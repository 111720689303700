.mainClientsDiv {
  width: 40%;
  min-height: 500px;
  margin: 110px auto 40px 360px;
  background: #f3f3f3;
  position: relative;
  border-radius: 15px;

  .clientsAbove {
    position: absolute;
    top: -76px;
    z-index: 20;
    border-bottom: 4px solid #fa9551;
    height: 35px;
    display: flex;
    // img {
    //   margin: 0 10px 0 15px;
    // }
    h4 {
      font-size: 15px;
      font-weight: bold !important;
      color: #2cace3;
    }
  }

  .clientsDiv {
    height: 93px;
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin: 30px 0 !important;
    padding: 30px 0 30px 30px;
    // label {
    //   align-self: center;
    //   margin: 0 40px 0 40px;
    //   color: #666666;
    //   select {
    //     border: 2px solid #00aff0;
    //     border-radius: 8px;
    //     height: 30px;
    //     color: #666666;
    //     font-weight: 400;
    //     margin: 0 0 0 20px;
    //     text-indent: 10px;
    //     width: 110px;
    //   }
    //   input {
    //     border: 2px solid #00aff0;
    //     border-radius: 8px;
    //     height: 30px;
    //     width: 110px;
    //     font-weight: 400;
    //     color: #666666;
    //     margin: 0 0 0 20px;
    //     text-indent: 10px;
    //   }
    // }

    .exam-doc {
      width: 100%;
    }
  }

  .row2 {
    // width: 83%;
    padding: 0 0 20px 0;
    margin: 0 30px 0 30px;
    .list-group {
      box-shadow: none;
      // margin: 10px 0;
      height: 130px;
      display: flex;

      .list-group-item {
        transition: all 0.5s ease-in-out;
        border: 1.7px solid #fa9551;
        border-radius: 10px;
        padding: 0;
        color: #666666;
        outline: 0;
        display: flex;
        width: 100%;
        font-weight: 500;
        // &:hover {
        //   background: #fa9551;
        //   color: white;
        // }
        // position: relative;
        img {
          // position: absolute;
          // right: -20px;
          // bottom: -10px;
          margin: 9px 20px 9px 9px;
          // border: 1.7px solid #666666;
          border-radius: 10px;
          width: 110px;
          height: 110px;
          background: white;
          object-fit: cover;
        }
        p {
          font-weight: bold;
          font-size: 18px;
          padding: 20px 0 0 0;
        }
        .docInfo {
          text-align: start;
          p {
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 20px;
            padding: 20px 0 0 0;
          }
          h5 {
            font-weight: 900;
            span {
              font-weight: 400;
            }
          }
        }
        a {
          color: #2cace3 !important;
          font-weight: 600;
          margin: auto 20px 20px auto;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
