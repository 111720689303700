.mainDoctorDiv {
  // width: calc(100% - 480px);
  width: 40%;
  min-height: 520px;
  margin: 110px 0 20px 360px;
  background: #f3f3f3;
  position: relative;
  border-radius: 15px;

  .doctorsAbove {
    position: absolute;
    top: -76px;
    z-index: 20;
    height: 35px;
    display: flex;
    border-bottom: 4px solid #fa9551;
    margin-right: 45px;
    h4 {
      font-size: 15px;
      font-weight: bold;
      color: #2cace3;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .row2 {
    // width: 90%;
    margin: 0 auto;
    color: #666666;
    padding: 30px 30px;
    .mainDoctorInfoDiv {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .imageDiv {
        border: 1.7px solid #fa9551;
        border-radius: 15px;
        background: white;
        flex: 1;
        height: 220px;
        display: flex;
        margin: 0 0 20px 0;
        .info {
          padding: 20px;
          text-align: start;
          p {
            font-weight: bold;
            font-size: 18px;
            margin-bottom: 20px;
          }
          h5 {
            font-weight: 900;
            margin: 20px 0 0 0 !important;
            span {
              font-weight: 400;
            }
          }
        }
        img {
          width: 110px;
          height: 110px;
          margin: 20px 5px 20px 20px;
          border-radius: 10px;
          object-fit: cover;
        }
      }
      .pricesAndDoing {
        flex: 1;
        border: 1.7px solid #fa9551;
        background: white;
        border-radius: 15px;
        margin: 0 0 20px 0;
        min-height: 130px;

        .priceInfo {
          padding: 20px;
          div {
            display: flex;
            justify-content: space-between;
            p {
              text-align: left;

              span {
                font-weight: 900;
              }
            }
            button {
              width: 45px;
              height: 20px;
              font-size: 12px;
              font-weight: 550;
              padding-top: 2px;
              display: flex;
              justify-content: center;
              background: #00aff0;
              border: none;
              border-radius: 3px;
              color: white;
            }
          }
        }
      }
    }
    .BiographyDiv {
      display: flex;
      flex-direction: column;
      .bio {
        flex: 1;
        border: 1.7px solid #fa9551;
        background: white;
        border-radius: 15px;
        margin: 20px 0 0 0;
        min-height: 130px;

        .pForBio {
          font-weight: 900;
          text-align: left;
          padding: 20px 20px 0 20px;
        }
        textarea {
          resize: none;
          width: 100%;
          min-height: 70px;
          padding: 0px 20px 20px 20px;
          border-radius: 5px;
          background: white;

          border: none;
          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-button:single-button {
            // background-color: #bbbbbb;
            display: block;

            height: 13px;
            width: 16px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb {
            background: #fa9551;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #db8246;
          }
        }
      }
      .workHours {
        flex: 1;
        border: 1.7px solid #fa9551;
        background: white;
        border-radius: 15px;
        min-height: 130px;

        // font-size: 12px;
        div {
          padding: 20px;
          p {
            text-align: left;
            margin-bottom: 7px;
            span {
              font-weight: 900;
            }
          }
        }
      }
    }
  }
}
