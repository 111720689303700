.detail-exam {
  background: #f2f2f2;
  position: relative;
  padding: 30px;
  border-radius: 15px;

  // top: 2px;
  // float: left;
  text-align: left;
  margin: 110px auto 20px 360px;
  width: calc(100% - 480px);
  min-height: 350px;
  height: 100% !important;
  display: flex;
  // max-height: 500px;
  .iconVideo {
    position: absolute;
    top: -76px;
    left: 0;
    z-index: 20;
    height: 35px;
    display: flex;
    border-bottom: 4px solid #fa9551;
    margin-right: 45px;
    h4 {
      font-weight: bold;
      font-size: 15px;
      color: #2cace3;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .detail {
    padding: 3px 10px 5px 0px;
    position: relative;
    width: 300px;
    .divSelectButton {
      display: flex;
      // margin: 50px 0 0 0;
      .select-option {
        width: 200px;
        margin: 0;
        // border: 1px solid #fa9551;
      }
      .btnSend {
        margin: 0 10px;
        border: 1px solid #377da5;
        background: #4092c2;
        color: white;
        padding: 0 5px;
        border-radius: 5px;
      }
    }
    p {
      color: #666666;
      border: none;
      span {
        font-weight: 900;
      }
    }

    .col-sm-10 {
      position: relative;
      height: 50px;

      .btn {
        position: relative;
        border: 1px solid #4092c2;
        height: 37px;
        left: 120px;
        top: -37px;
      }
    }
  }
  .mainMessageDiv {
    width: 100%;

    .subjectDiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fa9551;
      color: white;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      height: 30px;
      p {
        margin: 0px 15px 0 15px;

        span {
          margin-right: 5px;
        }
      }
      .infoAndSquare {
        align-items: center;
        #imageDiv1 {
          background: transparent;
          border: 1.7px solid white;
          // position: absolute;
          width: 20px;
          border-radius: 5px;
          height: 20px;
          margin: 0px 20px 0 0;
          display: none;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .messageDiv {
      min-height: 160px;
      width: 100%;
      padding: 10px 15px !important;
      background: white;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      // padding: 10px 13px 0 13px;
      textarea {
        resize: none;
        width: 100%;
        min-height: 100px;
        border: none;
        // padding: 10px 15px;
        // border-bottom-left-radius: 15px;
        // border-bottom-right-radius: 15px;
        background: white;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-button:single-button {
          // background-color: #bbbbbb;
          display: block;

          height: 13px;
          width: 16px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: #fa9551;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #db8246;
        }
      }
      .mainFileDiv {
        border-top: 1px solid #f2f2f2;
        height: 30px;
        display: flex;
        .FileDiv {
          height: 40px;
          p {
            // border: 1.7px solid #00aff0;
            color: #00aff0;
            font-weight: bold;
            width: 50px;
            height: 25px;
            border-radius: 5px;
            text-align: start;
            align-items: center;
            margin: 5px 0px 0 0;
          }
        }
        .fileForDownload {
          display: flex;
          // border: 1.7px solid #00aff0;
          color: #00aff0;
          // width: 100px;
          border-radius: 5px;
          text-align: left;
          align-items: center;
          justify-content: flex-start;
          height: 25px;
          margin: 2px 0 0 0;
          padding: 0 5px;
          overflow: hidden;
          white-space: nowrap;
          p {
            white-space: nowrap;
            width: 100px;
            margin: 0;
            color: #00aff0;
            text-indent: 0;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
              text-overflow: scroll;
              width: 350px;
            }
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .reportIfDeclined,
    .reportIfFinished {
      margin: 20px 0 0 0;

      // height: 45%;
      .subjectDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #e6e6e6;
        color: #666666;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        height: 30px;
        p {
          margin: 0px 15px 0 15px;

          span {
            margin-right: 5px;
          }
        }
        #imageDiv2,
        #imageDiv3 {
          background: transparent;
          border: 1.7px solid white;
          // position: absolute;
          width: 20px;
          border-radius: 5px;
          height: 20px;
          margin: 0px 20px 0 0;
          // display: none;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .messageDivReport {
        width: 100%;
        height: 72%;
        background: white;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        // padding: 10px 13px 0 13px;
        textarea {
          width: 100%;
          min-height: 150px;
          padding: 10px;
          text-indent: 5px;
          border: none;
          background: white;
          resize: none;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-button:single-button {
            // background-color: #bbbbbb;
            display: block;

            height: 13px;
            width: 16px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb {
            background: #fa9551;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #db8246;
          }
        }
        .mainFileDiv {
          border-top: 1px solid #f2f2f2;
          height: 30px;
          display: flex;
          padding: 0 15px;
          .FileDiv {
            height: 40px;
            p {
              // border: 1.7px solid #00aff0;
              color: #00aff0;
              font-weight: bold;
              width: 50px;
              height: 25px;
              border-radius: 5px;
              text-align: start;
              align-items: center;
              margin: 5px 0px 0 0;
            }
          }
          .fileForDownload {
            display: flex;
            // border: 1.7px solid #00aff0;
            color: #00aff0;
            // width: 100px;
            border-radius: 5px;
            text-align: left;
            align-items: center;
            justify-content: flex-start;
            height: 25px;
            margin: 2px 0 0 0;
            padding: 0 5px;
            overflow: hidden;
            white-space: nowrap;
            p {
              white-space: nowrap;
              width: 100px;
              margin: 0;
              color: #00aff0;
              text-indent: 0;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              &:hover {
                text-overflow: scroll;
                width: 350px;
              }
            }
            &:hover {
              cursor: pointer;
            }
          }
        }
        button {
          color: white;
          background: #00aff0;
          border: none;
          border-radius: 5px;
          padding: 5px 10px;
          font-weight: 600;
          margin: 20px auto 20px 0;
        }
      }
    }
  }
  .sideMessageDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    .SubjectMessage {
      .subjectDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fa9551;
        color: white;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        height: 30px;
        p {
          margin: 0px 15px 0 15px;

          span {
            margin-right: 5px;
          }
        }
        .infoAndSquare {
          align-items: center;
          #imageDiv1 {
            background: transparent;
            border: 1.7px solid white;
            // position: absolute;
            width: 20px;
            border-radius: 5px;
            height: 20px;
            margin: 0px 20px 0 0;
            display: none;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .messageDiv {
        min-height: 160px;
        background: white;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        padding: 10px 15px !important;

        textarea {
          width: 100%;
          min-height: 100px !important;
          // padding: 10px;
          // text-indent: 5px;
          background: white !important;
          border: none;
          resize: none;
          // border-bottom-left-radius: 15px;
          // border-bottom-right-radius: 15px;
          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-button:single-button {
            // background-color: #bbbbbb;
            display: block;

            height: 13px;
            width: 16px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb {
            background: #fa9551;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #db8246;
          }
        }
        .mainFileDiv {
          border-top: 1px solid #f2f2f2;
          height: 30px;
          display: flex;
          .FileDiv {
            height: 40px;
            p {
              // border: 1.7px solid #00aff0;
              color: #00aff0;
              font-weight: bold;
              width: 50px;
              height: 25px;
              border-radius: 5px;
              text-align: start;
              align-items: center;
              margin: 5px 0px 0 0;
            }
          }
          .fileForDownload {
            display: flex;
            // border: 1.7px solid #00aff0;
            color: #00aff0;
            // width: 100px;
            border-radius: 5px;
            text-align: left;
            align-items: center;
            justify-content: flex-start;
            height: 25px;
            margin: 2px 0 0 0;
            padding: 0 5px;
            overflow: hidden;
            white-space: nowrap;
            p {
              white-space: nowrap;
              width: 100px;
              margin: 0;
              color: #00aff0;
              text-indent: 0;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              &:hover {
                text-overflow: scroll;
                width: 350px;
              }
            }
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .reportIfDeclined,
      .reportIfFinished {
        margin: 20px 0 0 0;
        // height: 45%;
        .subjectDiv {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #e6e6e6;
          color: #666666;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          height: 30px;
          p {
            margin: 0px 15px 0 15px;
            // font-weight: 700;

            span {
              // font-weight: 700;
              margin-right: 5px;
            }
          }
          #imageDiv2,
          #imageDiv3 {
            background: transparent;
            border: 1.7px solid white;
            // position: absolute;
            width: 20px;
            border-radius: 5px;
            height: 20px;
            margin: 0px 20px 0 0;
            // display: none;
            &:hover {
              cursor: pointer;
            }
          }
        }
        .messageDivReport {
          width: 100%;
          height: 72%;
          background: white;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          // padding: 10px 13px 0 13px;
          textarea {
            width: 100%;
            min-height: 150px;
            padding: 10px;
            background: white;
            border: none;
            resize: none;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            &::-webkit-scrollbar {
              width: 5px;
            }

            &::-webkit-scrollbar-button:single-button {
              // background-color: #bbbbbb;
              display: block;

              height: 13px;
              width: 16px;
            }

            &::-webkit-scrollbar-track {
              background: #f1f1f1;
              border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb {
              background: #fa9551;
              border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: #db8246;
            }
          }
          .mainFileDiv {
            border-top: 1px solid #f2f2f2;
            height: 30px;
            display: flex;
            padding: 0 15px;
            .FileDiv {
              height: 40px;
              p {
                // border: 1.7px solid #00aff0;
                color: #00aff0;
                font-weight: bold;
                width: 50px;
                height: 25px;
                border-radius: 5px;
                text-align: start;
                align-items: center;
                margin: 5px 0px 0 0;
              }
            }
            .fileForDownload {
              display: flex;
              // border: 1.7px solid #00aff0;
              color: #00aff0;
              // width: 100px;
              border-radius: 5px;
              text-align: left;
              align-items: center;
              justify-content: flex-start;
              height: 25px;
              margin: 2px 0 0 0;
              padding: 0 5px;
              overflow: hidden;
              white-space: nowrap;
              p {
                white-space: nowrap;
                width: 100px;
                margin: 0;
                color: #00aff0;
                text-indent: 0;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                &:hover {
                  text-overflow: scroll;
                  width: 350px;
                }
              }
              &:hover {
                cursor: pointer;
              }
            }
          }
        }
        button {
          color: white;
          background: #00aff0;
          border: none;
          border-radius: 5px;
          padding: 5px 10px;
          font-weight: 600;
          margin: 50px auto 0px 0;
        }
      }
    }
    .mainMessageCorrespondence {
      // padding: 20px 0 0 0;
      .MessageCorrespondence {
        width: 100%;
        margin-top: 30px;

        .Maintbody {
          margin: 0 0 20px auto;
          p {
            text-indent: 20px;
            color: white;
            border: none;
            span {
              font-weight: 900;
            }
          }
          .senderMaiin {
            height: 30px;
            display: flex;
            background: #fa9551;
            justify-content: space-between;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            .senderP {
              margin-top: 5px;
              width: 70%;
              text-indent: 10px;
              flex: 3;
              span {
                font-weight: 900;
              }
            }
            .createdP {
              margin: 5px 0 0 0;
              width: 150px;
            }
            #imageDiv {
              background: transparent;
              border: 1.7px solid white;
              // position: absolute;
              width: 20px;
              border-radius: 5px;
              height: 20px;
              margin: 5px 20px 0 0;
              display: none;
              &:hover {
                cursor: pointer;
              }
            }
          }
          .row1 {
            background: white;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            padding: 10px 15px;

            textarea {
              background: white;
              color: #666666;
              width: 100%;
              height: 100px;
              resize: none;
              border: none;

              // border-bottom-left-radius: 15px;
              // border-bottom-right-radius: 15px;
              &::-webkit-scrollbar {
                width: 5px;
              }

              &::-webkit-scrollbar-button:single-button {
                // background-color: #bbbbbb;
                display: block;

                height: 13px;
                width: 16px;
              }

              &::-webkit-scrollbar-track {
                background: #f1f1f1;
                border-radius: 5px;
              }

              &::-webkit-scrollbar-thumb {
                background: #fa9551;
                border-radius: 5px;
              }

              &::-webkit-scrollbar-thumb:hover {
                background: #db8246;
              }
            }
            .mainFileDiv {
              border-top: 1px solid #f2f2f2;
              height: 30px;
              display: flex;
              .FileDiv {
                height: 40px;
                display: flex;
                p {
                  // border: 1.7px solid #00aff0;
                  color: #00aff0;
                  font-weight: bold;
                  width: 50px;
                  height: 25px;
                  border-radius: 5px;
                  text-align: start;
                  justify-content: center;
                  align-items: center;
                  margin: 5px 0px 0 0;
                  text-indent: 0;
                }
              }
              .fileForDownload {
                display: flex;
                // border: 1.7px solid #00aff0;
                color: #00aff0;
                // width: 100px;
                border-radius: 5px;
                text-align: left;
                align-items: center;
                justify-content: flex-start;
                height: 25px;
                margin: 2px 0 0 0;
                padding: 0 5px;
                overflow: hidden;
                white-space: nowrap;
                p {
                  white-space: nowrap;
                  width: 100px;
                  margin: 0;
                  color: #00aff0;
                  text-indent: 0;
                  display: inline-block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  &:hover {
                    text-overflow: scroll;
                    width: 350px;
                  }
                }
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
          .ReplyMainDiv {
            margin-left: 20px;
            button {
              color: white;
              background: #00aff0;
              height: 40px;
              width: 80px;
              border-radius: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              .replyIcon {
                font-size: 20px;
              }
            }
          }
          .SendMainDiv {
            margin-left: 20px;
            border: none;
            border-radius: 15px;
            margin: 10px auto 0 auto;
            box-shadow: none;
            background: white;
            textarea {
              min-height: 200px;
              resize: none;
              border: none;
              text-indent: 0;
              padding: 10px 15px;
              &::-webkit-scrollbar {
                width: 5px;
              }

              &::-webkit-scrollbar-button:single-button {
                // background-color: #bbbbbb;
                display: block;

                height: 13px;
                width: 16px;
              }

              &::-webkit-scrollbar-track {
                background: #f1f1f1;
                border-radius: 5px;
              }

              &::-webkit-scrollbar-thumb {
                background: #fa9551;
                border-radius: 5px;
              }

              &::-webkit-scrollbar-thumb:hover {
                background: #db8246;
              }
            }
            .sendbuttonAndAtt {
              display: flex;
              background: white;
              border-bottom-left-radius: 15px;
              border-bottom-right-radius: 15px;
              margin: 0;
              padding: 0;
              button {
                color: white;
                background: #00aff0;
                border: none;
                height: 30px;
                width: 80px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0px 10px 20px 20px;
                transition: all 0.5s ease-in-out;
                .replyIcon {
                  font-size: 20px;
                }
                // &:hover {
                //   box-shadow: 2px 2px 1px black;
                // }
              }
              .upload-btn-wrapper {
                position: relative;
                overflow: hidden;
                display: inline-block;
              }

              .btn {
                // border: 1px solid #00aff0;
                color: white;
                background-color: #00aff0;
                width: 30px;
                border-radius: 8px;
                font-size: 30px;
                font-weight: bold;
                outline: none;
                // &:hover {
                //   cursor: pointer;
                //   background: #4092c2;
                //   color: white;
                // }
              }

              .upload-btn-wrapper input[type="file"] {
                font-size: 0px;
                position: absolute;
                left: 0;
                top: 0;
                width: 40px;
                height: 40px;
                margin-left: 20px;
                cursor: pointer;
                opacity: 0;
              }
              .fileForDownload {
                display: flex;
                // border: 1.7px solid #00aff0;
                color: #00aff0;
                // width: 100px;
                border-radius: 5px;
                text-align: left;
                align-items: center;
                justify-content: flex-start;
                height: 30px;
                margin: 0 0 0 20px;
                padding: 0 5px;
                overflow: hidden;
                white-space: nowrap;
                p {
                  white-space: nowrap;
                  width: 100px;
                  margin: 0;
                  color: #00aff0;
                  text-indent: 0;
                  display: inline-block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  &:hover {
                    text-overflow: scroll;
                    width: 350px;
                  }
                }
              }
            }
          }
        }
      }
      .ReplyMainDiv {
        margin-left: 20px;
        button {
          color: white;
          background: #00aff0;
          // border: 1px solid #377da5;
          height: 40px;
          width: 80px;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          .replyIcon {
            font-size: 20px;
          }
        }
        &:disabled,
        button[disabled] {
          background: lightgray;
          color: white;
          cursor: not-allowed;
          box-shadow: none;
          pointer-events: none;
          border: none;
        }
      }
      .SendMainDiv {
        margin-left: 20px;
        border: none;
        border-radius: 15px;
        margin: 10px auto 0 auto;
        box-shadow: none;
        background: white;
        .senderMaiin {
          height: 30px;
          display: flex;
          background: #fa9551;
          justify-content: space-between;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          color: white;
          text-indent: 15px;
          .senderP {
            margin-top: 5px;
            span {
              font-weight: 900;
            }
          }
          .createdP {
            margin: 5px 20px 0 0;
          }
        }
        textarea {
          height: 200px;
          width: 100%;
          resize: none;
          border: none;
          text-indent: 0;
          padding: 10px 15px;
          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-button:single-button {
            // background-color: #bbbbbb;
            display: block;

            height: 13px;
            width: 16px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb {
            background: #fa9551;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #db8246;
          }
        }
        .sendbuttonAndAtt {
          display: flex;
          flex-direction: column;
          background: white;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;

          margin: 0;
          padding: 0 15px;
          button {
            color: white;
            background: #00aff0;
            border: none;
            height: 30px;
            width: 80px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px 10px 10px 0px;
            transition: all 0.5s ease-in-out;
            .replyIcon {
              font-size: 20px;
            }
            // &:hover {
            //   box-shadow: 2px 2px 1px black;
            // }
          }
          .upload-btn-wrapper {
            position: relative;
            overflow: hidden;
            display: inline-block;
          }

          .btn {
            // border: 1px solid #00aff0;
            // color: white;
            // background-color: white;
            // width: 50px;
            // height: 25px;
            // border-radius: 5px;
            // font-size: 14px;
            // font-weight: bold;
            outline: none;
            // // &:hover {
            // //   cursor: pointer;
            // //   background: #4092c2;
            // //   color: white;
            // // }
            // border: 1.7px solid #00aff0;
            // color: #00aff0;
            // font-weight: bold;
            // width: 60px;
            // height: 25px;
            // border-radius: 5px;
            // text-align: center;
            // justify-content: center;
            // align-items: center;
            // margin: 5px 10px 0 0;
            // text-indent: 0;

            background: white;
            border: 1.7px solid #00aff0;
            height: 30px;
            color: #00aff0;
            font-weight: bold;
            padding: 0 5px;
            width: 70px;
            display: flex;
            align-items: center;
            border-radius: 5px;
            margin: 0;
            align-items: center;
            box-shadow: none;
            p {
              margin: 0;
            }
          }

          .upload-btn-wrapper input[type="file"] {
            font-size: 0px;
            position: absolute;
            left: 0;
            top: 0;
            width: 40px;
            height: 40px;
            margin-left: 20px;
            cursor: pointer;
            opacity: 0;
          }
          .fileForDownload {
            display: flex;
            // border: 1.7px solid #00aff0;
            color: #00aff0;
            // width: 100px;
            border-radius: 5px;
            text-align: left;
            align-items: center;
            justify-content: flex-start;
            height: 30px;
            margin: 0 0 0 20px;
            padding: 0 5px;
            overflow: hidden;
            white-space: nowrap;
            p {
              white-space: nowrap;
              max-width: 100px;
              margin: 0 2px 0 0;
              color: #00aff0;
              text-indent: 0;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              &:hover {
                text-overflow: scroll;
                max-width: 350px;
              }
            }
            .deleteB {
              color: gray;
              width: 20px;
              text-align: center;
              text-decoration: none;
              border-radius: 5px;
              border: none;
              &:hover {
                cursor: pointer;
              }
            }
          }
          .attachess {
            display: flex;
            border-top: 1.7px solid #f2f2f2;
            padding: 5px 0 0 0;
            height: 40px;
          }
        }
      }
    }
    .newMessage {
      border: 2px solid #00aff0;
      color: #00aff0;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      position: relative;
      transition: all 0.5s ease-in-out;
      float: right;
      margin: auto 0 0 auto;
      h1 {
        margin: 0;
        position: absolute;
        font-size: 40px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover {
        background: #00aff0;
        color: white;
      }
    }
    .sendButtonForReplay {
      color: white;
      background: #00aff0;
      border: none;
      height: 30px;
      width: 80px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 10px 10px 0px;
      transition: all 0.5s ease-in-out;
      .replyIcon {
        font-size: 20px;
      }
      // &:hover {
      //   box-shadow: 2px 2px 1px black;
      // }
    }
  }
  .message-btn {
    position: absolute;
    bottom: 30px;
    // margin: 0 0 0 10px;
    button {
      background: #00aff0;
      margin: 0;
      font-size: 14px;
      height: 30px;
      width: 120px;
      border-radius: 10px;
      &:disabled,
      button[disabled] {
        background: lightgray;
        color: white;
        cursor: not-allowed;
        box-shadow: none;
        pointer-events: none;
        border: none;
      }
    }
  }
}

.messages-link,
.message-link {
  // border: 1px solid #00aff0;
  // padding: 12px;
  border-radius: 8px;
  background-color: #00aff0;
  color: white;
  margin: 10px;
  border: none;
  font-size: 18px;
  font-weight: 700;
  transition: all 0.5s ease-in-out;
  outline: 0;
  &:disabled,
  button[disabled] {
    background: lightgray;
    color: white;
    cursor: not-allowed;
    box-shadow: none;
    pointer-events: none;
    border: none;
  }

  // &:hover {
  //   // background: #377da5;
  //   box-shadow: 2px 2px 2px rgb(189, 186, 186);
  // }
}
