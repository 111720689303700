.exam {
  width: calc(100% - 480px) !important;
  margin: 110px auto 20px 360px !important;
  .mainExam {
    padding: 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: #f2f2f2;
    border-radius: 15px;
    position: relative;

    .newEmail {
      position: absolute;
      top: -76px;
      left: 0px;
      z-index: 20;
      height: 35px;
      display: flex;
      border-bottom: 4px solid #fa9551;
      margin-right: 45px;
      h4 {
        font-weight: bold;
        font-size: 15px;
        color: #2cace3;
      }
      &:hover {
        cursor: pointer;
      }
    }

    .exam-spec {
      margin-right: 10px;
      width: 100%;
    }

    .exam-doc {
      margin-right: 10px;
      width: 100%;
    }

    .exam-sub {
      width: 100%;
      .form-control {
        height: 33px !important;
        border: 1.7px solid #fa9551;
        border-radius: 10px;
        // width: 305px;
        width: 100%;
        font-weight: 600;
        background: white;
        color: #666666;
      }
    }
  }

  .exam-mess {
    margin-top: 20px !important;
    border-radius: 15px;
    width: 100%;
    margin: 0 auto;
    background: #f2f2f2;
    padding: 30px;

    .messageTitle {
      height: 40px;
      background: #fa9551;
      color: white;
      font-weight: 600;
      text-align: start;
      display: flex;
      align-items: center;
      text-indent: 12px;
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
    }

    .messageAndAttach {
      background: white;
      padding: 10px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      .form-control {
        height: 200px;
        border: none;
        box-shadow: none;
        overflow-x: hidden;
        // border-bottom-left-radius: 15px;
        // border-bottom-right-radius: 15px;
        resize: none;
        padding: 0;
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-button:single-button {
          // background-color: #bbbbbb;
          display: block;

          height: 13px;
          width: 16px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: #fa9551;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #db8246;
        }
      }
      .mainDivForFile {
        display: flex;
        padding-top: 4px;
        border-top: 1px solid #f2f2f2;
        position: relative;
        .upload-btn-wrapper {
          position: relative;
          overflow: hidden;
          display: inline-block;
          .btnn {
            background: white;
            border: 1.7px solid #00aff0;
            height: 30px;
            color: #00aff0;
            font-weight: bold;
            padding: 0 5px;
            width: 70px;
            display: flex;
            align-items: center;
            border-radius: 5px;
            .attachDiv {
              align-items: center;
              p {
                margin: 0;
              }
            }
          }
        }

        .upload-btn-wrapper input[type="file"] {
          font-size: 0px;
          position: absolute;
          background: #00aff0;
          left: 0;
          top: 0;
          width: 70px;
          height: 40px;
          opacity: 0;
          cursor: pointer;
        }
        .fileForDownload {
          // border: 1.7px solid #00aff0;
          // position: absolute;
          left: 120px;
          border-radius: 5px;
          color: #00aff0;
          padding: 0 5px;
          height: 30px;
          display: flex;
          align-items: center;
          overflow: hidden;
          // width: 100px;
          margin: 0 0 0 10px;
          white-space: nowrap;
          p {
            text-align: start;
            white-space: nowrap;
            max-width: 100px;
            margin: 0 2px 0 0;
            color: #00aff0;
            text-indent: 0;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
              text-overflow: scroll;
              max-width: 350px;
            }
          }
          .deleteB {
            color: gray;
            width: 20px;
            text-align: center;
            text-decoration: none;
            border-radius: 5px;
            border: none;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    .buttons {
      display: flex;
      height: 60px;
      .btn {
        margin: 30px 10px 0 0;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        font-weight: 500;
        color: white;
        background: #00aff0;
        transition: all 0.5s ease-in-out;
        outline: 0;
        &:hover {
          box-shadow: 2px 2px 2px black;
        }
        &:focus {
          outline: 0;
          border: none;
        }
        &:active {
          outline: 0;
          border: none;
        }
        // .submit-link {
        //   color: white;
        // }
      }
      .btnattach {
        margin: 30px 0 0 0;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        font-weight: 500;
        border-radius: 5px;
        color: white;
        background: #00aff0;
        transition: all 0.5s ease-in-out;
        outline: 0;
        border: none;
        &:hover {
          box-shadow: 2px 2px 2px black;
        }
        &:focus {
          outline: 0;
          border: none;
        }
        &:active {
          outline: 0;
          border: none;
        }
      }
    }

    // .profilePic {
    //   display: flex;
    //   margin: 30px 0 20px 20px;
    //   width: 30px;
    //   height: 30px;
    //   background: #00aff0;
    //   border-radius: 5px;

    //   p {
    //     font-weight: 600;
    //     color: #666666;
    //     margin: 0;
    //   }
    //   .upload-btn-wrapper {
    //     position: relative;
    //     overflow: hidden;
    //     .btn {
    //       margin: 0;
    //       width: 30px;
    //       height: 30px;
    //       padding: 0;
    //       background: transparent !important;
    //       .docImage {
    //         width: 30px;
    //         height: 30px;
    //         object-fit: cover;
    //         // border-radius: 50%;
    //       }
    //       p {
    //         font-size: 80px;
    //         padding: 0;
    //       }
    //     }
    //   }

    //   .upload-btn-wrapper input[type="file"] {
    //     font-size: 100px;
    //     position: absolute;
    //     // background: #00aff0;

    //     left: 0;
    //     top: -20px;
    //     width: 50px;
    //     opacity: 0;
    //   }
    // }
    // .fileForDownload {
    //   border: 1.7px solid #00aff0;
    //   position: absolute;
    //   left: 215px;
    //   bottom: 30px;
    //   border-radius: 5px;
    //   color: #00aff0;
    //   padding: 0 5px;
    //   height: 30px;
    //   display: flex;
    //   align-items: center;
    //   overflow: hidden;
    //   width: 100px;
    //   // margin: 0 0 0 10px;
    //   p {
    //     margin: 0;
    //   }
    // }
  }

  #subject {
    height: 38px;
  }
}
