.header {
  width: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  height: 70px;
  display: grid;
  grid-template-columns: 1fr;
  border-bottom: 2px solid #f2f2f2;
  div {
    width: 100%;
    margin: 0 auto;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    img {
      // width: 200px;
      height: 45px;
      margin: 20px 0 0 0;
    }
  }
  // @media (max-width: 1500px) {
  //   div {
  //     width: 80%;
  //   }
  // }
  .duca1 {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100vh;
    background: black;
    opacity: 0.4;
    z-index: 30;
  }
  .duca {
    position: absolute;
    z-index: 31;
    background: #f2f2f2;
    bottom: -400px;
    height: 200px;
    width: 50%;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    h1 {
      color: #00aff0;
      font-size: 25px;
      font-weight: bold;
      margin: 0;
    }
    h4 {
      color: #00aff0;
      font-weight: bold;
    }
    .buttons {
      display: flex;
      justify-content: space-around;
      margin-bottom: 20px;
      button {
        width: 100px;
        height: 35px;
        border: 1px solid white;
        border-radius: 5px;
        color: white;
        font-weight: bold;
        background: #00aff0;
      }
    }
  }
}
