.mainLogin {
  margin: 120px auto 0px auto;
  background: #f2f2f2;
  width: 37%;
  position: relative;
  border-radius: 15px;

  .loading {
    animation: rotation 2s infinite linear;
    width: 70px !important;
    position: absolute;
    top: 45%;
    left: 45%;
    transform: translate(-45%, -45%);
    z-index: 20;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .logo {
    position: absolute;
    width: 110px;
    right: -30px;
    top: -25px;
  }

  h1 {
    color: #00aff0;
    font-size: 45px;
    font-weight: bold;
    padding: 40px 0 0 0;
  }
  h5 {
    color: #666666;
    margin: 10px 0;
    font-weight: 800;
    font-size: 18px;
    letter-spacing: -0, 5px;
  }

  .createAccount {
    color: #00aff0;
    font-weight: 800;
    text-decoration: underline;
  }

  .login-form {
    width: 300px;
    margin: 0 auto;
    text-align: left;
    color: #666666;
    font-size: 13px;
    font-weight: bold;

    label {
      margin: 0 0 10px 0;
    }

    .rememberForgot {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 20px 0 0 0;
      div {
        display: flex;
        margin: 10px 0 0 0;

        input[type="checkbox"] {
          visibility: hidden;
          position: absolute;
        }

        input[type="checkbox"] + label:before {
          height: 15px;
          width: 15px;
          margin-right: 5px;
          content: " ";
          display: inline-block;
          vertical-align: baseline;
          border: 1px solid #00aff0;
          border-radius: 50%;
        }

        input[type="checkbox"]:checked + label:before {
          background: #00aff0;
        }

        // [type="checkbox"]:not(:checked),
        // [type="checkbox"]:checked {
        //   position: absolute;
        //   left: -9999px;
        // }

        // [type="checkbox"]:not(:checked) + label,
        // [type="checkbox"]:checked + label {
        //   position: relative;
        //   padding-left: 25px;
        //   cursor: pointer;
        // }

        // [type="checkbox"]:not(:checked) + label:before,
        // [type="checkbox"]:checked + label:before {
        //   content: "";
        //   position: absolute;
        //   left: 0;
        //   width: 17px;
        //   height: 17px;
        //   background: #fff;
        //   border-radius: 3px;
        //   box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        //   border-radius: 50%;
        //   outline: none;
        //   border: 1.7px solid #00aff0;
        // }

        // [type="checkbox"]:not(:checked) + label:after,
        // [type="checkbox"]:checked + label:after {
        //   content: "✔";
        //   position: absolute;
        //   top: 1px;
        //   left: 2px;
        //   font-size: 15px;
        //   line-height: 0.8;
        //   color: #00aff0;
        //   transition: all 0.2s;
        // }

        // [type="checkbox"]:not(:checked) + label:after {
        //   opacity: 0;
        //   transform: scale(0);
        // }

        // [type="checkbox"]:checked + label:after {
        //   opacity: 1;
        //   transform: scale(1);
        // }
      }
      .forget {
        color: #666666;
      }
    }

    .email {
      width: 300px;

      margin: 20px auto 30px auto;
      ::placeholder {
        color: #666666;
        font-size: 12px;
      }
      .form-control {
        height: 40px;
        border: 1.7px solid #fa9551;
        border-radius: 10px;
      }
    }

    .password {
      width: 300px;
      margin: 0 auto;
      ::placeholder {
        color: #666666;
        font-size: 16px;
      }
      div {
        width: 100%;
        position: relative;
        input {
          width: 100%;
          margin: 0 0 10px 0;
          height: 40px;
          border-radius: 10px;
          border: 1.7px solid #fa9551;
          font-weight: 600;
          padding-right: 30px;
        }
        img {
          position: absolute;
          right: 5px;
          top: 13px;
        }
      }
      // .form-control {
      //   height: 40px;
      //   border: 1.7px solid #fa9551;
      //   border-radius: 10px;
      // }
    }

    .login-button {
      margin-bottom: 20px;
      height: 60px;

      .btn {
        width: 70px;
        color: white;
        background: #00aff0;
        transition: all 0.5s ease-in-out;
        display: flex;
        justify-content: center;
        margin: 40px auto 20px auto;
        font-weight: 600;
        outline: 0;
        &:hover {
          box-shadow: 2px 2px 2px black;
        }
      }
    }
  }
}
