.exam1 {
  width: calc(100% - 480px) !important;
  margin: 110px auto 20px 360px !important;
  .mainExam {
    padding: 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: #f2f2f2;
    border-radius: 15px;
    position: relative;

    .newVideo {
      position: absolute;
      top: -76px;
      left: 0px;
      z-index: 20;
      height: 35px;
      display: flex;
      border-bottom: 4px solid #fa9551;
      margin-right: 45px;
      h4 {
        font-weight: bold;
        font-size: 15px;
        color: #2cace3;
      }
      &:hover {
        cursor: pointer;
      }
    }

    .exam-spec {
      margin-right: 10px;
      width: 100%;
    }

    .exam-doc {
      margin-right: 10px;
      width: 100%;
    }

    .exam-sub {
      width: 100%;
      .form-control {
        height: 33px !important;
        border: 1.7px solid #fa9551;
        border-radius: 10px;
        // width: 305px;
        width: 100%;
        font-weight: 600;
        background: white;
        color: #666666;
      }
    }
  }

  .DatePicker {
    width: 100%;
    height: 450px;
    margin: 20px auto 20px auto;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    background: #f2f2f2;
    border-radius: 15px;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    .exam-mess {
      border-radius: 15px;
      height: 347px;
      padding: 10px;

      width: 100%;
      margin: 0;
      color: #666666;
      background: white;
      border: 1.7px solid #00aff0 !important;
      textarea {
        border: none;
        height: 280px;
        margin: 0;
        width: 100%;
        resize: none;

        ::placeholder {
          font-weight: 500;
          color: #969696;
        }
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-button:single-button {
          // background-color: #bbbbbb;
          display: block;

          height: 13px;
          width: 16px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: #fa9551;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #db8246;
        }
      }
      .mainDivForFile {
        display: flex;
        padding-top: 4px;
        border-top: 1px solid #f2f2f2;
        position: relative;
        .upload-btn-wrapper {
          position: relative;
          overflow: hidden;
          display: inline-block;
          .btnn {
            background: white;
            border: 1.7px solid #00aff0;
            height: 30px;
            color: #00aff0;
            font-weight: bold;
            padding: 0 5px;
            width: 70px;
            display: flex;
            align-items: center;
            border-radius: 5px;
            .attachDiv {
              align-items: center;
              p {
                margin: 0;
              }
            }
          }
        }

        .upload-btn-wrapper input[type="file"] {
          font-size: 0px;
          position: absolute;
          background: #00aff0;
          left: 0;
          top: 0;
          width: 70px;
          height: 40px;
          opacity: 0;
          cursor: pointer;
        }
        .fileForDownload {
          // border: 1.7px solid #00aff0;
          // position: absolute;
          left: 120px;
          border-radius: 5px;
          color: #00aff0;
          padding: 0 5px;
          height: 30px;
          display: flex;
          align-items: center;
          overflow: hidden;
          // width: 50px;
          margin: 0 0 0 10px;
          white-space: nowrap;
          p {
            white-space: nowrap;
            max-width: 100px;
            margin: 0 2px 0 0;
            color: #00aff0;
            text-indent: 0;
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
              text-overflow: scroll;
              max-width: 250px;
            }
          }
          .deleteB {
            color: gray;
            width: 20px;
            text-align: center;
            text-decoration: none;
            border-radius: 5px;
            border: none;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    .MainDate {
      position: relative;
      border-radius: 15px;
      height: 347px;
      width: 550px;
      display: flex;
      justify-content: flex-end;
      margin-left: 25px;
      p {
        position: absolute;
        color: rgb(250, 103, 103);
        font-weight: bold;
        font-size: 15px;
        bottom: -70px;
      }

      .disabledDiv {
        width: 100%;
        height: 98%;
        position: absolute;
        z-index: 1;
      }
      div {
        width: 100%;
        .react-datepicker {
          // display: flex;
          // flex-direction: column;
          border-radius: 15px;
          border: 1.7px solid #00aff0;
          margin-right: 125px;
          width: 425px;
          height: 347px;

          .react-datepicker__navigation {
            // margin: 20px 30px 0 10px;
            width: 10px;
            height: 10px;
          }
          .react-datepicker__navigation--previous {
            position: absolute;
            left: 60px;
            top: 22px;
          }
          .react-datepicker__navigation--next {
            position: absolute;
            right: 60px;
            top: 22px;
          }
          .react-datepicker__month-container {
            .react-datepicker__header {
              background: transparent;
              padding: 0;
              width: 360px;
              margin: 0 auto;
              border-bottom: 1.5px solid #00aff0;
              .react-datepicker__current-month {
                font-size: 20px;
                margin: 10px 0;
                color: #666666;
              }
              .react-datepicker__day-names {
                margin: 0 auto 3px auto;
                color: #666666;
                font-size: 12px;
                display: flex;
                flex-direction: row;
                justify-content: space-around;

                // .react-datepicker__day-name {
                // }
              }
            }
            // width: 100%;
            .react-datepicker__month {
              margin: 0 auto 0 auto;
              width: 360px;
              .react-datepicker__week {
                display: flex;
                justify-content: space-around;
                align-items: center;
                // margin-bottom: 15px;
                height: 45px;
                .react-datepicker__day {
                  font-size: 18px;
                  text-align: center;
                  color: #00aff0;
                  font-weight: 700;
                  &:hover {
                    height: 30px;
                    padding: 7px 0 0 0;
                  }
                }
                .react-datepicker__day--selected {
                  margin: 0;

                  padding: 7px 0 0 0;
                  height: 30px;
                  background: #fa9551;
                  color: white;
                }
                .react-datepicker__day--disabled,
                .react-datepicker__day--outside-month {
                  color: #b3b2b2;
                  font-weight: 500;
                }
              }
            }
          }
          .react-datepicker__time-container {
            position: absolute;
            right: -127px;
            top: -2px;
            // display: flex;
            // flex-direction: column;
            height: 347px;
            width: 100px;
            margin: 0;
            padding: 0;
            border: 1.7px solid #00aff0;
            border-radius: 15px;
            background: white;

            .react-datepicker__header--time {
              height: 40px;

              background: white;

              border-radius: 20px;
              // background: transparent;
              border-bottom: none;
              width: 90%;
              padding: 10px 0;
              margin: 0 auto;
              .react-datepicker-time__header {
                font-size: 20px;
                // margin-bottom: 10px;
                color: #666666;
                text-transform: capitalize;
              }
            }
            .react-datepicker__time {
              font-size: 12px;
              margin: 10px 0 0 0;
              border-bottom-left-radius: 15px;
              border-bottom-right-radius: 15px;
              display: flex;
              height: 85%;

              .react-datepicker__time-box {
                // width: 380px;
                .react-datepicker__time-list {
                  overflow-y: hidden;
                  height: 270px !important;
                  display: flex;
                  margin: 0 auto;
                  flex-direction: column;
                  width: 75px;
                  padding: 6px 6px 0 6px;
                  color: #00aff0;
                  font-weight: 600;
                  overflow-y: scroll;
                  &::-webkit-scrollbar {
                    width: 5px;
                  }

                  /* Track */
                  &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                    border-radius: 5px;
                  }

                  /* Handle */
                  &::-webkit-scrollbar-thumb {
                    background: #fa9551;
                    // border: 1px solid blue;
                    border-radius: 5px;
                    margin-left: 10px !important;
                  }
                  // &::-webkit-scrollbar-button:single-button {
                  //   background-color: #bbbbbb;
                  //   display: block;
                  //   border-style: solid;
                  //   height: 13px;
                  //   width: 16px;
                  // }
                  /* Handle on hover */
                  &::-webkit-scrollbar-thumb:hover {
                    background: #db8246;
                  }
                  .react-datepicker__time-list-item--selected {
                    background: #00aff0;
                    color: white;
                    border-radius: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
    position: relative;
    .divAndAttach {
      background: #f2f2f2;
      width: 180px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
      position: absolute;
      bottom: 30px;
      left: 10px;

      .send {
        margin: 0px 20px 0 20px;
        width: 80px;
        height: 30px;
        border: none;
        border-radius: 5px;
        color: white;
        background: #00aff0;
        transition: all 0.5s ease-in-out;
        outline: none;
      }
    }
  }
  #file {
    width: 60%;
    margin: 10px auto 0 auto;
  }
}

#subject {
  height: 38px;
  font-weight: 500;
}
