/* @import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,600;0,700;0,800;1,600;1,700;1,800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Open Sans", sans-serif;
  outline: 0;
}

.App {
  min-height: 100vh;
  min-width: 1280px;
  width: 100%;
  text-align: center;
  overflow-x: hidden;
}

.head {
  color: #4092c2;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #09d3ac;
}

.mainNotFoundDiv {
  height: 80vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #4092c2;
}

.bug {
  font-size: 80px;
  color: #4092c2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bug h1 {
  font-size: 70px;
  margin-left: 20px;
  color: #09d3ac;
}

.e-content-wrap {
  min-height: 400px !important;
}
