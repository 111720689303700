.page-footer {
  width: 100%;
  background: #cccccc;
  height: 169px;
  color: #666666;
}

.mainFooter {
  display: flex;

  justify-content: space-between;
  width: 85%;
  margin: 0 auto;
  .contact {
    h3 {
      font-weight: bold;
      font-size: 15pt;
    }
    text-align: start;
    list-style: none;
    li {
      font-size: 13pt;
      font-weight: 500;
      margin: 0 0 2px 0;
    }
    .mail {
      margin: 0 4px 0 0;
    }

    .phone {
      margin: 0 4px 0 0;
    }
    .mapPin {
      margin: 0 4px 0 0;
    }
  }

  .company {
    h3 {
      font-weight: bold;
      font-size: 15pt;
      color: #666666;
    }
    text-align: start;
    list-style: none;
    color: #666666;
    li {
      font-size: 13pt;
      font-weight: 500;
      margin: 0 0 2px 0;
    }
    .aboutClass,
    .blockClass,
    .careersClass,
    .faqClass {
      color: #666666;
      &:focus,
      &:hover,
      &:visited,
      &:link,
      &:active {
        text-decoration: none;
        list-style: none;
        outline: 0;
      }
    }
  }
  .social {
    h3 {
      font-weight: bold;
      font-size: 15pt;
      text-align: left;
    }
    .socialMedia {
      display: flex;
      justify-content: space-between;
      width: 250px;
      margin-top: 20px;
      div {
        border: 1.7px solid #666666;
        border-radius: 50%;
        width: 50px;
        height: 50px;

        .insta,
        .facebook,
        .linkedin,
        .twitter {
          font-size: 30px;
          margin-top: 10px;
          transition: all 0.5s ease-in-out;
          color: #666666;
        }
      }
    }
    p {
      margin: 20px 0 0 0;
      text-align: left;
    }
  }
}

@media (max-width: 1400px) {
  .mainFooter {
    width: 80%;
  }
}

@media (max-width: 600px) {
  .mainFooter {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
