.mainClienn {
  display: flex;
  flex-direction: column;
  // width: calc(100% - 480px) !important;
  width: 40% !important;
  margin: 110px 120px 40px 360px;
  background: #f2f2f2;
  padding: 30px;
  border-radius: 15px;

  position: relative;
  // .clDetails {
  //   position: absolute;
  //   top: -76px;
  //   z-index: 20;
  //   border-bottom: 4px solid #fa9551;
  //   height: 35px;
  //   display: flex;
  //   margin: 0 0 0 -30px;
  //   // img {
  //   //   margin: 0 10px 0 15px;
  //   // }
  //   h4 {
  //     font-size: 15px;
  //     font-weight: bold !important;
  //     color: #2cace3;
  //   }
  // }

  .newVideo {
    position: absolute;
    top: -76px;
    z-index: 20;
    height: 35px;
    display: flex;
    margin: 0 0 0 -30px;
    .profileDoc {
      // border-bottom: 4px solid #fa9551;
      margin-right: 45px;
      h4 {
        font-size: 15px;
        color: #2cace3;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  .client {
    width: 100%;
    padding: 20px 20px 20px 20px;
    border: 1.7px solid #fa9551;
    border-radius: 15px;
    height: 250px;
    display: flex;
    margin: 0 0 20px 0 !important;
    background: white;
    justify-content: flex-start;

    img {
      width: 110px;
      height: 110px;
      // border: 1.7px solid #666666;
      border-radius: 10px;
      object-fit: cover;
    }

    .client-p {
      display: flex;
      flex-direction: column;
      text-align: left;
      padding: 0 0 0 20px;
      p {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 20px;
        // padding: 20px 0 0 0;
      }
      h5 {
        font-weight: 900;
        color: #666666;
        margin: 0 0 20px 0;
        span {
          font-weight: 400;
        }
      }
    }
  }

  .form {
    // padding: 20px 15px 20px 20px;
    padding: 0px !important;
    margin: 0;
    height: 300px;
    width: 100%;
    color: #666666;

    .conditionss {
      display: flex;
      flex-direction: column;
      text-align: start;
      height: 140px;
      border: 1.7px solid #fa9551;
      border-radius: 15px;
      font-weight: 900;
      color: #666666;
      margin: 0 0 20px 0;
      padding: 10px;
      background: white;
      span {
        font-size: 16px;
      }
      textarea {
        background: transparent;
        border: none;
        // padding: 15px 0 15px 0
        font-weight: 400;
        resize: none;
        min-height: 100px;
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-button:single-button {
          // background-color: #bbbbbb;
          display: block;

          height: 13px;
          width: 16px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: #fa9551;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #db8246;
        }
      }
    }
    .allergiess {
      display: flex;
      flex-direction: column;
      text-align: start;
      font-weight: 900;
      border: 1.7px solid #fa9551;
      border-radius: 15px;
      height: 140px;
      color: #666666;
      margin: 20px 0;
      padding: 10px;
      background: white;
      span {
        font-size: 16px;
      }
      textarea {
        background: transparent;
        border: none;
        // padding: 15px 0 15px 0;
        font-weight: 400;
        resize: none;
        min-height: 100px;
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-button:single-button {
          // background-color: #bbbbbb;
          display: block;

          height: 13px;
          width: 16px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: #fa9551;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #db8246;
        }
      }
    }
  }
  .consAndPagi {
    width: 780px;
    background: white;
    .mainTabelRecord {
      // height: 280px;

      border-radius: 15px;
      overflow: hidden;

      // width: calc(100% - 480px) !important;
      width: 780px !important;
      margin: 0px 120px 0px 0px;
      background: #f2f2f2;

      .NoResultDiv {
        color: #666666;
        margin: 20px auto;
        font-size: 20px;
      }
      .mainConsultation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fa9551;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        height: 40px;

        .icon_left {
          span {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin: 3px 10px;
            align-self: center;
            text-align: center;
            .icon1 {
              align-self: center;
              color: white;
              font-size: 22px;
              margin-top: 3px;
            }
          }
          p {
            align-self: center;
            margin: 0 0 0 30px;
            color: white;
          }
        }
        .sort {
          margin: 0 10px;
          display: flex;
          p {
            align-self: center;
            margin: 0 50px 0 0;
            text-overflow: bold;
            color: white;
            &:hover {
              cursor: pointer;
            }
          }
          .all {
            margin: 0 20px 0 0;
          }
        }
      }

      .table2 {
        text-align: center;
        font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
        margin: 0;
        padding: 10px;
        color: #666666;

        tbody {
          background: #f2f2f2;
          transition: all 0.5s ease-in-out;
          // &:hover {
          //   background: rgb(208, 242, 253);
          //   cursor: pointer;
          // }
          tr {
            height: 40px;
            &:hover {
              background: #e6e6e6;
              cursor: pointer;
            }

            td {
              text-align: left;

              .finished {
                color: #00aff0;
                font-size: 17px;
              }
            }
            .client-status {
              text-align: center;
              width: 100px;

              &:hover {
                .check,
                .pendi,
                .finished,
                .declined {
                  display: none;
                }
                .status {
                  display: block;
                }
              }
            }
            .status {
              display: none;
            }
            .client-type {
              width: 120px !important;
            }
            .created {
              width: 120px;
            }
            .client-subject {
              width: 300px;
              padding: 0 0 0 30px;
            }
          }
        }
        .client-head {
          text-align: center;
          color: #666666;

          .client-row {
            // height: 40px;
            background: #b3b3b3;
            .client-subject {
              // text-align: center;
              display: flex;
              margin: 0 auto 0 30px;
              justify-content: flex-start;
              align-items: center;
              height: 40px;
            }

            .client-type {
              // text-align: center;
              height: 40px;
              padding: 0;
              width: 120px;
              .mainExamDiv {
                margin: 0 0 0 -5px;
                height: 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: 120px;
                // .searchDiv {
                //   display: flex;
                //   align-items: center;
                //   height: 40px;
                //   margin: 0 22px 0 0;
                // }
                .searchIcon {
                  font-size: 20px;
                  display: flex;
                  align-items: center;
                  padding: 0 10px 0 30px;
                  height: 100%;
                  &:hover {
                    cursor: pointer;
                  }
                }
                select {
                  width: 80px;
                  height: 25px;
                  border: none;
                  border-radius: 5px;
                  background: #b3b3b3;

                  &::placeholder {
                    color: #666666;
                    font-size: 10px;
                  }

                  option {
                    background: white;
                  }
                }
              }
            }
            .client-date {
              display: flex;
              margin: 0;
              justify-content: flex-start;
              align-items: center;
              height: 40px;
              width: 120px;
            }
            .client-status {
              text-align: center;
              margin: 0 auto;
              height: 40px;
            }
          }
        }
      }
    }
  }

  .pagi {
    display: flex;
    // width: calc(100% - 480px);
    width: 780px;
    margin: 20px auto 0px 0px;
    justify-content: center;

    ul {
      margin: 20px auto;
      li {
        a {
          color: #00aff0;
          border: none;
        }
      }
      .lastPage {
        display: none;
      }
      .active {
        a {
          background: #00aff0;
          color: white;
        }
      }
      .disabled {
        a {
          color: #00aff0;
        }
      }
    }

    // .left {
    //   width: 30px;
    //   height: 30px;
    //   margin: 10px;
    //   transition: all 0.5s ease-in-out;
    //   &:hover {
    //     cursor: pointer;
    //   }
    //   .iconLeft {
    //     font-size: 20px;
    //     margin: 4px 0 0 0;
    //     color: #4092c2;
    //   }
    // }

    // .right {
    //   width: 30px;
    //   height: 30px;
    //   margin: 10px;
    //   transition: all 0.5s ease-in-out;
    //   &:hover {
    //     cursor: pointer;
    //   }
    //   .iconRight {
    //     font-size: 20px;
    //     margin: 4px 0 0 0;
    //     color: #4092c2;
    //   }
    // }
  }
}
