.hamburger {
  margin: 60px 0 10px 0;
  background: #00aff0;
  height: 60px;
  color: white !important;
  .hamNprofil {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    height: 100%;
    margin: 0 auto;
    font-size: 16px;

    .ham {
      &:hover {
        cursor: pointer;
      }
    }
    .profile {
      &:hover {
        cursor: pointer;
      }
    }
    .rightNavIcons {
      display: flex;
      div {
        margin: 0 0 0 70px;
        font-size: 13px;
        height: 20px;
        display: flex;
        &:hover {
          cursor: pointer;
        }
        .iconNav {
          margin: 1px 10px 0 0;
          font-size: 18px;
        }
        p {
          align-items: center;
          margin: 1px 0 0 0;
        }
        .linksPatients,
        .links {
          display: flex;
          color: white;
          list-style: none;
          text-decoration: none;
        }
        .doctorsIcon {
          height: 24px;
          width: 24px;
          margin: -2px 10px 0 0;
        }
        .profileIcon {
          height: 22px;
          width: 22px;
          margin: -2px 10px 0 0;
        }
        .dashboardIcon {
          width: 16px;
          height: 22px;
          margin: -2px 10px 0 0;
        }
        .calendarIcon {
          height: 22px;
          width: 22px;
          margin: -2px 10px 0 0;
        }
      }

      // .calendarNav {
      //   position: relative;
      //   .numOfMessages {
      //     //   background: red;
      //     height: 20px;
      //     width: 20px;
      //     //   border: 1px solid brown;
      //     border-radius: 5px;
      //     position: absolute;
      //     right: -20px;
      //     top: -10px;
      //     p {
      //       margin: 0 auto;
      //     }
      //   }
      // }
    }
  }
  // @media (max-width: 1500px) {
  //   .hamNprofil {
  //     width: 80%;
  //   }
  // }
  position: relative;
  .sideNav {
    position: absolute;
    transition: all 0.7s ease-in-out;
    width: 265.5px;
    // height: 100vh;
    background: #999999;
    color: white;
    .sideProfile {
      border-bottom: 2px solid #fa9551;
      width: 70%;
      margin: 60px auto 40px auto;
      .mainProfile {
        border: 1px solid #4092c2;
        border-radius: 50%;
        width: 67px;
        height: 67px;
        margin: 0 auto;
        background: white;
        position: relative;
        .onlineDot {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          position: absolute;
          top: 4px;
          right: 2px;
        }
        .profile {
          color: white;
          font-size: 60px;
          background: rgb(189, 235, 250);
          width: 60px;
          height: 60px;
          align-items: center;
          overflow: hidden;
          border-radius: 50%;
          margin: 2px auto 2px auto;
          .icon {
            margin: 10px 0 0 0;
          }
        }
      }
      p {
        margin: 15px 0;
      }
    }
    .sideVideo,
    .sideEmail,
    .sideWaitingRoom,
    .sideMyCounsultation,
    .sideHelp,
    .sideFaq,
    .sideMyAccount,
    .sideSignOut {
      &.active {
        background: #fa9551 !important;
      }
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;

      //   border-bottom: 1.5px solid #4092c2;
      //   width: 80%;
      height: 50px;
      margin: 0 auto;
      padding: 10px 10px 10px 40px;
      transition: all 0.5s ease-in-out;
      &:hover {
        // background: #fa9551;
        cursor: pointer;
      }
      span {
        width: 30px;
        font-size: 18px;
        margin: 0px 10px auto 0;
      }
      h2 {
        color: white;
        font-size: 13px;
        margin: 0;
      }
    }
  }
}

.sideNav {
  position: absolute;
  transition: all 0.7s ease-in-out;
  top: 70px;
  width: 240px;
  height: calc(100% - 70px);
  background: whitesmoke;
  color: #0164a1;
  .sideProfile {
    border-bottom: 2px solid #f79451;
    width: 190px;
    margin: 30px auto 10px auto;
    .mainProfile {
      // border: 1px solid #4092c2;
      border-radius: 50%;
      width: 70px;
      height: 70px;
      margin: 0 auto;

      position: relative;

      // .onlineDot {
      //   width: 12px;
      //   height: 12px;
      //   border-radius: 50%;
      //   position: absolute;
      //   top: 4px;
      //   right: 2px;
      // }
      .profile {
        color: white;
        // font-size: 60px;
        width: 70px;
        height: 70px;
        overflow: hidden;
        border-radius: 50%;
        img {
          // margin: 0;
          margin: 5px auto 2px auto;
          width: 60px;
          height: 60px;
          // border-radius: 50%;
          object-fit: cover;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    p {
      margin: 15px 0;
      color: #666666;
      font-weight: bold;
      font-size: 17px;
    }
  }
  .sideVideo,
  .sideEmail,
  .sideWaitingRoom,
  .sideMyCounsultation,
  .sideHelp,
  .sideFaq,
  .sideMyAccount,
  .sideSignOut {
    &.active {
      background: #fa9551 !important;
    }
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // border-bottom: 1.5px solid #4092c2;
    width: 100%;
    margin: 0 auto;
    padding: 12px 10px 12px 20px;
    transition: all 0.5s ease-in-out;
    color: #666666;
    &:hover {
      // background: #f79451;
      cursor: pointer;
    }
    span {
      font-size: 18px;
      margin: 0px 7px auto 0;
    }
    h2 {
      font-size: 15px;
      font-weight: bold;
      margin: 0;
    }
    text-decoration: none;
  }
}
