.testic {
  width: calc(100% - 240px);
  margin: 110px 0px 40px auto;
  padding: 0 120px;
  .loading {
    animation: rotation 2s infinite linear;
    width: 70px !important;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  .main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    margin: 0 auto 20px auto;
    grid-gap: 10px;

    background: #f2f2f2;
    padding: 20px 15px;
    position: relative;
    border-radius: 15px;
    // border-top-left-radius: 0;
    .dashboardIcon {
      position: absolute;
      top: -76px;
      z-index: 20;
      border-bottom: 4px solid #fa9551;
      height: 35px;
      display: flex;
      // img {
      //   margin: 0 10px 0 15px;
      // }
      h4 {
        font-size: 15px;
        font-weight: bold !important;
        color: #2cace3;
      }
    }

    .divVideo {
      position: relative;

      .pendingReq {
        font-size: 13px;
        margin-top: 50px;
        color: #666666;

        div {
          margin: 4px auto;
          text-align: left;
          text-indent: 20px;
          color: #666666;
          transition: all 0.5s ease-in-out;
          &:hover {
            cursor: pointer;
            background: rgb(189, 235, 250);
          }
        }
        p {
          width: 100px;
          text-indent: 20px;
        }
      }
      .requestsVideo {
        background: #00aff0;
        width: 250px;
        height: 30px;
        float: right;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%, 5% 46%);

        border-bottom-left-radius: 5px;
        color: white;
        p {
          display: flex;
          padding: 4px 0;
          font-size: 15px;
          margin-left: 30px;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }

    .divEmail {
      position: relative;
      .pendingReq {
        font-size: 13px;
        margin-top: 50px;
        color: #666666;
        div {
          margin: 4px auto;
          text-align: left;
          text-indent: 20px;
          color: #666666;
          transition: all 0.5s ease-in-out;

          &:hover {
            cursor: pointer;
            background: rgb(189, 235, 250);
          }
        }
        p {
          width: 100px;
          text-indent: 20px;
        }
      }

      .requestsEmail {
        background: #00aff0;
        width: 250px;
        height: 30px;

        float: right;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%, 5% 46%);
        border-bottom-left-radius: 5px;
        color: white;
        p {
          display: flex;
          padding: 4px 0;
          font-size: 15px;
          margin-left: 30px;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }

    .divClock {
      position: relative;
      .requestsClock {
        background: #00aff0;
        width: 250px;
        height: 30px;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%, 5% 46%);
        float: right;
        border-bottom-left-radius: 5px;
        color: white;
        p {
          display: flex;
          padding: 4px 0;
          font-size: 15px;
          margin-left: 30px;
          justify-content: flex-start;
          align-items: center;
        }
      }
      .pendingReq {
        font-size: 13px;
        margin-top: 50px;
        color: #666666;
        div {
          text-align: left;
          text-indent: 20px;
          color: #666666;
          transition: all 0.5s ease-in-out;

          &:hover {
            cursor: pointer;
            background: rgb(189, 235, 250);
          }
        }
        p {
          width: 100px;
          text-indent: 20px;
        }
      }
    }

    .divVideo,
    .divEmail,
    .divClock {
      height: 250px;
      border-radius: 15px;
      border: 2px solid #b3b3b3;
      background: white;
    }

    .videoApp1,
    .waitRoom1,
    .emailReq1 {
      display: flex;
      font-size: 40px;
      height: 90px;
      justify-content: flex-start;
      align-self: center;
      border-radius: 5px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      transition: all 0.5s ease-in-out;
      &:hover {
        cursor: pointer;
        box-shadow: 3px 3px 5px gray;
      }
      .icon {
        margin: 0 0 0 20px;
      }

      .email,
      .video,
      .clock {
        align-self: center;
        margin: 0 10px 0 0;
        .icon {
          align-self: center;
        }
      }

      h2 {
        font-size: 20px;
        align-self: center;
        font-weight: 400;
        color: #666666;
      }
    }
  }

  .mainTabel {
    border-radius: 15px;
    height: 480px;

    overflow: hidden;

    .NoResultDiv {
      color: #666666;
      margin: 20px auto 0 auto;
      font-size: 20px;
    }

    .mainConsultation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fa9551;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      height: 40px;

      .icon_left {
        span {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin: 3px 10px;
          align-self: center;
          text-align: center;
          .icon1 {
            align-self: center;
            color: white;
            font-size: 22px;
            margin-top: 3px;
          }
        }
        p {
          align-self: center;
          margin: 0 0 0 30px;
          color: white;
        }
      }
      .sort {
        margin: 0 10px;
        display: flex;
        p {
          align-self: center;
          margin: 0 50px 0 0;
          text-overflow: bold;
          color: white;
          &:hover {
            cursor: pointer;
          }
        }
        .all {
          margin: 0 30px 0 0;
        }
      }
    }

    .table2 {
      text-align: center;
      font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
      margin: 0;
      padding: 10px;
      color: #666666;

      tbody {
        background: #f2f2f2;
        transition: all 0.5s ease-in-out;
        &:last-of-type {
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          overflow: hidden !important;
          tr {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            overflow: hidden !important;
            td {
              &:first-of-type {
                border-bottom-left-radius: 15px;
              }
              &:last-of-type {
                border-bottom-right-radius: 15px !important;
              }
            }
          }
        }
        tr {
          height: 40px;

          td {
            text-align: left;

            .finished {
              color: #00aff0;
              font-size: 17px;
            }
          }
          .client-status {
            text-align: center;
            width: 100px;

            &:hover {
              .check,
              .pendi,
              .finished,
              .declined {
                display: none;
              }
              .status {
                display: block;
              }
            }
          }
          .status {
            display: none;
          }
          .client-doctor {
            padding: 0 0 0 35px;
            white-space: nowrap !important;
            overflow: hidden !important;

            text-overflow: ellipsis !important;
          }
          .client-subject1 {
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            width: 350px;
          }
          .client-subject {
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
          }
          .created {
            margin: 0 auto;
            padding: 0 0 0 40px;
            p {
              margin: 0 auto;
            }
          }
        }
      }
      .client-head {
        text-align: center;
        color: #666666;

        .client-row {
          // height: 40px;
          background: #b3b3b3;
          .client-doctor {
            width: 320px;
            // display: flex;
            // justify-content: center;
            // flex-direction: column;
            .mainExamDiv {
              flex-direction: column;
              // height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 150px;
              // margin: 0 auto;
              .searchDiv {
                display: flex;
                align-items: center;
                height: 40px;
              }
              .searchIcon {
                display: flex;
                align-items: center;
                margin: 0 0 0 25px;
                // padding: 0 10px 0 0;
                height: 100%;
                &:hover {
                  cursor: pointer;
                }
              }
              input {
                width: 120px;
                height: 25px;
                border: 1px solid #666666;
                border-radius: 5px;
                margin: 0 0 5px 40px;
                font-weight: 400;

                text-indent: 5px;
                &::placeholder {
                  color: #666666;
                  font-size: 13px;
                  font-weight: 400;
                }
              }
            }
          }
          .client-subject {
            // text-align: center;
            display: flex;
            width: 150px;
            // float: right;
            margin: 0;
            justify-content: flex-start;
            align-items: center;
            height: 40px;
          }

          .client-type {
            // text-align: center;
            height: 40px;
            width: 200px;
            .mainExamDiv {
              margin: 0 0 0 -5px;
              height: 40px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              width: 150px;
              // .searchDiv {
              //   display: flex;
              //   align-items: center;
              //   height: 40px;
              //   margin: 0 22px 0 0;
              // }
              .searchIcon {
                font-size: 20px;
                display: flex;
                align-items: center;
                padding: 0 10px 0 30px;
                height: 100%;
                &:hover {
                  cursor: pointer;
                }
              }
              select {
                width: 80px;
                height: 25px;
                border: none;
                border-radius: 5px;
                background: #b3b3b3;
                // margin: 0 0 5px 10px;
                // width: 100px;
                // height: 30px;
                // border: 1px solid #666666;
                // border-radius: 5px;
                // text-indent: 5px;
                &::placeholder {
                  color: #666666;
                  font-size: 10px;
                }

                option {
                  background: white;
                  height: 40px;
                }
              }
            }
          }
          .client-date {
            // text-align: left;
            width: 160px;
            padding: 0 0 0 40px;

            // text-align: center;
            display: flex;
            // width: 170px;
            // float: right;
            margin: 0;

            justify-content: flex-start;
            align-items: center;
            height: 40px;
          }
          .client-status {
            text-align: center;
            // text-align: center;
            // display: flex;
            // width: 170px;
            // float: right;
            margin: 0 auto;
            // justify-content: flex-start;
            // align-items: center;
            height: 40px;
          }
        }
      }
    }
  }

  .list-group {
    &:hover {
      cursor: pointer;
      background: #e6e6e6;
    }
  }

  .MainPending {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    z-index: 10;

    .openPending {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 20px;
      width: 100%;

      .uniquePen {
        display: flex;
        justify-content: space-evenly;
        padding: 10px;
        margin: 0 auto;
        color: white;
        border: 1px solid white;
        width: 80%;

        p {
          align-self: center;
        }
      }
    }
  }

  // .penTable {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   background: rgba(0, 0, 0, 0.8);
  //   width: 100%;
  //   height: 100%;
  //   overflow-y: scroll;
  //   z-index: 5;

  //   button {
  //     width: 150px;
  //     height: 30px;
  //     background: rgb(221, 221, 221);
  //     border-radius: 5px;
  //     border: none;
  //     margin: 30px auto;
  //     transition: all 0.5s ease-in-out;
  //     outline: 0;
  //     // position: absolute;
  //     // left: 0;
  //     // bottom: 10%;
  //     // left: 50%;
  //     // transform: translate(-50%);

  //     &:hover {
  //       background: pink;
  //       box-shadow: 5px 5px 5px black;
  //     }
  //   }
  // }

  // .table2 {
  //   text-align: center;
  //   font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  //   border-collapse: collapse;
  //   width: 100%;
  //   margin: 0;
  //   padding: 10px;

  //   tbody {
  //     background: white;
  //     transition: all 0.5 ease-in-out;
  //     &:hover {
  //       background: lightblue;
  //     }
  //     tr {
  //       height: 40px;
  //       td {
  //         .check {
  //           color: rgb(18, 219, 18);
  //           font-size: 17px;
  //         }
  //         .declined {
  //           color: red;
  //           font-size: 17px;
  //         }
  //         .pendi {
  //           color: #4092c2;
  //           font-size: 17px;
  //         }
  //       }
  //       .client-status {
  //         width: 100px;
  //         &:hover {
  //           .check,
  //           .pendi,
  //           .finished,
  //           .declined {
  //             display: none;
  //           }
  //           .status {
  //             display: block;
  //           }
  //         }
  //       }
  //       .status {
  //         display: none;
  //       }
  //     }
  //     .created {
  //       p {
  //         margin: 0;
  //       }
  //     }
  //   }
  //   .client-head {
  //     text-align: center;
  //     background-color: whitesmoke;
  //     color: gray;

  //     .client-row {
  //       height: 40px;
  //       .client-doctor {
  //         text-align: center;
  //       }
  //       .client-subject {
  //         text-align: center;
  //       }
  //       .client-status {
  //         text-align: center;
  //       }
  //     }
  //   }
  // }
  // .connectWithdoctor {
  //   margin: 100px 0 0 0;
  //   background: #4092c2;
  //   height: 60px;
  //   .connected {
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //     width: 60%;
  //     height: 100%;
  //     margin: 0 auto;
  //     p {
  //       margin: 0;
  //       color: white;
  //     }
  //     h4 {
  //       margin: 0;
  //       font-size: 15px;
  //       font-weight: bold;
  //       color: white;
  //       .see {
  //         font-size: 10px;
  //         color: white;
  //       }
  //     }
  //   }
  // }
  .pagi {
    display: flex;
    margin: 20px auto;
    justify-content: center;
    width: 100%;
    ul {
      margin: 20px auto;
      li {
        border: none;
        a {
          color: #00aff0;
          border: none;
        }
      }
      .lastPage {
        display: none;
      }
      .active {
        a {
          background: #00aff0;
          color: white;
        }
      }
    }

    // .left {
    //   width: 30px;
    //   height: 30px;
    //   margin: 10px;
    //   transition: all 0.5s ease-in-out;
    //   &:hover {
    //     cursor: pointer;
    //   }
    //   .iconLeft {
    //     font-size: 20px;
    //     margin: 4px 0 0 0;
    //     color: #4092c2;
    //   }
    // }

    // .right {
    //   width: 30px;
    //   height: 30px;
    //   margin: 10px;
    //   transition: all 0.5s ease-in-out;
    //   &:hover {
    //     cursor: pointer;
    //   }
    //   .iconRight {
    //     font-size: 20px;
    //     margin: 4px 0 0 0;
    //     color: #4092c2;
    //   }
    // }
  }
}
