.mainClientDashboard {
  width: calc(100% - 480px);
  margin: 110px 0px 20px 360px;
  .loading {
    animation: rotation 2s infinite linear;
    width: 70px !important;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  .main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    margin: 0px auto 20px auto;
    grid-gap: 10px;
    background: #f2f2f2;
    padding: 30px 15px;

    position: relative;
    border-radius: 15px;

    .dashboardIcon {
      position: absolute;
      top: -76px;
      z-index: 20;
      border-bottom: 4px solid #fa9551;
      height: 35px;
      display: flex;
      // img {
      //   margin: 0 10px 0 15px;
      // }
      h4 {
        font-size: 15px;
        font-weight: bold !important;
        color: #2cace3;
      }
    }

    .videoApp,
    .waitRoom,
    .emailReq {
      display: flex;
      font-size: 40px;
      align-items: center;
      height: 90px;
      border-radius: 10px;
      border: 1.6px solid #b3b3b3;
      justify-content: flex-start;
      background: white;
      transition: all 0.5s ease-in-out;

      .email,
      .video,
      .clock {
        border-radius: 50%;
        align-self: center;
        margin: 0 10px;

        .icon {
          align-self: center;
        }
      }
      div {
        h2 {
          font-size: 15px;
          text-align: start;
          color: #666666;
          margin-top: 10px;

          // height: 50px;
        }
        .secondH2 {
          font-size: 15px;
          font-weight: 800;
          margin-top: 10px;
        }
      }

      &:hover {
        cursor: pointer;
        box-shadow: 2px 2px 10px rgb(153, 153, 153);
      }
    }
    .waitRoom {
      span {
        position: relative;
        p {
          position: absolute;
          bottom: -10px;
          right: 0;
          font-size: 20px;
          background: red;
          color: white;
          padding: 0 3px;
          border-radius: 5px;
        }
      }
    }
  }

  .pagi {
    display: flex;
    margin: 20px auto;
    justify-content: center;
    width: 100%;
    ul {
      margin: 20px auto;
      li {
        border: none;
        a {
          color: #00aff0;
          border: none;
        }
      }
      .lastPage {
        display: none;
      }
      .active {
        a {
          background: #00aff0;
          color: white;
        }
      }
    }

    // .left {
    //   width: 30px;
    //   height: 30px;
    //   margin: 10px;
    //   transition: all 0.5s ease-in-out;
    //   &:hover {
    //     cursor: pointer;
    //   }
    //   .iconLeft {
    //     font-size: 20px;
    //     margin: 4px 0 0 0;
    //     color: #4092c2;
    //   }
    // }

    // .right {
    //   width: 30px;
    //   height: 30px;
    //   margin: 10px;
    //   transition: all 0.5s ease-in-out;
    //   &:hover {
    //     cursor: pointer;
    //   }
    //   .iconRight {
    //     font-size: 20px;
    //     margin: 4px 0 0 0;
    //     color: #4092c2;
    //   }
    // }
  }

  .mainTabel {
    margin: 0 auto;
    height: 480px;
    border-radius: 15px;
    overflow: hidden;
    .NoResultDiv {
      color: #666666;
      margin: 20px auto;
      font-size: 20px;
    }
    .mainConsultation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fa9551;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      height: 40px;

      .icon_left {
        span {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin: 3px 10px;
          align-self: center;
          text-align: center;
          .icon1 {
            align-self: center;
            color: white;
            font-size: 22px;
            margin-top: 3px;
          }
        }
        p {
          align-self: center;
          margin: 0 0 0 30px;
          color: white;
        }
      }
      .sort {
        margin: 0 10px;
        display: flex;
        p {
          align-self: center;
          margin: 0 50px 0 0;
          text-overflow: bold;
          color: white;
          &:hover {
            cursor: pointer;
          }
        }
        .all {
          margin: 0 20px 0 0;
        }
      }
    }

    .table3 {
      text-align: center;
      font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
      margin: 0;
      padding: 10px;
      color: #666666;

      tbody {
        transition: all 0.5s ease-in-out;
        background: #f2f2f2;
        &:last-of-type {
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          overflow: hidden !important;
          tr {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            overflow: hidden !important;
            td {
              &:first-of-type {
                border-bottom-left-radius: 15px;
              }
              &:last-of-type {
                border-bottom-right-radius: 15px !important;
              }
            }
          }
        }

        tr {
          height: 40px;

          &:hover {
            background: #e6e6e6;
            cursor: pointer;
          }

          td {
            text-align: left;

            .finished {
              color: #00aff0;
              font-size: 17px;
            }
          }
          .client-status {
            text-align: center;
            width: 100px;

            &:hover {
              .check,
              .pendi,
              .finished,
              .declined {
                display: none;
              }
              .status {
                display: block;
              }
            }
          }
          .status {
            display: none;
          }
          .client-doctor {
            padding: 0 0 0 30px;
            white-space: nowrap !important;
            overflow: hidden !important;

            text-overflow: ellipsis !important;
          }
          .client-subject1 {
            white-space: nowrap !important;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            width: 350px;
          }
          .client-subject {
            white-space: nowrap !important;
            overflow: hidden !important;

            text-overflow: ellipsis !important;
          }
        }
        .created {
          margin: 0 auto;
          padding: 0 0 0 40px;
          p {
            margin: 0 auto;
          }
        }
      }
      .client-head {
        text-align: center;
        color: #666666;
        background-color: black !important;
        .client-row {
          height: 40px;
          background: #b3b3b3;
          .client-doctor {
            text-indent: 30px;
          }
          .client-subject {
          }

          .client-type {
          }
          .client-status {
          }
        }
      }
    }
  }
  .penTable {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    z-index: 5;

    button {
      width: 150px;
      height: 30px;
      background: rgb(221, 221, 221);
      border-radius: 5px;
      border: none;
      margin: 30px auto;
      transition: all 0.5s ease-in-out;
      outline: 0;
      // position: absolute;
      // left: 0;
      // bottom: 10%;
      // left: 50%;
      // transform: translate(-50%);

      &:hover {
        background: pink;
        box-shadow: 5px 5px 5px black;
      }
    }
  }
}

.table4 {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  padding: 10px;

  tbody {
    background: white;
    transition: all 0.5 ease-in-out;
    &:hover {
      background: lightblue;
    }
    tr {
      height: 40px;
      td {
      }

      .client-status {
      }
    }
  }
  .client-head {
    text-align: center;
    background-color: whitesmoke;
    color: #666666;

    .client-row {
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #b3b3b3;

      .client-doctor2 {
      }
      .client-subject2 {
      }
      .client-status2 {
      }
    }
  }
}
