// .sc-bdfBwQ {
//   position: relative;
//   .topBarDiv {
//     position: absolute;
//     top: -76px;
//     left: 0;
//     z-index: 20;
//     height: 35px;
//     display: flex;
//     border-bottom: 4px solid #fa9551;
//     margin-right: 45px;
//     h4 {
//       font-size: 15px;
//       color: #2cace3;
//     }
//   }
//   video {
//     width: 100% !important;
//     height: 100% !important;
//     border-radius: 15px;
//     object-fit: cover;
//   }
//   .MainIconsDiv {
//     display: flex;
//     width: 400px;
//     justify-content: space-around;
//     margin: 20px auto 0 auto;
//     img {
//       &:hover {
//         cursor: pointer;
//       }
//     }
//   }
// }

#jitsi-container {
  height: 100vh !important;
  #jitsiConferenceFrame0 {
    width: 100% !important;
  }
}

.detailExam {
  background: #f2f2f2;
  position: relative;
  padding: 30px 0px !important;
  border-radius: 15px;

  // top: 2px;
  // float: left;
  text-align: left;
  margin: 110px auto 20px 360px !important;
  width: calc(100% - 480px) !important;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  height: 100% !important;

  .iconVideooCall {
    position: absolute;
    top: -79px;
    z-index: 20;
    height: 35px;
    display: flex;
    border-bottom: 4px solid #fa9551;
    margin-right: 45px;
    h4 {
      font-size: 15px;
      color: #2cace3;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .MainIconsDiv {
    display: flex;
    width: 400px;
    justify-content: space-around;
    margin: 20px auto 0 auto;
    img {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .detail2 {
    color: #666666;
    display: flex;
    .detailInfo2 {
      width: 35%;
      margin: 0px 30px 0px 10px !important;
      overflow: hidden;
      height: 550px;
      border-bottom: 1px solid #fa9551;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      .ClientP {
        text-indent: 10px;
        span {
          font-weight: 900;
        }
      }

      .MainDivForChat {
        height: 100%;
        display: flex;
        flex-direction: column;
        // overflow: hidden;
        p {
          background: #fa9551;
          color: white;
          height: 30px;
          text-indent: 10px;
          font-weight: 900;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          display: flex;
          align-items: center;
          margin: 0;
        }
        form {
          height: 518px;
          overflow: hidden;
          position: relative;

          #messages {
            // height: 375px !important;
            height: 488px;
            margin: 0;
            padding: 20px;
            border-bottom: none;
            border-left: 1px solid #fa9551;
            border-right: 1px solid #fa9551;
            border-radius: 0;
            p {
              position: relative;
              margin-top: 25px;
              margin-bottom: 20px;
              padding-left: 10px;
              text-indent: 0;
              span {
                display: flex;
                position: absolute;
                top: -18px;
                left: -10px;
                width: 48px;
                text-indent: 0;
              }
            }

            &::-webkit-scrollbar {
              width: 5px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: #f1f1f1;
              border-radius: 5px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #e6e6e6;
              border: 1px solid white;
              border-radius: 5px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #1184c7;
            }
            p {
              font-weight: 400 !important;
              color: #666666;
            }
          }
          .inputMessage {
            display: flex;
            position: absolute;
            bottom: 0;
            width: 100%;
            // border: 1px solid #fa9551 !important;
            border-top: none !important;
            // border-bottom-left-radius: 10px;
            // border-bottom-right-radius: 10px;

            #yourMessage {
              border: 0;
              // width: calc(100% - 65px);
              border-left: 1px solid #fa9551 !important;
              border-right: 1px solid #fa9551 !important;
              width: 100%;
              height: 30px;
              resize: none;
              padding: 5px 35px 5px 5px;
              // border-radius: 10px;
            }
          }
        }
      }
      #send {
        position: absolute;
        right: 10px;
        bottom: 3px !important;
        z-index: 20;
        background: white;
        border: none;
        img {
          width: 26px;
          height: 26px !important;
        }
      }
      position: relative;
    }
    #videoChat {
      display: flex;
      justify-content: center;
      height: 550px;
      width: 100%;
      padding: 0px 0px 0px 30px !important;
      // position: relative;
      // background: green;
      .vid {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 15px;
        // position: absolute;
        // // right: -20px
        // top: 7px;
      }

      .extendScreen {
        position: absolute;
        z-index: 5;
        top: 480px;
        right: 20px;
        &:hover {
          cursor: pointer;
        }
      }
      #myVid {
        object-fit: cover;
        width: 170px !important;
        border-radius: 15px;
        height: 100px;

        video {
          // height: 100px !important;
          width: 170px !important;
          height: 140px !important;
          background: #e6e6e6;
          z-index: 20;
        }
      }
    }
  }

  .record-box {
    padding: 5px;
    h4 {
      margin: 0 auto 12px auto;
      color: #4092c2;
    }
    p {
      text-align: left;
      text-indent: 10px;
      color: #4092c2;
      span {
        font-weight: 900;
      }
    }
  }
  .message-btn {
    position: absolute;
    bottom: 20px;
    left: 30px;
    .btn {
      width: 120px;
      font-size: 14;
      height: 30px;
      padding: 0px;
      border-radius: 8px;
      background-color: #00aff0;
      color: white;
      font-size: 18px;
      transition: all 0.5s ease-in-out;
      outline: 0;

      &:disabled,
      button[disabled] {
        background: lightgray;
        color: white;
        cursor: not-allowed;
        box-shadow: none;
        pointer-events: none;
        border: none;
      }
    }
  }
  .btn1 {
    background: #00aff0;
    position: absolute;
    bottom: 40px;
    margin: 0;
    font-size: 14px;
    padding: 12px;
    font-weight: bold;
    color: white;
    width: 150px;
    border-radius: 6px;
    box-shadow: none !important;
    border: none !important;
    &:hover {
      color: white !important;
    }
    &:disabled,
    button[disabled] {
      background: lightgray;
      cursor: not-allowed;
      box-shadow: none;
      border: none;
      pointer-events: none;
    }
  }
}
