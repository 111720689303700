// .mainprof {
//   display: flex;
//   flex-direction: column;
//   width: 65% !important;
//   background: #f2f2f2;
//   margin: 80px auto 20px auto;
//   padding: 40px 40px;
//   position: relative;
//   border-radius: 15px;
//   border-top-left-radius: 0;
//   .iconVideo {
//     position: absolute;
//     border-top-right-radius: 15px;
//     border-top-left-radius: 15px;
//     font-size: 17px;
//     top: -40px;
//     left: 0;
//     padding: 0 0 0 20px;
//     height: 40px;
//     display: flex;
//     background: #f2f2f2;
//     width: 18%;
//     justify-content: flex-start;
//     align-items: center;
//     p {
//       margin: 0 0 0 5px;
//     }
//   }
//   .doctorPr {
//     display: flex;
//     width: 100%;
//     .imginput {
//       margin-right: 30px;
//       padding: 10px 0 0 0;
//       .doctor-p {
//         position: relative;
//         width: 200px;
//         border: 1.7px solid #fa9551;
//         border-radius: 10px;
//         height: 180px;
//         padding: 5px;

//         p {
//           position: absolute;
//           top: 50%;
//           left: 50%;
//           transform: translate(-50%, -50%);
//           color: white;
//         }
//       }
//       input {
//         margin: 8px 0;
//         width: 130px;
//         border-radius: 5px;
//       }
//     }

//     .info {
//       border-radius: 10px;
//       padding: 5px;
//       width: 50%;
//       text-align: start;
//       p {
//         text-indent: 10px;
//         color: #666666;
//         span {
//           font-weight: 700;
//         }
//       }
//     }
//     @media (max-width: 600px) {
//       display: flex;
//       flex-direction: column;
//     }
//   }

//   .form {
//     width: 100%;
//     margin: 30px auto;
//     display: flex;
//     flex-direction: column;

//     .description {
//       margin: 20px 0 0 0;
//       display: flex;

//       .description-input {
//         height: 135px;
//         width: 100%;
//         border: 1.7px solid #fa9551;
//         border-radius: 10px;
//         padding: 5px;
//         font-weight: 600;
//       }
//     }

//     .pref-price {
//       display: flex;
//       justify-content: space-between;

//       @media (max-width: 600px) {
//         flex-direction: column;
//       }

//       .prefix {
//         margin-right: 5px;

//         .prefix-input {
//           width: 200px;
//           left: 100px;
//           border: 1.7px solid #fa9551;
//           border-radius: 10px;
//           padding: 5px;
//           height: 40px;
//           font-weight: 600;
//         }
//       }

//       .price {
//         align-self: center;
//         margin-left: 5px;

//         .price-input {
//           width: 200px;
//           height: 40px;

//           border: 1.7px solid #fa9551;
//           border-radius: 10px;
//           padding: 5px;
//           font-weight: 600;
//         }
//       }
//     }

//     .submit {
//       padding: 0;
//       margin-top: 20px;

//       .btn {
//         width: 156px;
//         color: white;
//         background: #00aff0;
//         transition: all 0.5s ease-in-out;
//         outline: 0;
//         border-radius: 5px;
//         position: absolute;
//         margin: 0;
//         left: 40px;
//       }
//     }
//   }
// }

.mainDoctor {
  display: flex;

  width: 40%;
  margin: 110px auto 20px 360px;
  background: #f2f2f2;
  border-radius: 15px;
  position: relative;
  .newVideo {
    position: absolute;
    top: -76px;
    z-index: 20;
    height: 35px;
    display: flex;
    .profileDoc {
      // border-bottom: 4px solid #fa9551;
      margin-right: 45px;
      h4 {
        font-size: 15px;
        color: #2cace3;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  .doctor {
    width: 50%;
    padding: 30px 0px 30px 30px;

    .doctor-p {
      display: flex;
      flex-direction: column;
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
      input {
        height: 33px;
        border: 1.7px solid #fa9551;
        border-radius: 10px;
        background: white;
        margin: 2px 0 20px 0;
        text-indent: 15px;
        font-weight: 700;
        width: 90%;

        &::placeholder {
          font-weight: 700;
          color: #969696;
        }
      }
      label {
        color: #666666;
        text-align: left;
      }

      .saveChanges {
        background: #00aff0;
        width: 80px;
        height: 30px;
        color: white;
        border: none;
        border-radius: 10px;
        font-weight: 600;
        margin: 25px 0 0 0;
      }
    }
  }

  .form {
    .imageAndGender {
      display: flex;
      // .Gender {
      //   margin-left: 45px;
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: center;
      //   .male {
      //     margin-bottom: 10px;
      //   }
      //   .male,
      //   .female {
      //     display: flex;
      //     height: 20px;
      //     input {
      //       margin-right: 5px;
      //     }
      //     label {
      //       margin: 0;
      //       display: flex;
      //       align-items: center;
      //     }
      //     input[type="checkbox"] {
      //       visibility: hidden;
      //       position: absolute;
      //     }

      //     input[type="checkbox"] + label:before {
      //       height: 20px;
      //       width: 20px;
      //       margin-right: 5px;
      //       content: " ";
      //       display: inline-block;
      //       vertical-align: baseline;
      //       border: 1px solid #00aff0;
      //       border-radius: 50%;
      //     }

      //     input[type="checkbox"]:checked + label:before {
      //       background: #00aff0;
      //     }
      //   }
      // }
      .profilePic {
        display: flex;
        // float: right;
        // margin: 7px 0 20px 20px;
        width: 110px;
        height: 110px;
        border-radius: 10px;

        p {
          font-weight: 600;
          color: #666666;
          margin: 0;
        }
        .upload-btn-wrapper {
          position: relative;
          overflow: hidden;
          .btn {
            margin: 0;
            width: 110px;
            height: 110px;
            padding: 0;
            border-radius: 10px;

            background: transparent !important;
            .docImage {
              width: 107px;
              height: 107px;
              border-radius: 10px;

              object-fit: cover;
              // border-radius: 50%;
            }
            p {
              font-size: 80px;
              padding: 0;
            }
          }
          .deleteImage {
            position: absolute;
            width: 20px;
            height: 20px;
            background: red;
            color: white;
            border: none;
            border-radius: 5px;
            z-index: 1000;
            top: 5px;
            left: 85px;
          }
        }

        .upload-btn-wrapper input[type="file"] {
          font-size: 0px;
          position: absolute;

          left: 0;
          top: -20px;
          width: 110px;
          height: 140px;
          opacity: 0;
          cursor: pointer;
        }
      }
    }

    padding: 30px 30px 30px 0px;
    width: 50%;
    color: #666666;
    .pricing {
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 30px 0;
    }

    .allergies {
      text-align: start;
      font-weight: 700;
      color: #666666;
      // display: flex;
      // flex-direction: column;
      margin: 25px 0 0 0;
      .Nameing {
        display: flex;
        justify-content: space-between;
        p {
          margin-bottom: 7px;
        }
      }

      .textAndProfile {
        // display: flex;
        // justify-content: flex-end;
        // flex-direction: row;
        // height: 100%;

        // border: 1.7px solid #fa9551;
        // border-radius: 10px;
        // background: white;
        // // margin: 7px 0 0 0;
        // padding: 10px 15px;
        // font-weight: 700;
        // width: 100%;
        // overflow-y: scroll;
        // resize: none;

        // &::-webkit-scrollbar {
        //   width: 5px;
        // }

        // &::-webkit-scrollbar-button:single-button {
        //   // background-color: #bbbbbb;
        //   display: block;

        //   height: 13px;
        //   width: 16px;
        // }

        // &::-webkit-scrollbar-track {
        //   background: #f1f1f1;
        //   border-radius: 5px;
        // }

        // &::-webkit-scrollbar-thumb {
        //   background: #fa9551;
        //   border-radius: 5px;
        // }

        // &::-webkit-scrollbar-thumb:hover {
        //   background: #db8246;
        // }

        // &::placeholder {
        //   font-weight: 700;
        //   color: #969696;
        // }
        textarea {
          border: 1.7px solid #fa9551;
          border-radius: 10px;
          background: white;
          // margin: 7px 0 0 0;
          padding: 10px;
          font-weight: 700;
          width: 100%;
          height: 115px;
          resize: none;

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-button:single-button {
            // background-color: #bbbbbb;
            display: block;

            height: 13px;
            width: 16px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 5px;
            margin: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: #fa9551;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #db8246;
          }

          &::placeholder {
            font-weight: 700;
            color: #969696;
          }
        }

        .changeDiv {
          height: 100%;
          color: #969696;
        }
      }
    }
    label {
      color: #666666;
      text-align: left;
      width: 100%;
    }
  }
  .Services {
    width: 100%;
    padding: 30px;
    color: #666666;
    .mainEmailVisit {
      display: flex;
      opacity: 0.4;
      .emailVisit {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0px 0px 10px 0px;
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }

        input {
          height: 33px;
          border: 1.7px solid #fa9551;
          border-radius: 10px;
          background: white;
          margin: 0 0 0 auto;

          text-indent: 15px;
          font-weight: 700;
          width: 85px;
          &::placeholder {
            color: #969696;
          }
        }
        label {
          width: 180px;
        }
      }
      input[type="checkbox"] {
        visibility: hidden;
        position: absolute;
      }

      input[type="checkbox"] + label:before {
        height: 20px;
        width: 20px;
        margin-right: 5px;
        content: " ";
        display: inline-block;
        vertical-align: baseline;
        border: 1px solid #00aff0;
        border-radius: 50%;
      }

      input[type="checkbox"]:checked + label:before {
        background: #00aff0;
      }
      label {
        display: flex;
        align-items: center;
        margin: 0;
      }
    }
    .mainVideoVisit {
      display: flex;
      opacity: 0.4;
      .videoVisit {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }

        input {
          height: 33px;
          border: 1.7px solid #fa9551;
          border-radius: 10px;
          background: white;
          margin: 0 0 0 auto;

          text-indent: 15px;
          font-weight: 700;
          width: 85px;
          &::placeholder {
            color: #969696;
          }
        }
        label {
          width: 180px;
        }
      }
      input[type="checkbox"] {
        visibility: hidden;
        position: absolute;
      }

      input[type="checkbox"] + label:before {
        height: 20px;
        width: 20px;
        margin-right: 5px;
        content: " ";
        display: inline-block;
        vertical-align: baseline;
        border: 1px solid #00aff0;
        border-radius: 50%;
      }

      input[type="checkbox"]:checked + label:before {
        background: #00aff0;
      }
      label {
        display: flex;
        align-items: center;
        margin: 0;
      }
    }
    .mainVideoFollowUp {
      display: flex;
      opacity: 0.4;
      .videoFollowUp {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 10px 0px 10px 0px;
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }

        input {
          height: 33px;
          border: 1.7px solid #fa9551;
          border-radius: 10px;
          background: white;
          margin: 0 0 0 auto;
          text-indent: 15px;
          font-weight: 700;
          width: 85px;
          &::placeholder {
            color: #969696;
          }
        }
        label {
          width: 180px;
        }
      }
      input[type="checkbox"] {
        visibility: hidden;
        position: absolute;
      }

      input[type="checkbox"] + label:before {
        height: 20px;
        width: 20px;
        margin-right: 5px;
        content: " ";
        display: inline-block;
        vertical-align: baseline;
        border: 1px solid #00aff0;
        border-radius: 50%;
      }

      input[type="checkbox"]:checked + label:before {
        background: #00aff0;
      }
      label {
        display: flex;
        align-items: center;
        margin: 0;
      }
    }
    .mainWaitingRoomVisit {
      display: flex;
      opacity: 0.4;
      .WaitingRoomVisit {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0px;
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }

        input {
          height: 33px;
          border: 1.7px solid #fa9551;
          border-radius: 10px;
          background: white;
          margin: 0 0 0 auto;
          text-indent: 15px;
          font-weight: 700;
          width: 85px;
          &::placeholder {
            color: #969696;
          }
        }
        label {
          width: 180px;
        }
      }
      input[type="checkbox"] {
        visibility: hidden;
        position: absolute;
      }

      input[type="checkbox"] + label:before {
        height: 20px;
        width: 20px;
        margin-right: 5px;
        content: " ";
        display: inline-block;
        vertical-align: baseline;
        border: 1px solid #00aff0;
        border-radius: 50%;
      }

      input[type="checkbox"]:checked + label:before {
        background: #00aff0;
      }
      label {
        display: flex;
        align-items: center;
        margin: 0;
      }
    }
    .saveChanges {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #00aff0;
      width: 80px;
      height: 30px;
      color: white;
      border: none;
      border-radius: 10px;
      font-weight: 600;
      margin: 45px auto 0 0px !important;
    }
  }
  .mainWorkHours {
    width: 100%;
    padding: 30px;
    .workHoursDiv {
      display: flex;
      justify-content: flex-start;
      margin: 0 0 20px 0;
      // width: 90%;
      height: 33px;
      color: #666666;
      // input {
      //   margin: 0;
      //   font-size: 14px;
      //   text-align: center;
      //   width: 90px;
      //   text-indent: 0;
      //   &::placeholder {
      //     // color: blue;
      //     color: #969696;

      //     text-align: center;
      //   }
      //   height: 33px;
      //   border: 1.7px solid #fa9551;
      //   border-radius: 10px;
      //   background: white;
      //   font-weight: 500;
      // }
      // input[type="time"]::-webkit-calendar-picker-indicator {
      //   background: none;
      //   display: none;
      // }
      // input::-webkit-datetime-edit-ampm-field {
      //   display: none;
      // }
      p {
        display: flex;
        margin: 0 10px 0 10px;
        font-size: 29px;
        align-items: center;
      }

      input {
        margin: 0;
        font-size: 14px;
        text-align: center;
        width: 90px !important;
        text-indent: 0;
        &::placeholder {
          // color: blue;
          color: #969696;

          text-align: center;
        }
        height: 33px;
        border: 1.7px solid #fa9551;
        border-radius: 10px;
        background: white;
        font-weight: 500;
        .react-time-picker__wrapper {
          border: none;
        }
      }
      img {
        width: 20px;
        height: 20px;
        margin: 6px 0 0 10px;
      }
    }
    img {
      width: 28px;
      height: 28px;
      display: flex;
      margin: 20px auto 0 0;
      &:hover {
        cursor: pointer;
      }
    }
    .saveChanges {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #00aff0;
      width: 80px;
      height: 30px;
      color: white;
      border: none;
      border-radius: 10px;
      font-weight: 600;
      margin: 45px auto 0 0px !important;
    }
  }
}
