.detail_exam {
  background: #f2f2f2;
  position: relative;
  padding: 30px 0px !important;
  border-radius: 15px;
  text-align: left;
  margin: 110px auto 20px 360px;
  width: calc(100% - 480px);
  min-height: 350px;
  display: flex;
  .iconVideo {
    position: absolute;
    top: -77px;
    z-index: 20;
    height: 35px;
    display: flex;
    .ConsulDetails {
      // border-bottom: 4px solid #fa9551;
      margin-right: 45px;
      h4 {
        font-size: 15px;
        color: #2cace3;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .detail {
    padding: 5px 10px 0px 30px;
    position: relative;
    width: 350px;
    .divSelectButton {
      display: flex;
      .select-option {
        width: 200px;
        // border: 1px solid #fa9551;
      }
      .btnSend {
        margin: 0 10px;
        border: 1px solid #377da5;
        background: #4092c2;
        color: white;
        padding: 0 5px;
        border-radius: 5px;
      }
    }
    p {
      color: #666666;
      border: none;
      span {
        font-weight: 900;
      }
    }

    .col-sm-10 {
      position: relative;
      height: 50px;

      .btn {
        position: relative;
        border: 1px solid #4092c2;
        height: 37px;
        left: 120px;
        top: -37px;
      }
    }
    .message-btn {
      position: absolute;
      bottom: 0;
      // margin: 0 0 0 -10px;
      button {
        background: #00aff0;
        margin: 0;
        font-size: 14px;
        width: 120px;
        padding: 0;
        height: 30px;
        border-radius: 6px;
        &:disabled,
        button[disabled] {
          background: lightgray;
          color: white;
          cursor: not-allowed;
          box-shadow: none;
          pointer-events: none;
          border: none;
        }
      }
    }
  }
  .mainMessageDiv {
    width: 100%;
    padding: 0 30px 0 0;
    .subjectDiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fa9551;
      color: white;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      height: 30px;
      p {
        margin: 0px 15px 0 15px;
        // font-weight: 700;

        span {
          // font-weight: 700;
          margin-right: 5px;
        }
      }
      .infoAndSquare {
        align-items: center;
        p {
          margin: 0px 15px 0 15px;
          // font-weight: 700;
          span {
            margin-right: 5px;
            align-items: center;
          }
        }
        #imageDiv1 {
          background: transparent;
          border: 1.7px solid white;
          // position: absolute;
          width: 20px;
          border-radius: 5px;
          height: 20px;
          margin: 0px 20px 0 0;
          display: none;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .messageDiv {
      min-height: 160px;
      width: 100%;
      background: white;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      padding: 10px 15px !important;

      // padding: 10px 13px 0 13px;
      textarea {
        resize: none;
        width: 100%;
        min-height: 100px !important;
        border: none;
        // padding: 10px 15px;
        border-bottom-left-radius: 15px;
        background: white;
        border-bottom-right-radius: 15px;
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-button:single-button {
          // background-color: #bbbbbb;
          display: block;

          height: 13px;
          width: 16px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: #fa9551;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #db8246;
        }
      }
      .mainFileDiv {
        border-top: 1px solid #f2f2f2;
        height: 30px;
        display: flex;
        .FileDiv {
          height: 40px;
          p {
            // border: 1.7px solid #00aff0;
            color: #00aff0;
            font-weight: bold;
            width: 50px;
            height: 25px;
            border-radius: 5px;
            text-align: start;
            align-items: center;
            margin: 5px 0px 0 0;
          }
        }
        .fileForDownload {
          display: flex;
          // border: 1.7px solid #00aff0;
          color: #00aff0;
          // width: 100px;
          border-radius: 5px;
          text-align: left;
          align-items: center;
          justify-content: flex-start;
          height: 25px;
          margin: 2px 0 0 0;
          padding: 0 5px;
          overflow: hidden;
          white-space: nowrap;
          p {
            margin: 0;
            width: 100px;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
              text-overflow: scroll;
              width: 350px;
            }
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
      .mainFileDiv {
        border-top: 1px solid #f2f2f2;
        height: 30px;
        display: flex;
        .FileDiv {
          height: 40px;
          p {
            // border: 1.7px solid #00aff0;
            color: #00aff0;
            font-weight: bold;
            width: 50px;
            height: 25px;
            border-radius: 5px;
            text-align: start;
            align-items: center;
            margin: 5px 0px 0 0;
          }
        }
        .fileForDownload {
          display: flex;
          // border: 1.7px solid #00aff0;
          color: #00aff0;
          // width: 100px;
          border-radius: 5px;
          text-align: left;
          align-items: center;
          justify-content: flex-start;
          height: 25px;
          margin: 2px 0 0 0;
          padding: 0 5px;
          overflow: hidden;
          white-space: nowrap;
          p {
            margin: 0;
            width: 100px;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:hover {
              text-overflow: scroll;
              width: 350px;
            }
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    .reportIfDeclined,
    .reportIfFinished {
      margin: 20px 0 0 0;
      // height: 400px;
      // height: 45%;
      .subjectDiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #e6e6e6;
        color: #666666;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        height: 30px;
        p {
          margin: 0px 15px 0 15px;
          // font-weight: 700;

          span {
            // font-weight: 700;
            margin-right: 5px;
          }
        }
        #imageDiv2,
        #imageDiv3 {
          background: transparent;
          border: 1.7px solid white;
          // position: absolute;
          width: 20px;
          border-radius: 5px;
          height: 20px;
          margin: 0px 20px 0 0;
          // display: none;
          &:hover {
            cursor: pointer;
          }
        }
      }
      .messageDivReport {
        width: 100%;
        // height: 72%;
        background: white;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        // padding: 10px 13px 0 13px;
        textarea {
          width: 100%;
          min-height: 130px;
          padding: 10px;

          background: white;
          border: none;
          resize: none;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-button:single-button {
            // background-color: #bbbbbb;
            display: block;

            height: 13px;
            width: 16px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb {
            background: #fa9551;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #db8246;
          }
        }
      }
      .sendbuttonAndAtt {
        display: flex;
        flex-direction: column;
        background: white;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;

        margin: 0;
        padding: 0 15px;
        button {
          color: white;
          background: #00aff0;
          border: none;
          height: 30px;
          width: 80px;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0px 10px 10px 0px;
          transition: all 0.5s ease-in-out;
          .replyIcon {
            font-size: 20px;
          }
          // &:hover {
          //   box-shadow: 2px 2px 1px black;
          // }
        }
        .upload-btn-wrapper {
          position: relative;
          overflow: hidden;
          display: inline-block;
        }

        .btn {
          // border: 1px solid #00aff0;
          // color: white;
          // background-color: white;
          // width: 50px;
          // height: 25px;
          // border-radius: 5px;
          // font-size: 14px;
          // font-weight: bold;
          outline: none;
          // // &:hover {
          // //   cursor: pointer;
          // //   background: #4092c2;
          // //   color: white;
          // // }
          // border: 1.7px solid #00aff0;
          // color: #00aff0;
          // font-weight: bold;
          // width: 60px;
          // height: 25px;
          // border-radius: 5px;
          // text-align: center;
          // justify-content: center;
          // align-items: center;
          // margin: 5px 10px 0 0;
          // text-indent: 0;

          background: white;
          border: 1.7px solid #00aff0;
          height: 30px;
          color: #00aff0;
          font-weight: bold;
          padding: 0 5px;
          width: 70px;
          display: flex;
          align-items: center;
          border-radius: 5px;
          margin: 0;
          align-items: center;
          box-shadow: none;
          p {
            margin: 0;
          }
        }

        .upload-btn-wrapper input[type="file"] {
          font-size: 0px;
          position: absolute;
          left: 0;
          top: 0;
          width: 70px;
          height: 30px;
          // margin-left: 20px;
          cursor: pointer;
          opacity: 0;
        }
        .fileForDownload {
          display: flex;
          // border: 1.7px solid #00aff0;
          color: #00aff0;
          // width: 100px;
          border-radius: 5px;
          text-align: left;
          align-items: center;
          justify-content: flex-start;
          height: 30px;
          margin: 0 0 0 20px;
          padding: 0 5px;
          overflow: hidden;
          white-space: nowrap;
          p {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100px;
            margin: 0 2px 0 0;
            color: #00aff0;
            text-indent: 0;
            &:hover {
              text-overflow: scroll;
              max-width: 350px;
            }
          }
          .deleteB {
            color: gray;
            width: 20px;
            text-align: center;
            text-decoration: none;
            border-radius: 5px;
            border: none;
            &:hover {
              cursor: pointer;
            }
          }
        }
        .attachess {
          display: flex;
          border-top: 1.7px solid #f2f2f2;
          padding: 5px 0 0 0;
          height: 40px;

          .showFiles {
            color: #00aff0;
            font-weight: bold;
            // width: 30px;
            height: 25px;
            border-radius: 5px;
            text-align: start;
            align-items: center;
            margin: 5px 0px 0 0;
          }
        }
      }
      button {
        color: white;
        background: #00aff0;
        border: none;
        border-radius: 5px;
        padding: 5px 10px;
        font-weight: bold;
        margin: 20px auto 0px 0;
      }
    }
    .btnForReport {
      color: white;
      background: #00aff0;
      border: none;
      border-radius: 5px;
      padding: 5px 10px;
      font-weight: 600;
      margin: 20px auto 20px 0;
    }
  }
  .sideMessageDetails {
    width: 100%;
    display: flex;
    padding: 0 30px 0 0;
    flex-direction: column;
    .SubjectMessage {
      .sideSub {
        background: #fa9551;
        color: white;
        height: 30px;
        display: flex;
        margin: 0;
        justify-content: space-between;
        align-items: center;
        // text-indent: 10px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        p {
          margin: 0px 15px 0 15px;

          // font-weight: 700;
          span {
            margin-right: 5px;
          }
        }
        .infoAndSquare {
          align-items: center;
          p {
            margin: 0px 15px 0 15px;
            // font-weight: 700;
            span {
              margin-right: 5px;
              align-items: center;
            }
          }
          #imageDiv1 {
            background: transparent;
            border: 1.7px solid white;
            // position: absolute;
            width: 20px;
            border-radius: 5px;
            height: 20px;
            margin: 0px 20px 0 0;
            display: none;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .messageDiv {
        background: white;
        color: #666666;
        min-height: 160px;
        padding: 10px 15px !important;

        // padding: 10px 0 0 0;
        // text-indent: 20px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        textarea {
          width: 100%;
          min-height: 100px !important;
          // padding: 10px;
          background: white;
          // text-indent: 10px;
          border: none;
          resize: none;
          // border-bottom-left-radius: 15px;
          // border-bottom-right-radius: 15px;
          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-button:single-button {
            // background-color: #bbbbbb;
            display: block;

            height: 13px;
            width: 16px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb {
            background: #fa9551;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #db8246;
          }
        }
        .mainFileDiv {
          border-top: 1px solid #f2f2f2;
          height: 30px;
          display: flex;
          .FileDiv {
            height: 40px;
            p {
              // border: 1.7px solid #00aff0;
              color: #00aff0;
              font-weight: bold;
              width: 50px;
              height: 25px;
              border-radius: 5px;
              text-align: start;
              align-items: center;
              margin: 5px 0px 0 0;
            }
          }
          .fileForDownload {
            display: flex;
            // border: 1.7px solid #00aff0;
            color: #00aff0;
            // width: 100px;
            border-radius: 5px;
            text-align: left;
            align-items: center;
            justify-content: flex-start;
            height: 25px;
            margin: 2px 0 0 0;
            padding: 0 5px;
            overflow: hidden;
            white-space: nowrap;
            p {
              margin: 0;
              width: 100px;
              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              &:hover {
                text-overflow: scroll;
                width: 350px;
              }
            }
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .reportIfDeclined,
      .reportIfFinished {
        margin: 20px 0 0 0;
        // height: 45%;
        .subjectDiv {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #e6e6e6;
          color: #666666;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          height: 30px;
          p {
            margin: 0px 15px 0 15px;
            // font-weight: 700;

            span {
              // font-weight: 700;
              margin-right: 5px;
            }
          }
        }
        .messageDivReport {
          width: 100%;
          // height: 72%;
          background: white;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          // padding: 10px 13px 0 13px;
          textarea {
            width: 100%;
            min-height: 130px;
            padding: 10px;
            background: white;
            border: none;
            resize: none;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            &::-webkit-scrollbar {
              width: 5px;
            }

            &::-webkit-scrollbar-button:single-button {
              // background-color: #bbbbbb;
              display: block;

              height: 13px;
              width: 16px;
            }

            &::-webkit-scrollbar-track {
              background: #f1f1f1;
              border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb {
              background: #fa9551;
              border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: #db8246;
            }
          }
        }
        button {
          color: white;
          background: #00aff0;
          border: none;
          width: 80px;
          height: 30px;
          border-radius: 5px;
          padding: 5px 10px;
          font-weight: bold;
          margin: 45px auto 0px 0;
        }
      }
    }
    .mainMessageCorrespondence {
      .MessageCorrespondence {
        width: 100%;
        margin-top: 30px;

        .Maintbody {
          margin: 0 0 20px auto;

          p {
            text-indent: 20px;
            border: none;
            span {
              font-weight: bold;
            }
          }
          .senderMaiin {
            height: 30px;
            display: flex;
            background: #fa9551;
            justify-content: space-between;
            color: white;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            position: relative;
            .senderP {
              margin-top: 5px;
              // width: 70%;
              flex: 3;
              text-indent: 10px;
              span {
                font-weight: 900;
              }
            }
            .createdP {
              margin: 5px 0 0 0;
              width: 150px;
            }
            #imageDiv {
              background: transparent;
              border: 1.7px solid white;
              // position: absolute;
              width: 20px;
              border-radius: 5px;
              height: 20px;
              margin: 5px 20px 0 0;
              display: none;
              &:hover {
                cursor: pointer;
              }
            }
          }
          .row1 {
            background: white;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            padding: 10px 15px;
            textarea {
              background: white;
              color: #666666;
              width: 100%;
              min-height: 100px;
              resize: none;
              border: none;
              // border-bottom-left-radius: 15px;
              // border-bottom-right-radius: 15px;
              &::-webkit-scrollbar {
                width: 5px;
              }

              &::-webkit-scrollbar-button:single-button {
                // background-color: #bbbbbb;
                display: block;

                height: 13px;
                width: 16px;
              }

              &::-webkit-scrollbar-track {
                background: #f1f1f1;
                border-radius: 5px;
              }

              &::-webkit-scrollbar-thumb {
                background: #fa9551;
                border-radius: 5px;
              }

              &::-webkit-scrollbar-thumb:hover {
                background: #db8246;
              }
            }
            .mainFileDiv {
              border-top: 1px solid #f2f2f2;
              height: 30px;
              display: flex;
              .FileDiv {
                height: 40px;
                display: flex;
                p {
                  // border: 1.7px solid #00aff0;
                  color: #00aff0;
                  font-weight: bold;
                  width: 50px;
                  height: 25px;
                  border-radius: 5px;
                  text-align: start;
                  justify-content: center;
                  align-items: center;
                  margin: 5px 0px 0 0;
                  text-indent: 0;
                }
              }
              .fileForDownload {
                display: flex;
                // border: 1.7px solid #00aff0;
                color: #00aff0;
                // width: 100px;
                border-radius: 5px;
                text-align: left;
                align-items: center;
                justify-content: flex-start;
                height: 25px;
                margin: 2px 0 0 0;
                padding: 0 5px;
                overflow: hidden;
                white-space: nowrap;
                p {
                  display: inline-block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 100px;
                  margin: 0;
                  color: #00aff0;
                  text-indent: 0;
                  &:hover {
                    text-overflow: scroll;
                    width: 350px;
                  }
                }
                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
          .ReplyMainDiv {
            margin-left: 20px;
            button {
              color: white;
              background: #00aff0;
              border: none;
              height: 40px;
              width: 80px;
              border-radius: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              .replyIcon {
                font-size: 20px;
              }
            }
          }
          .SendMainDiv {
            border: none;
            border-radius: 15px;
            margin: 10px auto 0 auto;
            box-shadow: none;
            background: white;
            textarea {
              margin: 0;
              width: 100%;
              padding: 10px 10px;
              min-height: 200px;
              resize: none;
              border: none;
              text-indent: 10px;
              &::-webkit-scrollbar {
                width: 5px;
              }

              &::-webkit-scrollbar-button:single-button {
                // background-color: #bbbbbb;
                display: block;

                height: 13px;
                width: 16px;
              }

              &::-webkit-scrollbar-track {
                background: #f1f1f1;
                border-radius: 5px;
              }

              &::-webkit-scrollbar-thumb {
                background: #fa9551;
                border-radius: 5px;
              }

              &::-webkit-scrollbar-thumb:hover {
                background: #db8246;
              }
            }
            .sendbuttonAndAtt {
              display: flex;
              background: white;
              border-bottom-left-radius: 15px;
              border-bottom-right-radius: 15px;
              margin: 0;
              padding: 0;
              button {
                color: white;
                background: #00aff0;
                border: none;
                height: 30px;
                width: 80px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0px 10px 20px 20px;
                transition: all 0.5s ease-in-out;
                box-shadow: none;
                .replyIcon {
                  font-size: 20px;
                }
                // &:hover {
                //   box-shadow: 2px 2px 1px black;
                // }
              }
              .upload-btn-wrapper {
                position: relative;
                overflow: hidden;
                display: inline-block;
              }

              .btn {
                color: white;
                background-color: #00aff0;
                width: 30px;
                border-radius: 8px;
                font-size: 30px;
                font-weight: bold;
                outline: none;
                // &:hover {
                //   cursor: pointer;
                //   background: #4092c2;
                //   color: white;
                // }
              }

              .upload-btn-wrapper input[type="file"] {
                font-size: 0px;
                position: absolute;
                left: 0;
                top: 0;
                margin-left: 20px;
                width: 40px;
                height: 40px;
                cursor: pointer;
                opacity: 0;
              }
              .fileForDownload {
                display: flex;
                // border: 1.7px solid #00aff0;
                color: #00aff0;
                // width: 100px;
                border-radius: 5px;
                text-align: left;
                align-items: center;
                justify-content: flex-start;
                height: 25px;
                margin: 2px 0 0 0;
                padding: 0 5px;
                overflow: hidden;
                white-space: nowrap;
                p {
                  display: inline-block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  width: 100px;
                  margin: 0;
                  color: #00aff0;
                  text-indent: 0;
                  &:hover {
                    text-overflow: scroll;
                    width: 350px;
                  }
                }

                &:hover {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
      .ReplyMainDiv {
        margin-left: 20px;
        button {
          color: white;
          background: #00aff0;
          border: 0;
          height: 40px;
          width: 80px;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          .replyIcon {
            font-size: 20px;
          }
        }
        &:disabled,
        button[disabled] {
          background: lightgray;
          color: white;
          cursor: not-allowed;
          box-shadow: none;
          pointer-events: none;
          border: none;
        }
      }
      .SendMainDiv {
        border: none;
        border-radius: 15px;
        margin: 10px auto 0 auto;
        box-shadow: none;
        background: white;
        .senderMaiin {
          height: 30px;
          display: flex;
          background: #00aff0;
          justify-content: space-between;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          color: white;
          text-indent: 15px;

          .senderP {
            margin-top: 5px;
            span {
              font-weight: 900;
            }
          }
          .createdP {
            margin: 5px 20px 0 0;
          }
        }
        textarea {
          width: 100%;
          resize: none;
          border: none;
          height: 200px;
          text-indent: 0;
          padding: 10px 15px;
          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-button:single-button {
            // background-color: #bbbbbb;
            display: block;

            height: 13px;
            width: 16px;
          }

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb {
            background: #fa9551;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #db8246;
          }
        }
        .sendbuttonAndAtt {
          display: flex;
          flex-direction: column;
          background: white;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;

          margin: 0;
          padding: 0 15px;
          button {
            color: white;
            background: #00aff0;
            border: none;
            height: 30px;
            width: 80px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px 10px 10px 0px;
            transition: all 0.5s ease-in-out;
            .replyIcon {
              font-size: 20px;
            }
            // &:hover {
            //   box-shadow: 2px 2px 1px black;
            // }
          }
          .upload-btn-wrapper {
            position: relative;
            overflow: hidden;
            display: inline-block;
          }

          .btn {
            // border: 1px solid #00aff0;
            // color: white;
            // background-color: white;
            // width: 50px;
            // height: 25px;
            // border-radius: 5px;
            // font-size: 14px;
            // font-weight: bold;
            outline: none;
            // // &:hover {
            // //   cursor: pointer;
            // //   background: #4092c2;
            // //   color: white;
            // // }
            // border: 1.7px solid #00aff0;
            // color: #00aff0;
            // font-weight: bold;
            // width: 60px;
            // height: 25px;
            // border-radius: 5px;
            // text-align: center;
            // justify-content: center;
            // align-items: center;
            // margin: 5px 10px 0 0;
            // text-indent: 0;

            background: white;
            border: 1.7px solid #00aff0;
            height: 30px;
            color: #00aff0;
            font-weight: bold;
            padding: 0 5px;
            width: 70px;
            display: flex;
            align-items: center;
            border-radius: 5px;
            margin: 0;
            align-items: center;
            box-shadow: none;
            p {
              margin: 0;
            }
          }

          .upload-btn-wrapper input[type="file"] {
            font-size: 0px;
            position: absolute;
            left: 0;
            top: 0;
            width: 70px;
            height: 30px;
            // margin-left: 20px;
            cursor: pointer;
            opacity: 0;
          }
          .fileForDownload {
            display: flex;
            // border: 1.7px solid #00aff0;
            color: #00aff0;
            // width: 100px;
            border-radius: 5px;
            text-align: left;
            align-items: center;
            justify-content: flex-start;
            height: 30px;
            margin: 0 0 0 20px;
            padding: 0 5px;
            overflow: hidden;
            white-space: nowrap;
            p {
              white-space: nowrap;
              max-width: 100px;
              margin: 0 2px 0 0;
              color: #00aff0;
              text-indent: 0;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              &:hover {
                text-overflow: scroll;
                max-width: 350px;
              }
            }
            .deleteB {
              color: gray;
              width: 20px;
              text-align: center;
              text-decoration: none;
              border-radius: 5px;
              border: none;
              &:hover {
                cursor: pointer;
              }
            }
          }
          .attachess {
            display: flex;
            border-top: 1.7px solid #f2f2f2;
            padding: 5px 0 0 0;
            height: 40px;
          }
        }
      }
    }
    .newMessage {
      border: 2px solid #00aff0;
      color: #00aff0;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      position: relative;
      transition: all 0.5s ease-in-out;
      float: right;
      margin: auto 0 0 auto;
      h1 {
        margin: 0;
        position: absolute;
        font-size: 40px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover {
        background: #00aff0;
        color: white;
      }
    }
    .sendButtonForReplay {
      color: white;
      background: #00aff0;
      border: none;
      height: 30px;
      width: 80px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 10px 10px 0px;
      transition: all 0.5s ease-in-out;
      .replyIcon {
        font-size: 20px;
      }
      // &:hover {
      //   box-shadow: 2px 2px 1px black;
      // }
    }
  }
  .btn1 {
    background: #00aff0;
    position: absolute;
    bottom: 40px;
    margin: 0;
    font-size: 14px;
    padding: 12px;
    font-weight: bold;
    color: white;
    width: 150px;
    border-radius: 6px;
    box-shadow: none !important;
    border: none !important;
    &:hover {
      color: white !important;
    }
    &:disabled,
    button[disabled] {
      background: lightgray;
      cursor: not-allowed;
      box-shadow: none;
      border: none;
      pointer-events: none;
    }
  }
}

.mainClien {
  display: flex;
  flex-direction: column;
  // width: calc(100% - 480px) !important;
  width: 40% !important;
  margin: 110px 120px 40px 360px;
  background: #f2f2f2;
  padding: 30px;
  border-radius: 15px;

  position: relative;
  .iconVideoo {
    position: absolute;
    top: -79px;
    left: 0;
    z-index: 20;
    height: 35px;
    display: flex;
    .ConsulDetails {
      // border-bottom: 4px solid #fa9551;
      margin-right: 45px;
      h4 {
        font-size: 15px;
        color: #2cace3;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  .client {
    width: 100%;
    padding: 20px 20px 20px 20px;
    border: 1.7px solid #fa9551;
    border-radius: 15px;
    height: 250px;
    display: flex;
    margin: 0 0 20px 0 !important;
    background: white;
    justify-content: flex-start;

    img {
      width: 110px;
      height: 110px;
      // border: 1.7px solid #666666;
      border-radius: 10px;
      object-fit: cover;
    }

    .client-p {
      display: flex;
      flex-direction: column;
      text-align: left;
      padding: 0 0 0 20px;
      p {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 20px;
        // padding: 20px 0 0 0;
      }
      h5 {
        font-weight: 900;
        color: #666666;
        margin: 0 0 20px 0;
        span {
          font-weight: 400;
        }
      }
    }
  }

  .form {
    // padding: 20px 15px 20px 20px;
    padding: 0px !important;
    margin: 0;
    height: 300px;
    width: 100%;
    color: #666666;

    .conditionss {
      display: flex;
      flex-direction: column;
      text-align: start;
      height: 140px;
      border: 1.7px solid #fa9551;
      border-radius: 15px;
      font-weight: 900;
      color: #666666;
      margin: 0 0 20px 0;
      padding: 10px;
      background: white;
      span {
        font-size: 16px;
      }
      textarea {
        background: transparent;
        border: none;
        // padding: 15px 0 15px 0
        font-weight: 400;
        resize: none;
        min-height: 100px;
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-button:single-button {
          // background-color: #bbbbbb;
          display: block;

          height: 13px;
          width: 16px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: #fa9551;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #db8246;
        }
      }
    }
    .allergiess {
      display: flex;
      flex-direction: column;
      text-align: start;
      font-weight: 900;
      border: 1.7px solid #fa9551;
      border-radius: 15px;
      height: 140px;
      color: #666666;
      margin: 20px 0;
      padding: 10px;
      background: white;
      span {
        font-size: 16px;
      }
      textarea {
        background: transparent;
        border: none;
        // padding: 15px 0 15px 0;
        font-weight: 400;
        resize: none;
        min-height: 100px;
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-button:single-button {
          // background-color: #bbbbbb;
          display: block;

          height: 13px;
          width: 16px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: #fa9551;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #db8246;
        }
      }
    }
  }
}
.consultMain {
  background: #f2f2f2;
  position: relative;
  // padding: 30px 0px !important;
  border-radius: 15px;
  text-align: left;
  margin: 110px auto 20px 360px;
  width: 780px;
  min-height: 50px;
  display: flex;
  .iconVideoo {
    position: absolute;
    top: -77px;
    z-index: 20;
    height: 35px;
    display: flex;
    .ConsulDetails {
      // border-bottom: 4px solid #fa9551;
      margin-right: 45px;
      h4 {
        font-size: 15px;
        color: #2cace3;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .mainTabelRecord {
    margin: 0 auto;
    min-height: 80px;

    border-radius: 15px;
    overflow: hidden;

    // width: calc(100% - 480px) !important;
    width: 780px;
    // margin: 20px 120px 0px 360px;
    background: #f2f2f2;

    .NoResultDiv {
      color: #666666;
      margin: 20px auto;
      font-size: 20px;
      text-align: center;
    }
    .mainConsultation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fa9551;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      height: 40px;

      .icon_left {
        span {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin: 3px 10px;
          align-self: center;
          text-align: center;
          .icon1 {
            align-self: center;
            color: white;
            font-size: 22px;
            margin-top: 3px;
          }
        }
        p {
          align-self: center;
          margin: 0 0 0 30px;
          color: white;
        }
      }
      .sort {
        margin: 0 10px;
        display: flex;
        p {
          align-self: center;
          margin: 0 50px 0 0;
          text-overflow: bold;
          color: white;
          &:hover {
            cursor: pointer;
          }
        }
        .all {
          margin: 0 20px 0 0;
        }
      }
    }

    .table2 {
      text-align: center;
      font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
      margin: 0;
      padding: 10px;
      color: #666666;

      tbody {
        background: #f2f2f2;
        transition: all 0.5s ease-in-out;
        // &:hover {
        //   background: rgb(208, 242, 253);
        //   cursor: pointer;
        // }
        tr {
          height: 40px;
          &:hover {
            background: #e6e6e6;
            cursor: pointer;
          }

          td {
            text-align: left;

            .finished {
              color: #00aff0;
              font-size: 17px;
            }
          }
          .client-status {
            text-align: center;
            width: 100px;

            &:hover {
              .check,
              .pendi,
              .finished,
              .declined {
                display: none;
              }
              .status {
                display: block;
              }
            }
          }
          .status {
            display: none;
          }
          .client-type {
            width: 120px !important;
          }
          .created {
            width: 120px;
          }
          .client-subject {
            width: 300px;
            padding: 0 0 0 30px;
          }
        }
      }
      .client-head {
        text-align: center;
        color: #666666;

        .client-row {
          // height: 40px;
          background: #b3b3b3;
          .client-subject {
            // text-align: center;
            display: flex;
            margin: 0 auto 0 30px;
            justify-content: flex-start;
            align-items: center;
            height: 40px;
          }

          .client-type {
            // text-align: center;
            height: 40px;
            padding: 0;
            width: 120px;
            .mainExamDiv {
              margin: 0 0 0 -5px;
              height: 40px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              width: 120px;
              // .searchDiv {
              //   display: flex;
              //   align-items: center;
              //   height: 40px;
              //   margin: 0 22px 0 0;
              // }
              .searchIcon {
                font-size: 20px;
                display: flex;
                align-items: center;
                padding: 0 10px 0 30px;
                height: 100%;
                &:hover {
                  cursor: pointer;
                }
              }
              select {
                width: 80px;
                height: 25px;
                border: none;
                border-radius: 5px;
                background: #b3b3b3;

                &::placeholder {
                  color: #666666;
                  font-size: 10px;
                }

                option {
                  background: white;
                }
              }
            }
          }
          .client-date {
            display: flex;
            margin: 0;
            justify-content: flex-start;
            align-items: center;
            height: 40px;
            width: 120px;
          }
          .client-status {
            text-align: center;
            margin: 0 auto;
            height: 40px;
          }
        }
      }
    }
  }
}

.pagi {
  display: flex;
  // width: calc(100% - 480px);
  width: 780px;
  margin: 20px auto 0px 360px;
  justify-content: center;

  ul {
    margin: 20px auto;
    li {
      a {
        color: #00aff0;
        border: none;
      }
    }
    .lastPage {
      display: none;
    }
    .active {
      a {
        background: #00aff0;
        color: white;
      }
    }
    .disabled {
      a {
        color: #00aff0;
      }
    }
  }
  // .left {
  //   width: 30px;
  //   height: 30px;
  //   margin: 10px;
  //   transition: all 0.5s ease-in-out;
  //   &:hover {
  //     cursor: pointer;
  //   }
  //   .iconLeft {
  //     font-size: 20px;
  //     margin: 4px 0 0 0;
  //     color: #4092c2;
  //   }
  // }

  // .right {
  //   width: 30px;
  //   height: 30px;
  //   margin: 10px;
  //   transition: all 0.5s ease-in-out;
  //   &:hover {
  //     cursor: pointer;
  //   }
  //   .iconRight {
  //     font-size: 20px;
  //     margin: 4px 0 0 0;
  //     color: #4092c2;
  //   }
  // }
}
