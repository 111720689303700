.mainDoctorAlerts {
  margin: 110px auto 20px 360px !important;
  width: calc(100% - 480px) !important;
  padding: 30px !important;
  background: #f2f2f2;
  height: 500px;
  position: relative;
  border-radius: 15px;

  .iconAlert {
    position: absolute;
    top: -76px;
    left: 0;
    z-index: 20;
    height: 35px;
    display: flex;
    border-bottom: 4px solid #fa9551;
    margin-right: 45px;
    h4 {
      font-weight: bold;
      font-size: 15px;
      color: #2cace3;
    }
  }
  .loading {
    animation: rotation 2s infinite linear;
    position: absolute;
    width: 70px !important;
    top: 40%;
    left: 45%;
    transform: translate(-40%, -45%);
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  .tableDiv {
    height: 100%;
    overflow: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 1px;
    }

    .NoResultDiv {
      color: #666666;
      margin: 20px auto;
      font-size: 20px;
    }

    .table2 {
      text-align: center;
      font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
      border-collapse: collapse;
      width: 100%;
      margin: 0;
      padding: 10px;
      color: #666666;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;

      tbody {
        transition: all 0.5s ease-in-out;
        &:hover {
          background: rgb(255, 255, 255);
          cursor: pointer;
        }
        tr {
          height: 40px;
          td {
            text-align: left;
            text-indent: 20px;
            &.client-type {
              text-indent: 0;
            }
          }
          .created {
            p {
              margin: 2px 0 0 0;
            }
          }

          .client-trash {
            color: red;
            text-align: center;
            transition: 0.5s ease-in-out;
            text-indent: 0;
            &:hover {
              background: red;
              color: white;
            }
          }
        }
      }
      .client-head {
        text-align: center;
        color: #666666;

        .client-row {
          height: 40px;
          width: 100%;
          color: white;
          background: #fa9551;

          .client-doctor {
            align-self: left;
            text-align: left;
            border-top-left-radius: 15px;
            text-indent: 20px;
          }
          .client-date {
            text-align: left;
            align-self: left;
            text-align: left;
            text-indent: 20px;
          }
          .client-type {
            width: 150px;
            align-self: left;
            text-align: left;
          }
          .client-subject {
            align-self: left;
            text-align: left;
          }
          .client-trash {
            width: 40px;
            border-top-right-radius: 15px;
          }
        }
      }
    }
  }
}
