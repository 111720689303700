@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,600;0,700;0,800;1,600;1,700;1,800&display=swap);
.row {
  margin: 70px 0 0 0;
}
.row .headers {
  position: relative;
}
.row .headers img {
  width: 100%;
}
.row .headers .headersInfo {
  position: absolute;
  top: 10%;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  text-align: start;
  color: #00aff0;
  width: 85%;
}
.row .headers .headersInfo img {
  height: 140px;
  margin: 0 auto 20px auto;
}
.row .headers .headersInfo h1 {
  font-size: 35pt;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
}
.row .headers .headersInfo h4 {
  font-weight: 600;
  font-size: 25pt;
  letter-spacing: -1px;
  text-align: center;
}
.row .headers .MainDivInfo {
  position: absolute;
  bottom: 140px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  width: 85%;
  display: flex;
  justify-content: space-between;
}
.row .headers .MainDivInfo .firstDivInfo,
.row .headers .MainDivInfo .secondDivInfo,
.row .headers .MainDivInfo .thirdDivInfo,
.row .headers .MainDivInfo .fourthDivInfo,
.row .headers .MainDivInfo .fifthDivInfo {
  background: #00aff0;
  border-radius: 10px;
  color: white;
  width: 200px;
  height: 330px;
  position: relative;
}
.row .headers .MainDivInfo .firstDivInfo .iconAbove1,
.row .headers .MainDivInfo .firstDivInfo .iconAbove2,
.row .headers .MainDivInfo .firstDivInfo .iconAbove3,
.row .headers .MainDivInfo .firstDivInfo .iconAbove4,
.row .headers .MainDivInfo .firstDivInfo .iconAbove5,
.row .headers .MainDivInfo .secondDivInfo .iconAbove1,
.row .headers .MainDivInfo .secondDivInfo .iconAbove2,
.row .headers .MainDivInfo .secondDivInfo .iconAbove3,
.row .headers .MainDivInfo .secondDivInfo .iconAbove4,
.row .headers .MainDivInfo .secondDivInfo .iconAbove5,
.row .headers .MainDivInfo .thirdDivInfo .iconAbove1,
.row .headers .MainDivInfo .thirdDivInfo .iconAbove2,
.row .headers .MainDivInfo .thirdDivInfo .iconAbove3,
.row .headers .MainDivInfo .thirdDivInfo .iconAbove4,
.row .headers .MainDivInfo .thirdDivInfo .iconAbove5,
.row .headers .MainDivInfo .fourthDivInfo .iconAbove1,
.row .headers .MainDivInfo .fourthDivInfo .iconAbove2,
.row .headers .MainDivInfo .fourthDivInfo .iconAbove3,
.row .headers .MainDivInfo .fourthDivInfo .iconAbove4,
.row .headers .MainDivInfo .fourthDivInfo .iconAbove5,
.row .headers .MainDivInfo .fifthDivInfo .iconAbove1,
.row .headers .MainDivInfo .fifthDivInfo .iconAbove2,
.row .headers .MainDivInfo .fifthDivInfo .iconAbove3,
.row .headers .MainDivInfo .fifthDivInfo .iconAbove4,
.row .headers .MainDivInfo .fifthDivInfo .iconAbove5 {
  background: #fa9551;
  position: absolute;
  top: -20px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  font-size: 28px;
  font-weight: bold;
  width: 40px;
  height: 40px;
  margin: 0;
  padding: 0;
  border-radius: 50%;
}
.row .headers .MainDivInfo .firstDivInfo h1,
.row .headers .MainDivInfo .secondDivInfo h1,
.row .headers .MainDivInfo .thirdDivInfo h1,
.row .headers .MainDivInfo .fourthDivInfo h1,
.row .headers .MainDivInfo .fifthDivInfo h1 {
  margin: 25px auto 0 auto;
  font-size: 17px;
  padding: 5px 0;
  text-align: center;
  font-weight: bold;
  border-bottom: 3px solid white;
  border-top: 3px solid white;
}
.row .headers .MainDivInfo .firstDivInfo .signUp,
.row .headers .MainDivInfo .secondDivInfo .signUp,
.row .headers .MainDivInfo .thirdDivInfo .signUp,
.row .headers .MainDivInfo .fourthDivInfo .signUp,
.row .headers .MainDivInfo .fifthDivInfo .signUp {
  width: 74px;
}
.row .headers .MainDivInfo .firstDivInfo .requestConsu,
.row .headers .MainDivInfo .secondDivInfo .requestConsu,
.row .headers .MainDivInfo .thirdDivInfo .requestConsu,
.row .headers .MainDivInfo .fourthDivInfo .requestConsu,
.row .headers .MainDivInfo .fifthDivInfo .requestConsu {
  width: 139px;
}
.row .headers .MainDivInfo .firstDivInfo .conswithD,
.row .headers .MainDivInfo .secondDivInfo .conswithD,
.row .headers .MainDivInfo .thirdDivInfo .conswithD,
.row .headers .MainDivInfo .fourthDivInfo .conswithD,
.row .headers .MainDivInfo .fifthDivInfo .conswithD {
  width: 140px;
}
.row .headers .MainDivInfo .firstDivInfo .securePay,
.row .headers .MainDivInfo .secondDivInfo .securePay,
.row .headers .MainDivInfo .thirdDivInfo .securePay,
.row .headers .MainDivInfo .fourthDivInfo .securePay,
.row .headers .MainDivInfo .fifthDivInfo .securePay {
  width: 90px;
}
.row .headers .MainDivInfo .firstDivInfo .clientDash,
.row .headers .MainDivInfo .secondDivInfo .clientDash,
.row .headers .MainDivInfo .thirdDivInfo .clientDash,
.row .headers .MainDivInfo .fourthDivInfo .clientDash,
.row .headers .MainDivInfo .fifthDivInfo .clientDash {
  width: 118px;
}
.row .headers .MainDivInfo .firstDivInfo p,
.row .headers .MainDivInfo .secondDivInfo p,
.row .headers .MainDivInfo .thirdDivInfo p,
.row .headers .MainDivInfo .fourthDivInfo p,
.row .headers .MainDivInfo .fifthDivInfo p {
  padding-top: 10px;
  font-size: 14px;
}
.row .headers .MainDivInfo .firstDivInfo img,
.row .headers .MainDivInfo .secondDivInfo img,
.row .headers .MainDivInfo .thirdDivInfo img,
.row .headers .MainDivInfo .fourthDivInfo img,
.row .headers .MainDivInfo .fifthDivInfo img {
  width: 100px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}
.row .headers .MainDivInfo .firstDivInfo .payment,
.row .headers .MainDivInfo .secondDivInfo .payment,
.row .headers .MainDivInfo .thirdDivInfo .payment,
.row .headers .MainDivInfo .fourthDivInfo .payment,
.row .headers .MainDivInfo .fifthDivInfo .payment {
  width: 120px !important;
  position: absolute;
  bottom: 10px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}
/* @import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap"); */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Open Sans", sans-serif;
  outline: 0;
}

.App {
  min-height: 100vh;
  min-width: 1280px;
  width: 100%;
  text-align: center;
  overflow-x: hidden;
}

.head {
  color: #4092c2;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #09d3ac;
}

.mainNotFoundDiv {
  height: 80vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #4092c2;
}

.bug {
  font-size: 80px;
  color: #4092c2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bug h1 {
  font-size: 70px;
  margin-left: 20px;
  color: #09d3ac;
}

.e-content-wrap {
  min-height: 400px !important;
}

.header {
  width: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  height: 70px;
  display: grid;
  grid-template-columns: 1fr;
  border-bottom: 2px solid #f2f2f2;
}
.header div {
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
}
.header div img {
  height: 45px;
  margin: 20px 0 0 0;
}
.header .duca1 {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100vh;
  background: black;
  opacity: 0.4;
  z-index: 30;
}
.header .duca {
  position: absolute;
  z-index: 31;
  background: #f2f2f2;
  bottom: -400px;
  height: 200px;
  width: 50%;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  display: flex;
  flex-direction: column;
  border-radius: 15px;
}
.header .duca h1 {
  color: #00aff0;
  font-size: 25px;
  font-weight: bold;
  margin: 0;
}
.header .duca h4 {
  color: #00aff0;
  font-weight: bold;
}
.header .duca .buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
.header .duca .buttons button {
  width: 100px;
  height: 35px;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  background: #00aff0;
}
.nav ul {
  display: flex;
  float: right;
}
.nav ul .topProfile {
  width: 100%;
  display: flex;
}
.nav ul .topProfile .mainProfile {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  background: white;
  position: relative;
}
.nav ul .topProfile .mainProfile .profile {
  margin: 0;
}
.nav ul .topProfile .mainProfile .profile img {
  margin: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: contain;
}
.nav ul .topProfile .mainProfile .profile img:hover {
  cursor: pointer;
}
.nav ul .topProfile p {
  margin: 13px 10px 13px 40px;
  color: #666666;
  font-weight: 500;
}
.nav ul .inic {
  background: lightblue;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #3064b3;
}
.nav ul .inic .initials {
  color: white;
  text-align: center;
  margin-top: 9px;
}
.nav ul li {
  padding: 0;
  margin-top: 5px;
  font-weight: 600;
  font-size: 12pt;
}
.nav ul li a {
  color: #666666;
  background: none;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  padding: 15px 0 15px 10px;
}
.nav ul li a:link {
  background: none;
}
.nav ul .userName {
  margin-left: 10px;
}
.nav ul .selectStatus {
  max-width: 100px;
  margin: 35px 0 0 0;
  padding: 0;
}
.nav ul .selectStatus #status {
  border-radius: 3px;
  border: none;
  background: #3cb54a;
  color: white;
  width: 100px;
  height: 30px;
  font-size: 10px;
  font-size: 13px;
}
.nav .li-reg {
  height: 40px;
  margin: 20px 0 0 0;
}
.nav .li-reg .register {
  color: #00aff0;
  font-weight: 600;
  font-size: 12pt;
  background-color: transparent;
}
.nav .li-log {
  height: 40px;
  margin: 20px 0 0 0;
}
.nav .li-log .login {
  color: white;
  font-size: 12pt;
  font-weight: 600;
  background-color: #00aff0;
  height: 26px;
  padding: 2px 10px 2px 10px;
  margin: 12px 0 0 10px;
  border-radius: 5px;
}
.page-footer {
  width: 100%;
  background: #cccccc;
  height: 169px;
  color: #666666;
}

.mainFooter {
  display: flex;
  justify-content: space-between;
  width: 85%;
  margin: 0 auto;
}
.mainFooter .contact {
  text-align: start;
  list-style: none;
}
.mainFooter .contact h3 {
  font-weight: bold;
  font-size: 15pt;
}
.mainFooter .contact li {
  font-size: 13pt;
  font-weight: 500;
  margin: 0 0 2px 0;
}
.mainFooter .contact .mail {
  margin: 0 4px 0 0;
}
.mainFooter .contact .phone {
  margin: 0 4px 0 0;
}
.mainFooter .contact .mapPin {
  margin: 0 4px 0 0;
}
.mainFooter .company {
  text-align: start;
  list-style: none;
  color: #666666;
}
.mainFooter .company h3 {
  font-weight: bold;
  font-size: 15pt;
  color: #666666;
}
.mainFooter .company li {
  font-size: 13pt;
  font-weight: 500;
  margin: 0 0 2px 0;
}
.mainFooter .company .aboutClass,
.mainFooter .company .blockClass,
.mainFooter .company .careersClass,
.mainFooter .company .faqClass {
  color: #666666;
}
.mainFooter .company .aboutClass:focus, .mainFooter .company .aboutClass:hover, .mainFooter .company .aboutClass:visited, .mainFooter .company .aboutClass:link, .mainFooter .company .aboutClass:active,
.mainFooter .company .blockClass:focus,
.mainFooter .company .blockClass:hover,
.mainFooter .company .blockClass:visited,
.mainFooter .company .blockClass:link,
.mainFooter .company .blockClass:active,
.mainFooter .company .careersClass:focus,
.mainFooter .company .careersClass:hover,
.mainFooter .company .careersClass:visited,
.mainFooter .company .careersClass:link,
.mainFooter .company .careersClass:active,
.mainFooter .company .faqClass:focus,
.mainFooter .company .faqClass:hover,
.mainFooter .company .faqClass:visited,
.mainFooter .company .faqClass:link,
.mainFooter .company .faqClass:active {
  text-decoration: none;
  list-style: none;
  outline: 0;
}
.mainFooter .social h3 {
  font-weight: bold;
  font-size: 15pt;
  text-align: left;
}
.mainFooter .social .socialMedia {
  display: flex;
  justify-content: space-between;
  width: 250px;
  margin-top: 20px;
}
.mainFooter .social .socialMedia div {
  border: 1.7px solid #666666;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.mainFooter .social .socialMedia div .insta,
.mainFooter .social .socialMedia div .facebook,
.mainFooter .social .socialMedia div .linkedin,
.mainFooter .social .socialMedia div .twitter {
  font-size: 30px;
  margin-top: 10px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  color: #666666;
}
.mainFooter .social p {
  margin: 20px 0 0 0;
  text-align: left;
}

@media (max-width: 1400px) {
  .mainFooter {
    width: 80%;
  }
}
@media (max-width: 600px) {
  .mainFooter {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
.mainClientDashboard {
  width: calc(100% - 480px);
  margin: 110px 0px 20px 360px;
}
.mainClientDashboard .loading {
  -webkit-animation: rotation 2s infinite linear;
          animation: rotation 2s infinite linear;
  width: 70px !important;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
.mainClientDashboard .main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin: 0px auto 20px auto;
  grid-gap: 10px;
  background: #f2f2f2;
  padding: 30px 15px;
  position: relative;
  border-radius: 15px;
}
.mainClientDashboard .main .dashboardIcon {
  position: absolute;
  top: -76px;
  z-index: 20;
  border-bottom: 4px solid #fa9551;
  height: 35px;
  display: flex;
}
.mainClientDashboard .main .dashboardIcon h4 {
  font-size: 15px;
  font-weight: bold !important;
  color: #2cace3;
}
.mainClientDashboard .main .videoApp,
.mainClientDashboard .main .waitRoom,
.mainClientDashboard .main .emailReq {
  display: flex;
  font-size: 40px;
  align-items: center;
  height: 90px;
  border-radius: 10px;
  border: 1.6px solid #b3b3b3;
  justify-content: flex-start;
  background: white;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.mainClientDashboard .main .videoApp .email,
.mainClientDashboard .main .videoApp .video,
.mainClientDashboard .main .videoApp .clock,
.mainClientDashboard .main .waitRoom .email,
.mainClientDashboard .main .waitRoom .video,
.mainClientDashboard .main .waitRoom .clock,
.mainClientDashboard .main .emailReq .email,
.mainClientDashboard .main .emailReq .video,
.mainClientDashboard .main .emailReq .clock {
  border-radius: 50%;
  align-self: center;
  margin: 0 10px;
}
.mainClientDashboard .main .videoApp .email .icon,
.mainClientDashboard .main .videoApp .video .icon,
.mainClientDashboard .main .videoApp .clock .icon,
.mainClientDashboard .main .waitRoom .email .icon,
.mainClientDashboard .main .waitRoom .video .icon,
.mainClientDashboard .main .waitRoom .clock .icon,
.mainClientDashboard .main .emailReq .email .icon,
.mainClientDashboard .main .emailReq .video .icon,
.mainClientDashboard .main .emailReq .clock .icon {
  align-self: center;
}
.mainClientDashboard .main .videoApp div h2,
.mainClientDashboard .main .waitRoom div h2,
.mainClientDashboard .main .emailReq div h2 {
  font-size: 15px;
  text-align: start;
  color: #666666;
  margin-top: 10px;
}
.mainClientDashboard .main .videoApp div .secondH2,
.mainClientDashboard .main .waitRoom div .secondH2,
.mainClientDashboard .main .emailReq div .secondH2 {
  font-size: 15px;
  font-weight: 800;
  margin-top: 10px;
}
.mainClientDashboard .main .videoApp:hover,
.mainClientDashboard .main .waitRoom:hover,
.mainClientDashboard .main .emailReq:hover {
  cursor: pointer;
  box-shadow: 2px 2px 10px #999999;
}
.mainClientDashboard .main .waitRoom span {
  position: relative;
}
.mainClientDashboard .main .waitRoom span p {
  position: absolute;
  bottom: -10px;
  right: 0;
  font-size: 20px;
  background: red;
  color: white;
  padding: 0 3px;
  border-radius: 5px;
}
.mainClientDashboard .pagi {
  display: flex;
  margin: 20px auto;
  justify-content: center;
  width: 100%;
}
.mainClientDashboard .pagi ul {
  margin: 20px auto;
}
.mainClientDashboard .pagi ul li {
  border: none;
}
.mainClientDashboard .pagi ul li a {
  color: #00aff0;
  border: none;
}
.mainClientDashboard .pagi ul .lastPage {
  display: none;
}
.mainClientDashboard .pagi ul .active a {
  background: #00aff0;
  color: white;
}
.mainClientDashboard .mainTabel {
  margin: 0 auto;
  height: 480px;
  border-radius: 15px;
  overflow: hidden;
}
.mainClientDashboard .mainTabel .NoResultDiv {
  color: #666666;
  margin: 20px auto;
  font-size: 20px;
}
.mainClientDashboard .mainTabel .mainConsultation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fa9551;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 40px;
}
.mainClientDashboard .mainTabel .mainConsultation .icon_left span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 3px 10px;
  align-self: center;
  text-align: center;
}
.mainClientDashboard .mainTabel .mainConsultation .icon_left span .icon1 {
  align-self: center;
  color: white;
  font-size: 22px;
  margin-top: 3px;
}
.mainClientDashboard .mainTabel .mainConsultation .icon_left p {
  align-self: center;
  margin: 0 0 0 30px;
  color: white;
}
.mainClientDashboard .mainTabel .mainConsultation .sort {
  margin: 0 10px;
  display: flex;
}
.mainClientDashboard .mainTabel .mainConsultation .sort p {
  align-self: center;
  margin: 0 50px 0 0;
  text-overflow: bold;
  color: white;
}
.mainClientDashboard .mainTabel .mainConsultation .sort p:hover {
  cursor: pointer;
}
.mainClientDashboard .mainTabel .mainConsultation .sort .all {
  margin: 0 20px 0 0;
}
.mainClientDashboard .mainTabel .table2 {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  padding: 10px;
  color: #666666;
}
.mainClientDashboard .mainTabel .table2 tbody {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background: #f2f2f2;
}
.mainClientDashboard .mainTabel .table2 tbody:last-of-type {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden !important;
}
.mainClientDashboard .mainTabel .table2 tbody:last-of-type tr {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden !important;
}
.mainClientDashboard .mainTabel .table2 tbody:last-of-type tr td:first-of-type {
  border-bottom-left-radius: 15px;
}
.mainClientDashboard .mainTabel .table2 tbody:last-of-type tr td:last-of-type {
  border-bottom-right-radius: 15px !important;
}
.mainClientDashboard .mainTabel .table2 tbody tr {
  height: 40px;
}
.mainClientDashboard .mainTabel .table2 tbody tr:hover {
  background: #e6e6e6;
  cursor: pointer;
}
.mainClientDashboard .mainTabel .table2 tbody tr td {
  text-align: left;
}
.mainClientDashboard .mainTabel .table2 tbody tr td .finished {
  color: #00aff0;
  font-size: 17px;
}
.mainClientDashboard .mainTabel .table2 tbody tr .client-status {
  text-align: center;
  width: 100px;
}
.mainClientDashboard .mainTabel .table2 tbody tr .client-status:hover .check,
.mainClientDashboard .mainTabel .table2 tbody tr .client-status:hover .pendi,
.mainClientDashboard .mainTabel .table2 tbody tr .client-status:hover .finished,
.mainClientDashboard .mainTabel .table2 tbody tr .client-status:hover .declined {
  display: none;
}
.mainClientDashboard .mainTabel .table2 tbody tr .client-status:hover .status {
  display: block;
}
.mainClientDashboard .mainTabel .table2 tbody tr .status {
  display: none;
}
.mainClientDashboard .mainTabel .table2 tbody tr .client-doctor {
  padding: 0 0 0 30px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.mainClientDashboard .mainTabel .table2 tbody tr .client-subject1 {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 350px;
}
.mainClientDashboard .mainTabel .table2 tbody tr .client-subject {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.mainClientDashboard .mainTabel .table2 tbody .created {
  margin: 0 auto;
  padding: 0 0 0 40px;
}
.mainClientDashboard .mainTabel .table2 tbody .created p {
  margin: 0 auto;
}
.mainClientDashboard .mainTabel .table2 .client-head {
  text-align: center;
  color: #666666;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row {
  background: #b3b3b3;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-doctor {
  width: 320px;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-doctor .mainExamDiv {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-doctor .mainExamDiv .searchDiv {
  display: flex;
  align-items: center;
  height: 40px;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-doctor .mainExamDiv .searchIcon {
  display: flex;
  align-items: center;
  margin: 0 0 0 30px;
  height: 100%;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-doctor .mainExamDiv .searchIcon:hover {
  cursor: pointer;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-doctor .mainExamDiv input {
  width: 120px;
  height: 25px;
  border: 1px solid #666666;
  border-radius: 5px;
  margin: 0 0 5px 30px;
  font-weight: 400;
  text-indent: 5px;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-doctor .mainExamDiv input::-webkit-input-placeholder {
  color: #666666;
  font-size: 13px;
  font-weight: 400;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-doctor .mainExamDiv input:-ms-input-placeholder {
  color: #666666;
  font-size: 13px;
  font-weight: 400;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-doctor .mainExamDiv input::-ms-input-placeholder {
  color: #666666;
  font-size: 13px;
  font-weight: 400;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-doctor .mainExamDiv input::placeholder {
  color: #666666;
  font-size: 13px;
  font-weight: 400;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-subject {
  display: flex;
  width: 150px;
  margin: 0;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-type {
  height: 40px;
  width: 200px;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-type .mainExamDiv {
  margin: 0 0 0 -5px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 150px;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-type .mainExamDiv .searchIcon {
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 30px;
  height: 100%;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-type .mainExamDiv .searchIcon:hover {
  cursor: pointer;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-type .mainExamDiv select {
  width: 80px;
  height: 25px;
  border: none;
  border-radius: 5px;
  background: #b3b3b3;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-type .mainExamDiv select::-webkit-input-placeholder {
  color: #666666;
  font-size: 10px;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-type .mainExamDiv select:-ms-input-placeholder {
  color: #666666;
  font-size: 10px;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-type .mainExamDiv select::-ms-input-placeholder {
  color: #666666;
  font-size: 10px;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-type .mainExamDiv select::placeholder {
  color: #666666;
  font-size: 10px;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-type .mainExamDiv select option {
  background: white;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-date {
  width: 160px;
  display: flex;
  padding: 0 0 0 40px;
  margin: 0;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
}
.mainClientDashboard .mainTabel .table2 .client-head .client-row .client-status {
  text-align: center;
  margin: 0 auto;
  height: 40px;
}
.mainClientDashboard .penTable {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  z-index: 5;
}
.mainClientDashboard .penTable button {
  width: 150px;
  height: 30px;
  background: #dddddd;
  border-radius: 5px;
  border: none;
  margin: 30px auto;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  outline: 0;
}
.mainClientDashboard .penTable button:hover {
  background: pink;
  box-shadow: 5px 5px 5px black;
}
.mainClientDashboard .penTable .table2 {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  padding: 10px;
}
.mainClientDashboard .penTable .table2 tbody {
  background: white;
  -webkit-transition: all 0.5 ease-in-out;
  transition: all 0.5 ease-in-out;
}
.mainClientDashboard .penTable .table2 tbody:hover {
  background: lightblue;
}
.mainClientDashboard .penTable .table2 tbody tr {
  height: 40px;
}
.mainClientDashboard .penTable .table2 tbody tr td .check {
  color: #12db12;
  font-size: 17px;
}
.mainClientDashboard .penTable .table2 tbody tr td .declined {
  color: red;
  font-size: 17px;
}
.mainClientDashboard .penTable .table2 tbody tr td .pendi {
  color: #4092c2;
  font-size: 17px;
}
.mainClientDashboard .penTable .table2 tbody tr .client-status {
  width: 100px;
}
.mainClientDashboard .penTable .table2 tbody tr .client-status:hover .check,
.mainClientDashboard .penTable .table2 tbody tr .client-status:hover .pendi,
.mainClientDashboard .penTable .table2 tbody tr .client-status:hover .finished,
.mainClientDashboard .penTable .table2 tbody tr .client-status:hover .declined {
  display: none;
}
.mainClientDashboard .penTable .table2 tbody tr .client-status:hover .status {
  display: block;
}
.mainClientDashboard .penTable .table2 tbody tr .status {
  display: none;
}
.mainClientDashboard .penTable .table2 .client-head {
  text-align: center;
  background-color: whitesmoke;
  color: gray;
}
.mainClientDashboard .penTable .table2 .client-head .client-row {
  height: 40px;
}
.mainClientDashboard .penTable .table2 .client-head .client-row .client-doctor {
  text-align: center;
}
.mainClientDashboard .penTable .table2 .client-head .client-row .client-subject {
  text-align: center;
}
.mainClientDashboard .penTable .table2 .client-head .client-row .client-status {
  text-align: center;
}
.testic {
  width: calc(100% - 240px);
  margin: 110px 0px 40px auto;
  padding: 0 120px;
}
.testic .loading {
  -webkit-animation: rotation 2s infinite linear;
          animation: rotation 2s infinite linear;
  width: 70px !important;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
.testic .main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin: 0 auto 20px auto;
  grid-gap: 10px;
  background: #f2f2f2;
  padding: 20px 15px;
  position: relative;
  border-radius: 15px;
}
.testic .main .dashboardIcon {
  position: absolute;
  top: -76px;
  z-index: 20;
  border-bottom: 4px solid #fa9551;
  height: 35px;
  display: flex;
}
.testic .main .dashboardIcon h4 {
  font-size: 15px;
  font-weight: bold !important;
  color: #2cace3;
}
.testic .main .divVideo {
  position: relative;
}
.testic .main .divVideo .pendingReq {
  font-size: 13px;
  margin-top: 50px;
  color: #666666;
}
.testic .main .divVideo .pendingReq div {
  margin: 4px auto;
  text-align: left;
  text-indent: 20px;
  color: #666666;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.testic .main .divVideo .pendingReq div:hover {
  cursor: pointer;
  background: #bdebfa;
}
.testic .main .divVideo .pendingReq p {
  width: 100px;
  text-indent: 20px;
}
.testic .main .divVideo .requestsVideo {
  background: #00aff0;
  width: 250px;
  height: 30px;
  float: right;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%, 5% 46%);
          clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%, 5% 46%);
  border-bottom-left-radius: 5px;
  color: white;
}
.testic .main .divVideo .requestsVideo p {
  display: flex;
  padding: 4px 0;
  font-size: 15px;
  margin-left: 30px;
  justify-content: flex-start;
  align-items: center;
}
.testic .main .divEmail {
  position: relative;
}
.testic .main .divEmail .pendingReq {
  font-size: 13px;
  margin-top: 50px;
  color: #666666;
}
.testic .main .divEmail .pendingReq div {
  margin: 4px auto;
  text-align: left;
  text-indent: 20px;
  color: #666666;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.testic .main .divEmail .pendingReq div:hover {
  cursor: pointer;
  background: #bdebfa;
}
.testic .main .divEmail .pendingReq p {
  width: 100px;
  text-indent: 20px;
}
.testic .main .divEmail .requestsEmail {
  background: #00aff0;
  width: 250px;
  height: 30px;
  float: right;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%, 5% 46%);
          clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%, 5% 46%);
  border-bottom-left-radius: 5px;
  color: white;
}
.testic .main .divEmail .requestsEmail p {
  display: flex;
  padding: 4px 0;
  font-size: 15px;
  margin-left: 30px;
  justify-content: flex-start;
  align-items: center;
}
.testic .main .divClock {
  position: relative;
}
.testic .main .divClock .requestsClock {
  background: #00aff0;
  width: 250px;
  height: 30px;
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%, 5% 46%);
          clip-path: polygon(0 0, 100% 0%, 100% 100%, 10% 100%, 5% 46%);
  float: right;
  border-bottom-left-radius: 5px;
  color: white;
}
.testic .main .divClock .requestsClock p {
  display: flex;
  padding: 4px 0;
  font-size: 15px;
  margin-left: 30px;
  justify-content: flex-start;
  align-items: center;
}
.testic .main .divClock .pendingReq {
  font-size: 13px;
  margin-top: 50px;
  color: #666666;
}
.testic .main .divClock .pendingReq div {
  text-align: left;
  text-indent: 20px;
  color: #666666;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.testic .main .divClock .pendingReq div:hover {
  cursor: pointer;
  background: #bdebfa;
}
.testic .main .divClock .pendingReq p {
  width: 100px;
  text-indent: 20px;
}
.testic .main .divVideo,
.testic .main .divEmail,
.testic .main .divClock {
  height: 250px;
  border-radius: 15px;
  border: 2px solid #b3b3b3;
  background: white;
}
.testic .main .videoApp1,
.testic .main .waitRoom1,
.testic .main .emailReq1 {
  display: flex;
  font-size: 40px;
  height: 90px;
  justify-content: flex-start;
  align-self: center;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.testic .main .videoApp1:hover,
.testic .main .waitRoom1:hover,
.testic .main .emailReq1:hover {
  cursor: pointer;
  box-shadow: 3px 3px 5px gray;
}
.testic .main .videoApp1 .icon,
.testic .main .waitRoom1 .icon,
.testic .main .emailReq1 .icon {
  margin: 0 0 0 20px;
}
.testic .main .videoApp1 .email,
.testic .main .videoApp1 .video,
.testic .main .videoApp1 .clock,
.testic .main .waitRoom1 .email,
.testic .main .waitRoom1 .video,
.testic .main .waitRoom1 .clock,
.testic .main .emailReq1 .email,
.testic .main .emailReq1 .video,
.testic .main .emailReq1 .clock {
  align-self: center;
  margin: 0 10px 0 0;
}
.testic .main .videoApp1 .email .icon,
.testic .main .videoApp1 .video .icon,
.testic .main .videoApp1 .clock .icon,
.testic .main .waitRoom1 .email .icon,
.testic .main .waitRoom1 .video .icon,
.testic .main .waitRoom1 .clock .icon,
.testic .main .emailReq1 .email .icon,
.testic .main .emailReq1 .video .icon,
.testic .main .emailReq1 .clock .icon {
  align-self: center;
}
.testic .main .videoApp1 h2,
.testic .main .waitRoom1 h2,
.testic .main .emailReq1 h2 {
  font-size: 20px;
  align-self: center;
  font-weight: 400;
  color: #666666;
}
.testic .mainTabel {
  border-radius: 15px;
  height: 480px;
  overflow: hidden;
}
.testic .mainTabel .NoResultDiv {
  color: #666666;
  margin: 20px auto 0 auto;
  font-size: 20px;
}
.testic .mainTabel .mainConsultation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fa9551;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 40px;
}
.testic .mainTabel .mainConsultation .icon_left span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 3px 10px;
  align-self: center;
  text-align: center;
}
.testic .mainTabel .mainConsultation .icon_left span .icon1 {
  align-self: center;
  color: white;
  font-size: 22px;
  margin-top: 3px;
}
.testic .mainTabel .mainConsultation .icon_left p {
  align-self: center;
  margin: 0 0 0 30px;
  color: white;
}
.testic .mainTabel .mainConsultation .sort {
  margin: 0 10px;
  display: flex;
}
.testic .mainTabel .mainConsultation .sort p {
  align-self: center;
  margin: 0 50px 0 0;
  text-overflow: bold;
  color: white;
}
.testic .mainTabel .mainConsultation .sort p:hover {
  cursor: pointer;
}
.testic .mainTabel .mainConsultation .sort .all {
  margin: 0 30px 0 0;
}
.testic .mainTabel .table2 {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  padding: 10px;
  color: #666666;
}
.testic .mainTabel .table2 tbody {
  background: #f2f2f2;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.testic .mainTabel .table2 tbody:last-of-type {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden !important;
}
.testic .mainTabel .table2 tbody:last-of-type tr {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden !important;
}
.testic .mainTabel .table2 tbody:last-of-type tr td:first-of-type {
  border-bottom-left-radius: 15px;
}
.testic .mainTabel .table2 tbody:last-of-type tr td:last-of-type {
  border-bottom-right-radius: 15px !important;
}
.testic .mainTabel .table2 tbody tr {
  height: 40px;
}
.testic .mainTabel .table2 tbody tr td {
  text-align: left;
}
.testic .mainTabel .table2 tbody tr td .finished {
  color: #00aff0;
  font-size: 17px;
}
.testic .mainTabel .table2 tbody tr .client-status {
  text-align: center;
  width: 100px;
}
.testic .mainTabel .table2 tbody tr .client-status:hover .check,
.testic .mainTabel .table2 tbody tr .client-status:hover .pendi,
.testic .mainTabel .table2 tbody tr .client-status:hover .finished,
.testic .mainTabel .table2 tbody tr .client-status:hover .declined {
  display: none;
}
.testic .mainTabel .table2 tbody tr .client-status:hover .status {
  display: block;
}
.testic .mainTabel .table2 tbody tr .status {
  display: none;
}
.testic .mainTabel .table2 tbody tr .client-doctor {
  padding: 0 0 0 35px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.testic .mainTabel .table2 tbody tr .client-subject1 {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 350px;
}
.testic .mainTabel .table2 tbody tr .client-subject {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.testic .mainTabel .table2 tbody tr .created {
  margin: 0 auto;
  padding: 0 0 0 40px;
}
.testic .mainTabel .table2 tbody tr .created p {
  margin: 0 auto;
}
.testic .mainTabel .table2 .client-head {
  text-align: center;
  color: #666666;
}
.testic .mainTabel .table2 .client-head .client-row {
  background: #b3b3b3;
}
.testic .mainTabel .table2 .client-head .client-row .client-doctor {
  width: 320px;
}
.testic .mainTabel .table2 .client-head .client-row .client-doctor .mainExamDiv {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
}
.testic .mainTabel .table2 .client-head .client-row .client-doctor .mainExamDiv .searchDiv {
  display: flex;
  align-items: center;
  height: 40px;
}
.testic .mainTabel .table2 .client-head .client-row .client-doctor .mainExamDiv .searchIcon {
  display: flex;
  align-items: center;
  margin: 0 0 0 25px;
  height: 100%;
}
.testic .mainTabel .table2 .client-head .client-row .client-doctor .mainExamDiv .searchIcon:hover {
  cursor: pointer;
}
.testic .mainTabel .table2 .client-head .client-row .client-doctor .mainExamDiv input {
  width: 120px;
  height: 25px;
  border: 1px solid #666666;
  border-radius: 5px;
  margin: 0 0 5px 40px;
  font-weight: 400;
  text-indent: 5px;
}
.testic .mainTabel .table2 .client-head .client-row .client-doctor .mainExamDiv input::-webkit-input-placeholder {
  color: #666666;
  font-size: 13px;
  font-weight: 400;
}
.testic .mainTabel .table2 .client-head .client-row .client-doctor .mainExamDiv input:-ms-input-placeholder {
  color: #666666;
  font-size: 13px;
  font-weight: 400;
}
.testic .mainTabel .table2 .client-head .client-row .client-doctor .mainExamDiv input::-ms-input-placeholder {
  color: #666666;
  font-size: 13px;
  font-weight: 400;
}
.testic .mainTabel .table2 .client-head .client-row .client-doctor .mainExamDiv input::placeholder {
  color: #666666;
  font-size: 13px;
  font-weight: 400;
}
.testic .mainTabel .table2 .client-head .client-row .client-subject {
  display: flex;
  width: 150px;
  margin: 0;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
}
.testic .mainTabel .table2 .client-head .client-row .client-type {
  height: 40px;
  width: 200px;
}
.testic .mainTabel .table2 .client-head .client-row .client-type .mainExamDiv {
  margin: 0 0 0 -5px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 150px;
}
.testic .mainTabel .table2 .client-head .client-row .client-type .mainExamDiv .searchIcon {
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 30px;
  height: 100%;
}
.testic .mainTabel .table2 .client-head .client-row .client-type .mainExamDiv .searchIcon:hover {
  cursor: pointer;
}
.testic .mainTabel .table2 .client-head .client-row .client-type .mainExamDiv select {
  width: 80px;
  height: 25px;
  border: none;
  border-radius: 5px;
  background: #b3b3b3;
}
.testic .mainTabel .table2 .client-head .client-row .client-type .mainExamDiv select::-webkit-input-placeholder {
  color: #666666;
  font-size: 10px;
}
.testic .mainTabel .table2 .client-head .client-row .client-type .mainExamDiv select:-ms-input-placeholder {
  color: #666666;
  font-size: 10px;
}
.testic .mainTabel .table2 .client-head .client-row .client-type .mainExamDiv select::-ms-input-placeholder {
  color: #666666;
  font-size: 10px;
}
.testic .mainTabel .table2 .client-head .client-row .client-type .mainExamDiv select::placeholder {
  color: #666666;
  font-size: 10px;
}
.testic .mainTabel .table2 .client-head .client-row .client-type .mainExamDiv select option {
  background: white;
  height: 40px;
}
.testic .mainTabel .table2 .client-head .client-row .client-date {
  width: 160px;
  padding: 0 0 0 40px;
  display: flex;
  margin: 0;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
}
.testic .mainTabel .table2 .client-head .client-row .client-status {
  text-align: center;
  margin: 0 auto;
  height: 40px;
}
.testic .list-group:hover {
  cursor: pointer;
  background: #e6e6e6;
}
.testic .MainPending {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 10;
}
.testic .MainPending .openPending {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 20px;
  width: 100%;
}
.testic .MainPending .openPending .uniquePen {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  margin: 0 auto;
  color: white;
  border: 1px solid white;
  width: 80%;
}
.testic .MainPending .openPending .uniquePen p {
  align-self: center;
}
.testic .pagi {
  display: flex;
  margin: 20px auto;
  justify-content: center;
  width: 100%;
}
.testic .pagi ul {
  margin: 20px auto;
}
.testic .pagi ul li {
  border: none;
}
.testic .pagi ul li a {
  color: #00aff0;
  border: none;
}
.testic .pagi ul .lastPage {
  display: none;
}
.testic .pagi ul .active a {
  background: #00aff0;
  color: white;
}
.detail_exam {
  background: #f2f2f2;
  position: relative;
  padding: 30px 0px !important;
  border-radius: 15px;
  text-align: left;
  margin: 110px auto 20px 360px;
  width: calc(100% - 480px);
  min-height: 350px;
  display: flex;
}
.detail_exam .iconVideo {
  position: absolute;
  top: -77px;
  z-index: 20;
  height: 35px;
  display: flex;
}
.detail_exam .iconVideo .ConsulDetails {
  margin-right: 45px;
}
.detail_exam .iconVideo .ConsulDetails h4 {
  font-size: 15px;
  color: #2cace3;
}
.detail_exam .iconVideo .ConsulDetails:hover {
  cursor: pointer;
}
.detail_exam .detail {
  padding: 5px 10px 0px 30px;
  position: relative;
  width: 350px;
}
.detail_exam .detail .divSelectButton {
  display: flex;
}
.detail_exam .detail .divSelectButton .select-option {
  width: 200px;
}
.detail_exam .detail .divSelectButton .btnSend {
  margin: 0 10px;
  border: 1px solid #377da5;
  background: #4092c2;
  color: white;
  padding: 0 5px;
  border-radius: 5px;
}
.detail_exam .detail p {
  color: #666666;
  border: none;
}
.detail_exam .detail p span {
  font-weight: 900;
}
.detail_exam .detail .col-sm-10 {
  position: relative;
  height: 50px;
}
.detail_exam .detail .col-sm-10 .btn {
  position: relative;
  border: 1px solid #4092c2;
  height: 37px;
  left: 120px;
  top: -37px;
}
.detail_exam .detail .message-btn {
  position: absolute;
  bottom: 0;
}
.detail_exam .detail .message-btn button {
  background: #00aff0;
  margin: 0;
  font-size: 14px;
  width: 120px;
  padding: 0;
  height: 30px;
  border-radius: 6px;
}
.detail_exam .detail .message-btn button:disabled,
.detail_exam .detail .message-btn button button[disabled] {
  background: lightgray;
  color: white;
  cursor: not-allowed;
  box-shadow: none;
  pointer-events: none;
  border: none;
}
.detail_exam .mainMessageDiv {
  width: 100%;
  padding: 0 30px 0 0;
}
.detail_exam .mainMessageDiv .subjectDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fa9551;
  color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 30px;
}
.detail_exam .mainMessageDiv .subjectDiv p {
  margin: 0px 15px 0 15px;
}
.detail_exam .mainMessageDiv .subjectDiv p span {
  margin-right: 5px;
}
.detail_exam .mainMessageDiv .subjectDiv .infoAndSquare {
  align-items: center;
}
.detail_exam .mainMessageDiv .subjectDiv .infoAndSquare p {
  margin: 0px 15px 0 15px;
}
.detail_exam .mainMessageDiv .subjectDiv .infoAndSquare p span {
  margin-right: 5px;
  align-items: center;
}
.detail_exam .mainMessageDiv .subjectDiv .infoAndSquare #imageDiv1 {
  background: transparent;
  border: 1.7px solid white;
  width: 20px;
  border-radius: 5px;
  height: 20px;
  margin: 0px 20px 0 0;
  display: none;
}
.detail_exam .mainMessageDiv .subjectDiv .infoAndSquare #imageDiv1:hover {
  cursor: pointer;
}
.detail_exam .mainMessageDiv .messageDiv {
  min-height: 160px;
  width: 100%;
  background: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 10px 15px !important;
}
.detail_exam .mainMessageDiv .messageDiv textarea {
  resize: none;
  width: 100%;
  min-height: 100px !important;
  border: none;
  border-bottom-left-radius: 15px;
  background: white;
  border-bottom-right-radius: 15px;
}
.detail_exam .mainMessageDiv .messageDiv textarea::-webkit-scrollbar {
  width: 5px;
}
.detail_exam .mainMessageDiv .messageDiv textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.detail_exam .mainMessageDiv .messageDiv textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.detail_exam .mainMessageDiv .messageDiv textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.detail_exam .mainMessageDiv .messageDiv textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.detail_exam .mainMessageDiv .messageDiv .mainFileDiv {
  border-top: 1px solid #f2f2f2;
  height: 30px;
  display: flex;
}
.detail_exam .mainMessageDiv .messageDiv .mainFileDiv .FileDiv {
  height: 40px;
}
.detail_exam .mainMessageDiv .messageDiv .mainFileDiv .FileDiv p {
  color: #00aff0;
  font-weight: bold;
  width: 50px;
  height: 25px;
  border-radius: 5px;
  text-align: start;
  align-items: center;
  margin: 5px 0px 0 0;
}
.detail_exam .mainMessageDiv .messageDiv .mainFileDiv .fileForDownload {
  display: flex;
  color: #00aff0;
  border-radius: 5px;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  height: 25px;
  margin: 2px 0 0 0;
  padding: 0 5px;
  overflow: hidden;
  white-space: nowrap;
}
.detail_exam .mainMessageDiv .messageDiv .mainFileDiv .fileForDownload p {
  margin: 0;
  width: 100px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail_exam .mainMessageDiv .messageDiv .mainFileDiv .fileForDownload p:hover {
  text-overflow: scroll;
  width: 350px;
}
.detail_exam .mainMessageDiv .messageDiv .mainFileDiv .fileForDownload:hover {
  cursor: pointer;
}
.detail_exam .mainMessageDiv .messageDiv .mainFileDiv {
  border-top: 1px solid #f2f2f2;
  height: 30px;
  display: flex;
}
.detail_exam .mainMessageDiv .messageDiv .mainFileDiv .FileDiv {
  height: 40px;
}
.detail_exam .mainMessageDiv .messageDiv .mainFileDiv .FileDiv p {
  color: #00aff0;
  font-weight: bold;
  width: 50px;
  height: 25px;
  border-radius: 5px;
  text-align: start;
  align-items: center;
  margin: 5px 0px 0 0;
}
.detail_exam .mainMessageDiv .messageDiv .mainFileDiv .fileForDownload {
  display: flex;
  color: #00aff0;
  border-radius: 5px;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  height: 25px;
  margin: 2px 0 0 0;
  padding: 0 5px;
  overflow: hidden;
  white-space: nowrap;
}
.detail_exam .mainMessageDiv .messageDiv .mainFileDiv .fileForDownload p {
  margin: 0;
  width: 100px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail_exam .mainMessageDiv .messageDiv .mainFileDiv .fileForDownload p:hover {
  text-overflow: scroll;
  width: 350px;
}
.detail_exam .mainMessageDiv .messageDiv .mainFileDiv .fileForDownload:hover {
  cursor: pointer;
}
.detail_exam .mainMessageDiv .reportIfDeclined,
.detail_exam .mainMessageDiv .reportIfFinished {
  margin: 20px 0 0 0;
}
.detail_exam .mainMessageDiv .reportIfDeclined .subjectDiv,
.detail_exam .mainMessageDiv .reportIfFinished .subjectDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e6e6e6;
  color: #666666;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 30px;
}
.detail_exam .mainMessageDiv .reportIfDeclined .subjectDiv p,
.detail_exam .mainMessageDiv .reportIfFinished .subjectDiv p {
  margin: 0px 15px 0 15px;
}
.detail_exam .mainMessageDiv .reportIfDeclined .subjectDiv p span,
.detail_exam .mainMessageDiv .reportIfFinished .subjectDiv p span {
  margin-right: 5px;
}
.detail_exam .mainMessageDiv .reportIfDeclined .subjectDiv #imageDiv2,
.detail_exam .mainMessageDiv .reportIfDeclined .subjectDiv #imageDiv3,
.detail_exam .mainMessageDiv .reportIfFinished .subjectDiv #imageDiv2,
.detail_exam .mainMessageDiv .reportIfFinished .subjectDiv #imageDiv3 {
  background: transparent;
  border: 1.7px solid white;
  width: 20px;
  border-radius: 5px;
  height: 20px;
  margin: 0px 20px 0 0;
}
.detail_exam .mainMessageDiv .reportIfDeclined .subjectDiv #imageDiv2:hover,
.detail_exam .mainMessageDiv .reportIfDeclined .subjectDiv #imageDiv3:hover,
.detail_exam .mainMessageDiv .reportIfFinished .subjectDiv #imageDiv2:hover,
.detail_exam .mainMessageDiv .reportIfFinished .subjectDiv #imageDiv3:hover {
  cursor: pointer;
}
.detail_exam .mainMessageDiv .reportIfDeclined .messageDivReport,
.detail_exam .mainMessageDiv .reportIfFinished .messageDivReport {
  width: 100%;
  background: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.detail_exam .mainMessageDiv .reportIfDeclined .messageDivReport textarea,
.detail_exam .mainMessageDiv .reportIfFinished .messageDivReport textarea {
  width: 100%;
  min-height: 130px;
  padding: 10px;
  background: white;
  border: none;
  resize: none;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.detail_exam .mainMessageDiv .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar,
.detail_exam .mainMessageDiv .reportIfFinished .messageDivReport textarea::-webkit-scrollbar {
  width: 5px;
}
.detail_exam .mainMessageDiv .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar-button:single-button,
.detail_exam .mainMessageDiv .reportIfFinished .messageDivReport textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.detail_exam .mainMessageDiv .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar-track,
.detail_exam .mainMessageDiv .reportIfFinished .messageDivReport textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.detail_exam .mainMessageDiv .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar-thumb,
.detail_exam .mainMessageDiv .reportIfFinished .messageDivReport textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.detail_exam .mainMessageDiv .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar-thumb:hover,
.detail_exam .mainMessageDiv .reportIfFinished .messageDivReport textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.detail_exam .mainMessageDiv .reportIfDeclined .sendbuttonAndAtt,
.detail_exam .mainMessageDiv .reportIfFinished .sendbuttonAndAtt {
  display: flex;
  flex-direction: column;
  background: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin: 0;
  padding: 0 15px;
}
.detail_exam .mainMessageDiv .reportIfDeclined .sendbuttonAndAtt button,
.detail_exam .mainMessageDiv .reportIfFinished .sendbuttonAndAtt button {
  color: white;
  background: #00aff0;
  border: none;
  height: 30px;
  width: 80px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px 10px 0px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.detail_exam .mainMessageDiv .reportIfDeclined .sendbuttonAndAtt button .replyIcon,
.detail_exam .mainMessageDiv .reportIfFinished .sendbuttonAndAtt button .replyIcon {
  font-size: 20px;
}
.detail_exam .mainMessageDiv .reportIfDeclined .sendbuttonAndAtt .upload-btn-wrapper,
.detail_exam .mainMessageDiv .reportIfFinished .sendbuttonAndAtt .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.detail_exam .mainMessageDiv .reportIfDeclined .sendbuttonAndAtt .btn,
.detail_exam .mainMessageDiv .reportIfFinished .sendbuttonAndAtt .btn {
  outline: none;
  background: white;
  border: 1.7px solid #00aff0;
  height: 30px;
  color: #00aff0;
  font-weight: bold;
  padding: 0 5px;
  width: 70px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 0;
  align-items: center;
  box-shadow: none;
}
.detail_exam .mainMessageDiv .reportIfDeclined .sendbuttonAndAtt .btn p,
.detail_exam .mainMessageDiv .reportIfFinished .sendbuttonAndAtt .btn p {
  margin: 0;
}
.detail_exam .mainMessageDiv .reportIfDeclined .sendbuttonAndAtt .upload-btn-wrapper input[type=file],
.detail_exam .mainMessageDiv .reportIfFinished .sendbuttonAndAtt .upload-btn-wrapper input[type=file] {
  font-size: 0px;
  position: absolute;
  left: 0;
  top: 0;
  width: 70px;
  height: 30px;
  cursor: pointer;
  opacity: 0;
}
.detail_exam .mainMessageDiv .reportIfDeclined .sendbuttonAndAtt .fileForDownload,
.detail_exam .mainMessageDiv .reportIfFinished .sendbuttonAndAtt .fileForDownload {
  display: flex;
  color: #00aff0;
  border-radius: 5px;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  margin: 0 0 0 20px;
  padding: 0 5px;
  overflow: hidden;
  white-space: nowrap;
}
.detail_exam .mainMessageDiv .reportIfDeclined .sendbuttonAndAtt .fileForDownload p,
.detail_exam .mainMessageDiv .reportIfFinished .sendbuttonAndAtt .fileForDownload p {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
  margin: 0 2px 0 0;
  color: #00aff0;
  text-indent: 0;
}
.detail_exam .mainMessageDiv .reportIfDeclined .sendbuttonAndAtt .fileForDownload p:hover,
.detail_exam .mainMessageDiv .reportIfFinished .sendbuttonAndAtt .fileForDownload p:hover {
  text-overflow: scroll;
  max-width: 350px;
}
.detail_exam .mainMessageDiv .reportIfDeclined .sendbuttonAndAtt .fileForDownload .deleteB,
.detail_exam .mainMessageDiv .reportIfFinished .sendbuttonAndAtt .fileForDownload .deleteB {
  color: gray;
  width: 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  border: none;
}
.detail_exam .mainMessageDiv .reportIfDeclined .sendbuttonAndAtt .fileForDownload .deleteB:hover,
.detail_exam .mainMessageDiv .reportIfFinished .sendbuttonAndAtt .fileForDownload .deleteB:hover {
  cursor: pointer;
}
.detail_exam .mainMessageDiv .reportIfDeclined .sendbuttonAndAtt .attachess,
.detail_exam .mainMessageDiv .reportIfFinished .sendbuttonAndAtt .attachess {
  display: flex;
  border-top: 1.7px solid #f2f2f2;
  padding: 5px 0 0 0;
  height: 40px;
}
.detail_exam .mainMessageDiv .reportIfDeclined .sendbuttonAndAtt .attachess .showFiles,
.detail_exam .mainMessageDiv .reportIfFinished .sendbuttonAndAtt .attachess .showFiles {
  color: #00aff0;
  font-weight: bold;
  height: 25px;
  border-radius: 5px;
  text-align: start;
  align-items: center;
  margin: 5px 0px 0 0;
}
.detail_exam .mainMessageDiv .reportIfDeclined button,
.detail_exam .mainMessageDiv .reportIfFinished button {
  color: white;
  background: #00aff0;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: bold;
  margin: 20px auto 0px 0;
}
.detail_exam .mainMessageDiv .btnForReport {
  color: white;
  background: #00aff0;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 600;
  margin: 20px auto 20px 0;
}
.detail_exam .sideMessageDetails {
  width: 100%;
  display: flex;
  padding: 0 30px 0 0;
  flex-direction: column;
}
.detail_exam .sideMessageDetails .SubjectMessage .sideSub {
  background: #fa9551;
  color: white;
  height: 30px;
  display: flex;
  margin: 0;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.detail_exam .sideMessageDetails .SubjectMessage .sideSub p {
  margin: 0px 15px 0 15px;
}
.detail_exam .sideMessageDetails .SubjectMessage .sideSub p span {
  margin-right: 5px;
}
.detail_exam .sideMessageDetails .SubjectMessage .sideSub .infoAndSquare {
  align-items: center;
}
.detail_exam .sideMessageDetails .SubjectMessage .sideSub .infoAndSquare p {
  margin: 0px 15px 0 15px;
}
.detail_exam .sideMessageDetails .SubjectMessage .sideSub .infoAndSquare p span {
  margin-right: 5px;
  align-items: center;
}
.detail_exam .sideMessageDetails .SubjectMessage .sideSub .infoAndSquare #imageDiv1 {
  background: transparent;
  border: 1.7px solid white;
  width: 20px;
  border-radius: 5px;
  height: 20px;
  margin: 0px 20px 0 0;
  display: none;
}
.detail_exam .sideMessageDetails .SubjectMessage .sideSub .infoAndSquare #imageDiv1:hover {
  cursor: pointer;
}
.detail_exam .sideMessageDetails .SubjectMessage .messageDiv {
  background: white;
  color: #666666;
  min-height: 160px;
  padding: 10px 15px !important;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.detail_exam .sideMessageDetails .SubjectMessage .messageDiv textarea {
  width: 100%;
  min-height: 100px !important;
  background: white;
  border: none;
  resize: none;
}
.detail_exam .sideMessageDetails .SubjectMessage .messageDiv textarea::-webkit-scrollbar {
  width: 5px;
}
.detail_exam .sideMessageDetails .SubjectMessage .messageDiv textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.detail_exam .sideMessageDetails .SubjectMessage .messageDiv textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.detail_exam .sideMessageDetails .SubjectMessage .messageDiv textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.detail_exam .sideMessageDetails .SubjectMessage .messageDiv textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.detail_exam .sideMessageDetails .SubjectMessage .messageDiv .mainFileDiv {
  border-top: 1px solid #f2f2f2;
  height: 30px;
  display: flex;
}
.detail_exam .sideMessageDetails .SubjectMessage .messageDiv .mainFileDiv .FileDiv {
  height: 40px;
}
.detail_exam .sideMessageDetails .SubjectMessage .messageDiv .mainFileDiv .FileDiv p {
  color: #00aff0;
  font-weight: bold;
  width: 50px;
  height: 25px;
  border-radius: 5px;
  text-align: start;
  align-items: center;
  margin: 5px 0px 0 0;
}
.detail_exam .sideMessageDetails .SubjectMessage .messageDiv .mainFileDiv .fileForDownload {
  display: flex;
  color: #00aff0;
  border-radius: 5px;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  height: 25px;
  margin: 2px 0 0 0;
  padding: 0 5px;
  overflow: hidden;
  white-space: nowrap;
}
.detail_exam .sideMessageDetails .SubjectMessage .messageDiv .mainFileDiv .fileForDownload p {
  margin: 0;
  width: 100px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail_exam .sideMessageDetails .SubjectMessage .messageDiv .mainFileDiv .fileForDownload p:hover {
  text-overflow: scroll;
  width: 350px;
}
.detail_exam .sideMessageDetails .SubjectMessage .messageDiv .mainFileDiv .fileForDownload:hover {
  cursor: pointer;
}
.detail_exam .sideMessageDetails .SubjectMessage .reportIfDeclined,
.detail_exam .sideMessageDetails .SubjectMessage .reportIfFinished {
  margin: 20px 0 0 0;
}
.detail_exam .sideMessageDetails .SubjectMessage .reportIfDeclined .subjectDiv,
.detail_exam .sideMessageDetails .SubjectMessage .reportIfFinished .subjectDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e6e6e6;
  color: #666666;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 30px;
}
.detail_exam .sideMessageDetails .SubjectMessage .reportIfDeclined .subjectDiv p,
.detail_exam .sideMessageDetails .SubjectMessage .reportIfFinished .subjectDiv p {
  margin: 0px 15px 0 15px;
}
.detail_exam .sideMessageDetails .SubjectMessage .reportIfDeclined .subjectDiv p span,
.detail_exam .sideMessageDetails .SubjectMessage .reportIfFinished .subjectDiv p span {
  margin-right: 5px;
}
.detail_exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport,
.detail_exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport {
  width: 100%;
  background: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.detail_exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport textarea,
.detail_exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport textarea {
  width: 100%;
  min-height: 130px;
  padding: 10px;
  background: white;
  border: none;
  resize: none;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.detail_exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar,
.detail_exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport textarea::-webkit-scrollbar {
  width: 5px;
}
.detail_exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar-button:single-button,
.detail_exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.detail_exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar-track,
.detail_exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.detail_exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar-thumb,
.detail_exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.detail_exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar-thumb:hover,
.detail_exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.detail_exam .sideMessageDetails .SubjectMessage .reportIfDeclined button,
.detail_exam .sideMessageDetails .SubjectMessage .reportIfFinished button {
  color: white;
  background: #00aff0;
  border: none;
  width: 80px;
  height: 30px;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: bold;
  margin: 45px auto 0px 0;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence {
  width: 100%;
  margin-top: 30px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody {
  margin: 0 0 20px auto;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody p {
  text-indent: 20px;
  border: none;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody p span {
  font-weight: bold;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .senderMaiin {
  height: 30px;
  display: flex;
  background: #fa9551;
  justify-content: space-between;
  color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: relative;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .senderMaiin .senderP {
  margin-top: 5px;
  flex: 3 1;
  text-indent: 10px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .senderMaiin .senderP span {
  font-weight: 900;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .senderMaiin .createdP {
  margin: 5px 0 0 0;
  width: 150px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .senderMaiin #imageDiv {
  background: transparent;
  border: 1.7px solid white;
  width: 20px;
  border-radius: 5px;
  height: 20px;
  margin: 5px 20px 0 0;
  display: none;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .senderMaiin #imageDiv:hover {
  cursor: pointer;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 {
  background: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 10px 15px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 textarea {
  background: white;
  color: #666666;
  width: 100%;
  min-height: 100px;
  resize: none;
  border: none;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 textarea::-webkit-scrollbar {
  width: 5px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 .mainFileDiv {
  border-top: 1px solid #f2f2f2;
  height: 30px;
  display: flex;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 .mainFileDiv .FileDiv {
  height: 40px;
  display: flex;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 .mainFileDiv .FileDiv p {
  color: #00aff0;
  font-weight: bold;
  width: 50px;
  height: 25px;
  border-radius: 5px;
  text-align: start;
  justify-content: center;
  align-items: center;
  margin: 5px 0px 0 0;
  text-indent: 0;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 .mainFileDiv .fileForDownload {
  display: flex;
  color: #00aff0;
  border-radius: 5px;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  height: 25px;
  margin: 2px 0 0 0;
  padding: 0 5px;
  overflow: hidden;
  white-space: nowrap;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 .mainFileDiv .fileForDownload p {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
  margin: 0;
  color: #00aff0;
  text-indent: 0;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 .mainFileDiv .fileForDownload p:hover {
  text-overflow: scroll;
  width: 350px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 .mainFileDiv .fileForDownload:hover {
  cursor: pointer;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .ReplyMainDiv {
  margin-left: 20px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .ReplyMainDiv button {
  color: white;
  background: #00aff0;
  border: none;
  height: 40px;
  width: 80px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .ReplyMainDiv button .replyIcon {
  font-size: 20px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv {
  border: none;
  border-radius: 15px;
  margin: 10px auto 0 auto;
  box-shadow: none;
  background: white;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv textarea {
  margin: 0;
  width: 100%;
  padding: 10px 10px;
  min-height: 200px;
  resize: none;
  border: none;
  text-indent: 10px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv textarea::-webkit-scrollbar {
  width: 5px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv .sendbuttonAndAtt {
  display: flex;
  background: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin: 0;
  padding: 0;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv .sendbuttonAndAtt button {
  color: white;
  background: #00aff0;
  border: none;
  height: 30px;
  width: 80px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px 20px 20px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  box-shadow: none;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv .sendbuttonAndAtt button .replyIcon {
  font-size: 20px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv .sendbuttonAndAtt .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv .sendbuttonAndAtt .btn {
  color: white;
  background-color: #00aff0;
  width: 30px;
  border-radius: 8px;
  font-size: 30px;
  font-weight: bold;
  outline: none;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv .sendbuttonAndAtt .upload-btn-wrapper input[type=file] {
  font-size: 0px;
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: 0;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv .sendbuttonAndAtt .fileForDownload {
  display: flex;
  color: #00aff0;
  border-radius: 5px;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  height: 25px;
  margin: 2px 0 0 0;
  padding: 0 5px;
  overflow: hidden;
  white-space: nowrap;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv .sendbuttonAndAtt .fileForDownload p {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
  margin: 0;
  color: #00aff0;
  text-indent: 0;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv .sendbuttonAndAtt .fileForDownload p:hover {
  text-overflow: scroll;
  width: 350px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv .sendbuttonAndAtt .fileForDownload:hover {
  cursor: pointer;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .ReplyMainDiv {
  margin-left: 20px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .ReplyMainDiv button {
  color: white;
  background: #00aff0;
  border: 0;
  height: 40px;
  width: 80px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .ReplyMainDiv button .replyIcon {
  font-size: 20px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .ReplyMainDiv:disabled,
.detail_exam .sideMessageDetails .mainMessageCorrespondence .ReplyMainDiv button[disabled] {
  background: lightgray;
  color: white;
  cursor: not-allowed;
  box-shadow: none;
  pointer-events: none;
  border: none;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv {
  border: none;
  border-radius: 15px;
  margin: 10px auto 0 auto;
  box-shadow: none;
  background: white;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .senderMaiin {
  height: 30px;
  display: flex;
  background: #00aff0;
  justify-content: space-between;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: white;
  text-indent: 15px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .senderMaiin .senderP {
  margin-top: 5px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .senderMaiin .senderP span {
  font-weight: 900;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .senderMaiin .createdP {
  margin: 5px 20px 0 0;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv textarea {
  width: 100%;
  resize: none;
  border: none;
  height: 200px;
  text-indent: 0;
  padding: 10px 15px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv textarea::-webkit-scrollbar {
  width: 5px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt {
  display: flex;
  flex-direction: column;
  background: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin: 0;
  padding: 0 15px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt button {
  color: white;
  background: #00aff0;
  border: none;
  height: 30px;
  width: 80px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px 10px 0px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt button .replyIcon {
  font-size: 20px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .btn {
  outline: none;
  background: white;
  border: 1.7px solid #00aff0;
  height: 30px;
  color: #00aff0;
  font-weight: bold;
  padding: 0 5px;
  width: 70px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 0;
  align-items: center;
  box-shadow: none;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .btn p {
  margin: 0;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .upload-btn-wrapper input[type=file] {
  font-size: 0px;
  position: absolute;
  left: 0;
  top: 0;
  width: 70px;
  height: 30px;
  cursor: pointer;
  opacity: 0;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .fileForDownload {
  display: flex;
  color: #00aff0;
  border-radius: 5px;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  margin: 0 0 0 20px;
  padding: 0 5px;
  overflow: hidden;
  white-space: nowrap;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .fileForDownload p {
  white-space: nowrap;
  max-width: 100px;
  margin: 0 2px 0 0;
  color: #00aff0;
  text-indent: 0;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .fileForDownload p:hover {
  text-overflow: scroll;
  max-width: 350px;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .fileForDownload .deleteB {
  color: gray;
  width: 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  border: none;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .fileForDownload .deleteB:hover {
  cursor: pointer;
}
.detail_exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .attachess {
  display: flex;
  border-top: 1.7px solid #f2f2f2;
  padding: 5px 0 0 0;
  height: 40px;
}
.detail_exam .sideMessageDetails .newMessage {
  border: 2px solid #00aff0;
  color: #00aff0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: relative;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  float: right;
  margin: auto 0 0 auto;
}
.detail_exam .sideMessageDetails .newMessage h1 {
  margin: 0;
  position: absolute;
  font-size: 40px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.detail_exam .sideMessageDetails .newMessage:hover {
  background: #00aff0;
  color: white;
}
.detail_exam .sideMessageDetails .sendButtonForReplay {
  color: white;
  background: #00aff0;
  border: none;
  height: 30px;
  width: 80px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px 10px 0px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.detail_exam .sideMessageDetails .sendButtonForReplay .replyIcon {
  font-size: 20px;
}
.detail_exam .btn1 {
  background: #00aff0;
  position: absolute;
  bottom: 40px;
  margin: 0;
  font-size: 14px;
  padding: 12px;
  font-weight: bold;
  color: white;
  width: 150px;
  border-radius: 6px;
  box-shadow: none !important;
  border: none !important;
}
.detail_exam .btn1:hover {
  color: white !important;
}
.detail_exam .btn1:disabled,
.detail_exam .btn1 button[disabled] {
  background: lightgray;
  cursor: not-allowed;
  box-shadow: none;
  border: none;
  pointer-events: none;
}

.mainClien {
  display: flex;
  flex-direction: column;
  width: 40% !important;
  margin: 110px 120px 40px 360px;
  background: #f2f2f2;
  padding: 30px;
  border-radius: 15px;
  position: relative;
}
.mainClien .iconVideoo {
  position: absolute;
  top: -79px;
  left: 0;
  z-index: 20;
  height: 35px;
  display: flex;
}
.mainClien .iconVideoo .ConsulDetails {
  margin-right: 45px;
}
.mainClien .iconVideoo .ConsulDetails h4 {
  font-size: 15px;
  color: #2cace3;
}
.mainClien .iconVideoo .ConsulDetails:hover {
  cursor: pointer;
}
.mainClien .client {
  width: 100%;
  padding: 20px 20px 20px 20px;
  border: 1.7px solid #fa9551;
  border-radius: 15px;
  height: 250px;
  display: flex;
  margin: 0 0 20px 0 !important;
  background: white;
  justify-content: flex-start;
}
.mainClien .client img {
  width: 110px;
  height: 110px;
  border-radius: 10px;
  object-fit: cover;
}
.mainClien .client .client-p {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0 0 0 20px;
}
.mainClien .client .client-p p {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
}
.mainClien .client .client-p h5 {
  font-weight: 900;
  color: #666666;
  margin: 0 0 20px 0;
}
.mainClien .client .client-p h5 span {
  font-weight: 400;
}
.mainClien .form {
  padding: 0px !important;
  margin: 0;
  height: 300px;
  width: 100%;
  color: #666666;
}
.mainClien .form .conditionss {
  display: flex;
  flex-direction: column;
  text-align: start;
  height: 140px;
  border: 1.7px solid #fa9551;
  border-radius: 15px;
  font-weight: 900;
  color: #666666;
  margin: 0 0 20px 0;
  padding: 10px;
  background: white;
}
.mainClien .form .conditionss span {
  font-size: 16px;
}
.mainClien .form .conditionss textarea {
  background: transparent;
  border: none;
  font-weight: 400;
  resize: none;
  min-height: 100px;
}
.mainClien .form .conditionss textarea::-webkit-scrollbar {
  width: 5px;
}
.mainClien .form .conditionss textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.mainClien .form .conditionss textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.mainClien .form .conditionss textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.mainClien .form .conditionss textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.mainClien .form .allergiess {
  display: flex;
  flex-direction: column;
  text-align: start;
  font-weight: 900;
  border: 1.7px solid #fa9551;
  border-radius: 15px;
  height: 140px;
  color: #666666;
  margin: 20px 0;
  padding: 10px;
  background: white;
}
.mainClien .form .allergiess span {
  font-size: 16px;
}
.mainClien .form .allergiess textarea {
  background: transparent;
  border: none;
  font-weight: 400;
  resize: none;
  min-height: 100px;
}
.mainClien .form .allergiess textarea::-webkit-scrollbar {
  width: 5px;
}
.mainClien .form .allergiess textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.mainClien .form .allergiess textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.mainClien .form .allergiess textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.mainClien .form .allergiess textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}

.consultMain {
  background: #f2f2f2;
  position: relative;
  border-radius: 15px;
  text-align: left;
  margin: 110px auto 20px 360px;
  width: 780px;
  min-height: 50px;
  display: flex;
}
.consultMain .iconVideoo {
  position: absolute;
  top: -77px;
  z-index: 20;
  height: 35px;
  display: flex;
}
.consultMain .iconVideoo .ConsulDetails {
  margin-right: 45px;
}
.consultMain .iconVideoo .ConsulDetails h4 {
  font-size: 15px;
  color: #2cace3;
}
.consultMain .iconVideoo .ConsulDetails:hover {
  cursor: pointer;
}
.consultMain .mainTabelRecord {
  margin: 0 auto;
  min-height: 80px;
  border-radius: 15px;
  overflow: hidden;
  width: 780px;
  background: #f2f2f2;
}
.consultMain .mainTabelRecord .NoResultDiv {
  color: #666666;
  margin: 20px auto;
  font-size: 20px;
  text-align: center;
}
.consultMain .mainTabelRecord .mainConsultation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fa9551;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 40px;
}
.consultMain .mainTabelRecord .mainConsultation .icon_left span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 3px 10px;
  align-self: center;
  text-align: center;
}
.consultMain .mainTabelRecord .mainConsultation .icon_left span .icon1 {
  align-self: center;
  color: white;
  font-size: 22px;
  margin-top: 3px;
}
.consultMain .mainTabelRecord .mainConsultation .icon_left p {
  align-self: center;
  margin: 0 0 0 30px;
  color: white;
}
.consultMain .mainTabelRecord .mainConsultation .sort {
  margin: 0 10px;
  display: flex;
}
.consultMain .mainTabelRecord .mainConsultation .sort p {
  align-self: center;
  margin: 0 50px 0 0;
  text-overflow: bold;
  color: white;
}
.consultMain .mainTabelRecord .mainConsultation .sort p:hover {
  cursor: pointer;
}
.consultMain .mainTabelRecord .mainConsultation .sort .all {
  margin: 0 20px 0 0;
}
.consultMain .mainTabelRecord .table2 {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  padding: 10px;
  color: #666666;
}
.consultMain .mainTabelRecord .table2 tbody {
  background: #f2f2f2;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.consultMain .mainTabelRecord .table2 tbody tr {
  height: 40px;
}
.consultMain .mainTabelRecord .table2 tbody tr:hover {
  background: #e6e6e6;
  cursor: pointer;
}
.consultMain .mainTabelRecord .table2 tbody tr td {
  text-align: left;
}
.consultMain .mainTabelRecord .table2 tbody tr td .finished {
  color: #00aff0;
  font-size: 17px;
}
.consultMain .mainTabelRecord .table2 tbody tr .client-status {
  text-align: center;
  width: 100px;
}
.consultMain .mainTabelRecord .table2 tbody tr .client-status:hover .check,
.consultMain .mainTabelRecord .table2 tbody tr .client-status:hover .pendi,
.consultMain .mainTabelRecord .table2 tbody tr .client-status:hover .finished,
.consultMain .mainTabelRecord .table2 tbody tr .client-status:hover .declined {
  display: none;
}
.consultMain .mainTabelRecord .table2 tbody tr .client-status:hover .status {
  display: block;
}
.consultMain .mainTabelRecord .table2 tbody tr .status {
  display: none;
}
.consultMain .mainTabelRecord .table2 tbody tr .client-type {
  width: 120px !important;
}
.consultMain .mainTabelRecord .table2 tbody tr .created {
  width: 120px;
}
.consultMain .mainTabelRecord .table2 tbody tr .client-subject {
  width: 300px;
  padding: 0 0 0 30px;
}
.consultMain .mainTabelRecord .table2 .client-head {
  text-align: center;
  color: #666666;
}
.consultMain .mainTabelRecord .table2 .client-head .client-row {
  background: #b3b3b3;
}
.consultMain .mainTabelRecord .table2 .client-head .client-row .client-subject {
  display: flex;
  margin: 0 auto 0 30px;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
}
.consultMain .mainTabelRecord .table2 .client-head .client-row .client-type {
  height: 40px;
  padding: 0;
  width: 120px;
}
.consultMain .mainTabelRecord .table2 .client-head .client-row .client-type .mainExamDiv {
  margin: 0 0 0 -5px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 120px;
}
.consultMain .mainTabelRecord .table2 .client-head .client-row .client-type .mainExamDiv .searchIcon {
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 30px;
  height: 100%;
}
.consultMain .mainTabelRecord .table2 .client-head .client-row .client-type .mainExamDiv .searchIcon:hover {
  cursor: pointer;
}
.consultMain .mainTabelRecord .table2 .client-head .client-row .client-type .mainExamDiv select {
  width: 80px;
  height: 25px;
  border: none;
  border-radius: 5px;
  background: #b3b3b3;
}
.consultMain .mainTabelRecord .table2 .client-head .client-row .client-type .mainExamDiv select::-webkit-input-placeholder {
  color: #666666;
  font-size: 10px;
}
.consultMain .mainTabelRecord .table2 .client-head .client-row .client-type .mainExamDiv select:-ms-input-placeholder {
  color: #666666;
  font-size: 10px;
}
.consultMain .mainTabelRecord .table2 .client-head .client-row .client-type .mainExamDiv select::-ms-input-placeholder {
  color: #666666;
  font-size: 10px;
}
.consultMain .mainTabelRecord .table2 .client-head .client-row .client-type .mainExamDiv select::placeholder {
  color: #666666;
  font-size: 10px;
}
.consultMain .mainTabelRecord .table2 .client-head .client-row .client-type .mainExamDiv select option {
  background: white;
}
.consultMain .mainTabelRecord .table2 .client-head .client-row .client-date {
  display: flex;
  margin: 0;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  width: 120px;
}
.consultMain .mainTabelRecord .table2 .client-head .client-row .client-status {
  text-align: center;
  margin: 0 auto;
  height: 40px;
}

.pagi {
  display: flex;
  width: 780px;
  margin: 20px auto 0px 360px;
  justify-content: center;
}
.pagi ul {
  margin: 20px auto;
}
.pagi ul li a {
  color: #00aff0;
  border: none;
}
.pagi ul .lastPage {
  display: none;
}
.pagi ul .active a {
  background: #00aff0;
  color: white;
}
.pagi ul .disabled a {
  color: #00aff0;
}
* input:-webkit-autofill,
* input:-webkit-autofill:hover,
* input:-webkit-autofill:focus textarea:-webkit-autofill,
* textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
* select:-webkit-autofill,
* select:-webkit-autofill:hover,
* select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.mainRegisterDiv {
  width: 47%;
  margin: 120px auto 0 auto;
  text-align: start;
  background: #f2f2f2;
  padding: 0 70px;
  border-radius: 15px;
  color: #666666;
  position: relative;
}
.mainRegisterDiv .logo {
  position: absolute;
  width: 110px;
  right: -30px;
  top: -25px;
}
.mainRegisterDiv .loading {
  -webkit-animation: rotation 2s infinite linear;
          animation: rotation 2s infinite linear;
  width: 70px !important;
  position: absolute;
  top: 45%;
  left: 48%;
  -webkit-transform: translate(-45%, -48%);
          transform: translate(-45%, -48%);
  z-index: 20;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
.mainRegisterDiv .radioDiv {
  width: 100% !important;
}
.mainRegisterDiv .radioDiv h1 {
  font-weight: 700;
  text-align: start;
  color: #00aff0;
  padding: 20px 0 0 0;
}
.mainRegisterDiv .radioDiv .checkingInputs {
  margin: 20px 0 0 0;
  display: flex;
  justify-content: space-between;
}
.mainRegisterDiv .radioDiv .checkingInputs p {
  font-weight: 700;
  color: LightCoral;
  position: absolute;
  left: 0;
  bottom: -40px;
}
.mainRegisterDiv .radioDiv .checkingInputs .doctorORClient {
  display: flex;
  width: 100%;
  margin: 10px 10px 0 0;
  justify-content: space-evenly;
}
.mainRegisterDiv .radioDiv .checkingInputs .doctorORClient input[type=checkbox] {
  visibility: hidden;
  position: absolute;
}
.mainRegisterDiv .radioDiv .checkingInputs .doctorORClient input[type=checkbox] + label:before {
  height: 15px;
  width: 15px;
  margin-right: 5px;
  content: " ";
  display: inline-block;
  vertical-align: baseline;
  border: 1px solid #00aff0;
  border-radius: 50%;
}
.mainRegisterDiv .radioDiv .checkingInputs .doctorORClient input[type=checkbox]:checked + label:before {
  background: #00aff0;
}
.mainRegisterDiv .radioDiv .checkingInputs .doctorORClient .doctorLabel {
  color: #666666;
  font-size: 15px;
  font-weight: 700;
}
.mainRegisterDiv .radioDiv .checkingInputs .doctorORClient .clientLabel {
  color: #666666;
  font-size: 15px;
  font-weight: 700;
}
.mainRegisterDiv .radioDiv .checkingInputs .gender {
  width: 100%;
  margin: 0 0 0 10px;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
}
.mainRegisterDiv .radioDiv .checkingInputs .gender label {
  float: right !important;
}
.mainRegisterDiv .radioDiv .checkingInputs .gender .maleGender {
  margin: 10px 10px 0 0;
}
.mainRegisterDiv .radioDiv .checkingInputs .gender .maleGender input[type=radio] {
  visibility: hidden;
  position: absolute;
}
.mainRegisterDiv .radioDiv .checkingInputs .gender .maleGender input[type=radio] + label:before {
  height: 15px;
  width: 15px;
  margin-right: 5px;
  content: " ";
  display: inline-block;
  vertical-align: baseline;
  border: 1px solid #00aff0;
  border-radius: 50%;
}
.mainRegisterDiv .radioDiv .checkingInputs .gender .maleGender input[type=radio]:checked + label:before {
  background: #00aff0;
}
.mainRegisterDiv .radioDiv .checkingInputs .gender .femaleGender {
  margin: 10px 0 0 10px;
}
.mainRegisterDiv .radioDiv .checkingInputs .gender .femaleGender input[type=radio] {
  visibility: hidden;
  position: absolute;
}
.mainRegisterDiv .radioDiv .checkingInputs .gender .femaleGender input[type=radio] + label:before {
  height: 15px;
  width: 15px;
  margin-right: 5px;
  content: " ";
  display: inline-block;
  vertical-align: baseline;
  border: 1px solid #00aff0;
  border-radius: 50%;
}
.mainRegisterDiv .radioDiv .checkingInputs .gender .femaleGender input[type=radio]:checked + label:before {
  background: #00aff0;
}
@media (max-width: 1400px) {
  .mainRegisterDiv .radioDiv {
    width: 80%;
  }
}
.mainRegisterDiv .register-form {
  margin: 40px auto 0 auto;
}
.mainRegisterDiv .register-form label {
  font-size: 12px;
  float: left;
  text-indent: 2px;
}
.mainRegisterDiv .register-form .Pricing {
  margin: 5px 0 20px 0;
  display: flex;
  justify-content: space-between;
}
.mainRegisterDiv .register-form .Pricing ::-webkit-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .Pricing :-ms-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .Pricing ::-ms-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .Pricing ::placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .Pricing input {
  height: 40px;
  width: 250px;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  background: white;
  font-weight: 700;
}
.mainRegisterDiv .register-form .Pricing .WebPrice {
  width: 250px;
}
.mainRegisterDiv .register-form .Pricing .WebPrice input {
  margin: 0 -2px 0 auto;
  font-weight: 700;
}
.mainRegisterDiv .register-form .firstLastGender {
  display: flex;
  justify-content: space-between;
  margin: 0 0 20px 0;
}
.mainRegisterDiv .register-form .firstLastGender .reg-name {
  width: 100%;
  margin: 0 20px 0 0;
}
.mainRegisterDiv .register-form .firstLastGender .reg-name ::-webkit-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .firstLastGender .reg-name :-ms-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .firstLastGender .reg-name ::-ms-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .firstLastGender .reg-name ::placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .firstLastGender .reg-name input {
  flex: 1 1;
  height: 40px;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  background: white;
  font-weight: 700;
  margin: 0;
}
.mainRegisterDiv .register-form .firstLastGender .reg-surname {
  width: 100%;
}
.mainRegisterDiv .register-form .firstLastGender .reg-surname ::-webkit-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .firstLastGender .reg-surname :-ms-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .firstLastGender .reg-surname ::-ms-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .firstLastGender .reg-surname ::placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .firstLastGender .reg-surname input {
  flex: 1 1;
  height: 40px;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  margin: 0;
  background: white;
  font-weight: 700;
}
.mainRegisterDiv .register-form .regPass {
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
}
.mainRegisterDiv .register-form .regPass .reg-email {
  margin: 0 20px 0 0;
  width: 100% !important;
}
.mainRegisterDiv .register-form .regPass .reg-email ::-webkit-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .regPass .reg-email :-ms-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .regPass .reg-email ::-ms-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .regPass .reg-email ::placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .regPass .reg-email input {
  height: 40px;
  flex: 1 1;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  background: white;
  margin: 0;
  font-weight: 700;
}
.mainRegisterDiv .register-form .regPass .address {
  width: 100%;
}
.mainRegisterDiv .register-form .regPass .address ::-webkit-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .regPass .address :-ms-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .regPass .address ::-ms-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .regPass .address ::placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .regPass .address input {
  height: 40px;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  background: white;
  font-weight: 700;
  flex: 1 1;
  margin: 0;
}
.mainRegisterDiv .register-form .regPass .address input[type=number]::-webkit-inner-spin-button,
.mainRegisterDiv .register-form .regPass .address input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mainRegisterDiv .register-form .clientsBinfo {
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
}
.mainRegisterDiv .register-form .clientsBinfo .reg-pass,
.mainRegisterDiv .register-form .clientsBinfo .conf-pass {
  width: 100%;
  margin: 0 20px 0 0;
}
.mainRegisterDiv .register-form .clientsBinfo .reg-pass ::-webkit-input-placeholder, .mainRegisterDiv .register-form .clientsBinfo .conf-pass ::-webkit-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .clientsBinfo .reg-pass :-ms-input-placeholder, .mainRegisterDiv .register-form .clientsBinfo .conf-pass :-ms-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .clientsBinfo .reg-pass ::-ms-input-placeholder, .mainRegisterDiv .register-form .clientsBinfo .conf-pass ::-ms-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .clientsBinfo .reg-pass ::placeholder,
.mainRegisterDiv .register-form .clientsBinfo .conf-pass ::placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .clientsBinfo .reg-pass div,
.mainRegisterDiv .register-form .clientsBinfo .conf-pass div {
  width: 100%;
  position: relative;
}
.mainRegisterDiv .register-form .clientsBinfo .reg-pass div input,
.mainRegisterDiv .register-form .clientsBinfo .conf-pass div input {
  flex: 1 1;
  margin: 0 0 10px 0;
  height: 40px;
  border-radius: 10px;
  border: 1.7px solid #fa9551;
  font-weight: 700;
  margin: 0;
  padding-right: 30px;
}
.mainRegisterDiv .register-form .clientsBinfo .reg-pass div img,
.mainRegisterDiv .register-form .clientsBinfo .conf-pass div img {
  position: absolute;
  right: 5px;
  bottom: 13px;
}
.mainRegisterDiv .register-form .clientsBinfo .conf-pass {
  margin: 0;
}
.mainRegisterDiv .register-form .clientsBinfo .date {
  width: 100%;
}
.mainRegisterDiv .register-form .clientsBinfo .date ::-webkit-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .clientsBinfo .date :-ms-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .clientsBinfo .date ::-ms-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .clientsBinfo .date ::placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .clientsBinfo .date input {
  height: 40px;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  background: white;
  font-weight: 700;
  flex: 1 1;
  margin: 0;
}
.mainRegisterDiv .register-form .clientsBinfo .date .dateOnRegister {
  color: #9c9b9b;
}
.mainRegisterDiv .register-form .clientsBinfo .spec {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 40px;
  margin: 0px 0 40px 0;
  border-radius: 10px;
}
.mainRegisterDiv .register-form .clientsBinfo {
  display: flex;
}
.mainRegisterDiv .register-form .doctorsIdInfo {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  gap: 20px;
}
.mainRegisterDiv .register-form .doctorsIdInfo ::-webkit-input-placeholder {
  color: #4092c2;
  font-size: 10px;
}
.mainRegisterDiv .register-form .doctorsIdInfo :-ms-input-placeholder {
  color: #4092c2;
  font-size: 10px;
}
.mainRegisterDiv .register-form .doctorsIdInfo ::-ms-input-placeholder {
  color: #4092c2;
  font-size: 10px;
}
.mainRegisterDiv .register-form .doctorsIdInfo ::placeholder {
  color: #4092c2;
  font-size: 10px;
}
.mainRegisterDiv .register-form .doctorsIdInfo .prefix {
  width: 100%;
  margin: 0 0 30px 0;
}
.mainRegisterDiv .register-form .doctorsIdInfo .prefix div {
  width: 100%;
  position: relative;
}
.mainRegisterDiv .register-form .doctorsIdInfo .prefix div input {
  width: 100%;
  margin: 0 0 10px 0;
  height: 40px;
  border-radius: 10px;
  border: 1.7px solid #fa9551;
  font-weight: 700;
  flex: 1 1;
  margin: 0;
  padding-right: 30px;
}
.mainRegisterDiv .register-form .doctorsIdInfo .prefix div input[type=number]::-webkit-inner-spin-button,
.mainRegisterDiv .register-form .doctorsIdInfo .prefix div input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mainRegisterDiv .register-form .doctorsIdInfo .prefix div img {
  position: absolute;
  right: 5px;
  bottom: 13px;
}
.mainRegisterDiv .register-form .doctorsIdInfo .spec {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 40px;
  margin: 0px 0 40px 0;
  border-radius: 10px;
}
.mainRegisterDiv .register-form .doctorsBinfo {
  display: flex;
  justify-content: space-between;
}
.mainRegisterDiv .register-form .doctorsBinfo ::-webkit-input-placeholder {
  color: #4092c2;
  font-size: 10px;
}
.mainRegisterDiv .register-form .doctorsBinfo :-ms-input-placeholder {
  color: #4092c2;
  font-size: 10px;
}
.mainRegisterDiv .register-form .doctorsBinfo ::-ms-input-placeholder {
  color: #4092c2;
  font-size: 10px;
}
.mainRegisterDiv .register-form .doctorsBinfo ::placeholder {
  color: #4092c2;
  font-size: 10px;
}
.mainRegisterDiv .register-form .doctorsBinfo .prefix {
  width: 100%;
  margin: 0 20px 20px 0;
}
.mainRegisterDiv .register-form .doctorsBinfo .prefix div {
  width: 100%;
  position: relative;
}
.mainRegisterDiv .register-form .doctorsBinfo .prefix div input {
  width: 100%;
  margin: 0 0 10px 0;
  height: 40px;
  border-radius: 10px;
  border: 1.7px solid #fa9551;
  font-weight: 700;
  flex: 1 1;
  margin: 0;
  padding-right: 30px;
}
.mainRegisterDiv .register-form .doctorsBinfo .prefix div img {
  position: absolute;
  right: 5px;
  bottom: 13px;
}
.mainRegisterDiv .register-form .doctorsBinfo .spec {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 40px;
  margin: 0px 0 0 0;
  border-radius: 10px;
}
.mainRegisterDiv .register-form .regPass {
  display: flex;
}
.mainRegisterDiv .register-form .form-control {
  margin: 3px;
}
.mainRegisterDiv .register-form .register-button {
  width: 100px;
  margin-top: 5px;
}
.mainRegisterDiv .register-form .register-button .btn {
  position: relative;
  width: 80px;
  height: 35px;
  color: white;
  border-radius: 10px;
  background: #00aff0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  margin: 0 0 30px 2px;
  outline: 0;
}
.mainRegisterDiv .register-form .register-button .btn:hover {
  box-shadow: 2px 2px 2px #6d6d6d;
}
.mainRegisterDiv .register-form .confirmAndPhone {
  display: flex;
  justify-content: space-between;
}
.mainRegisterDiv .register-form .confirmAndPhone .conf-pass {
  width: 100%;
  margin: 0 20px 30px 0;
}
.mainRegisterDiv .register-form .confirmAndPhone .conf-pass div {
  width: 100%;
  position: relative;
}
.mainRegisterDiv .register-form .confirmAndPhone .conf-pass div input {
  flex: 1 1;
  margin: 0 0 10px 0;
  height: 40px;
  border-radius: 10px;
  border: 1.7px solid #fa9551;
  font-weight: 700;
  margin: 0;
  padding-right: 30px;
}
.mainRegisterDiv .register-form .confirmAndPhone .conf-pass div img {
  position: absolute;
  right: 5px;
  bottom: 13px;
}
.mainRegisterDiv .register-form .confirmAndPhone .Phone {
  width: 100%;
  margin: 0 0 30px 0;
}
.mainRegisterDiv .register-form .confirmAndPhone .Phone input {
  height: 40px;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  background: white;
  font-weight: 700;
  flex: 1 1;
  margin: 0;
}
.mainRegisterDiv .register-form .confirmAndPhone .Phone input::-webkit-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .confirmAndPhone .Phone input:-ms-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .confirmAndPhone .Phone input::-ms-input-placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .confirmAndPhone .Phone input::placeholder {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
}
.mainRegisterDiv .register-form .confirmAndPhone .Phone input[type=number]::-webkit-inner-spin-button,
.mainRegisterDiv .register-form .confirmAndPhone .Phone input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mainLogin {
  margin: 120px auto 0px auto;
  background: #f2f2f2;
  width: 37%;
  position: relative;
  border-radius: 15px;
}
.mainLogin .loading {
  -webkit-animation: rotation 2s infinite linear;
          animation: rotation 2s infinite linear;
  width: 70px !important;
  position: absolute;
  top: 45%;
  left: 45%;
  -webkit-transform: translate(-45%, -45%);
          transform: translate(-45%, -45%);
  z-index: 20;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
.mainLogin .logo {
  position: absolute;
  width: 110px;
  right: -30px;
  top: -25px;
}
.mainLogin h1 {
  color: #00aff0;
  font-size: 45px;
  font-weight: bold;
  padding: 40px 0 0 0;
}
.mainLogin h5 {
  color: #666666;
  margin: 10px 0;
  font-weight: 800;
  font-size: 18px;
  letter-spacing: 0, 5px;
}
.mainLogin .createAccount {
  color: #00aff0;
  font-weight: 800;
  text-decoration: underline;
}
.mainLogin .login-form {
  width: 300px;
  margin: 0 auto;
  text-align: left;
  color: #666666;
  font-size: 13px;
  font-weight: bold;
}
.mainLogin .login-form label {
  margin: 0 0 10px 0;
}
.mainLogin .login-form .rememberForgot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0 0;
}
.mainLogin .login-form .rememberForgot div {
  display: flex;
  margin: 10px 0 0 0;
}
.mainLogin .login-form .rememberForgot div input[type=checkbox] {
  visibility: hidden;
  position: absolute;
}
.mainLogin .login-form .rememberForgot div input[type=checkbox] + label:before {
  height: 15px;
  width: 15px;
  margin-right: 5px;
  content: " ";
  display: inline-block;
  vertical-align: baseline;
  border: 1px solid #00aff0;
  border-radius: 50%;
}
.mainLogin .login-form .rememberForgot div input[type=checkbox]:checked + label:before {
  background: #00aff0;
}
.mainLogin .login-form .rememberForgot .forget {
  color: #666666;
}
.mainLogin .login-form .email {
  width: 300px;
  margin: 20px auto 30px auto;
}
.mainLogin .login-form .email ::-webkit-input-placeholder {
  color: #666666;
  font-size: 12px;
}
.mainLogin .login-form .email :-ms-input-placeholder {
  color: #666666;
  font-size: 12px;
}
.mainLogin .login-form .email ::-ms-input-placeholder {
  color: #666666;
  font-size: 12px;
}
.mainLogin .login-form .email ::placeholder {
  color: #666666;
  font-size: 12px;
}
.mainLogin .login-form .email .form-control {
  height: 40px;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
}
.mainLogin .login-form .password {
  width: 300px;
  margin: 0 auto;
}
.mainLogin .login-form .password ::-webkit-input-placeholder {
  color: #666666;
  font-size: 16px;
}
.mainLogin .login-form .password :-ms-input-placeholder {
  color: #666666;
  font-size: 16px;
}
.mainLogin .login-form .password ::-ms-input-placeholder {
  color: #666666;
  font-size: 16px;
}
.mainLogin .login-form .password ::placeholder {
  color: #666666;
  font-size: 16px;
}
.mainLogin .login-form .password div {
  width: 100%;
  position: relative;
}
.mainLogin .login-form .password div input {
  width: 100%;
  margin: 0 0 10px 0;
  height: 40px;
  border-radius: 10px;
  border: 1.7px solid #fa9551;
  font-weight: 600;
  padding-right: 30px;
}
.mainLogin .login-form .password div img {
  position: absolute;
  right: 5px;
  top: 13px;
}
.mainLogin .login-form .login-button {
  margin-bottom: 20px;
  height: 60px;
}
.mainLogin .login-form .login-button .btn {
  width: 70px;
  color: white;
  background: #00aff0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  margin: 40px auto 20px auto;
  font-weight: 600;
  outline: 0;
}
.mainLogin .login-form .login-button .btn:hover {
  box-shadow: 2px 2px 2px black;
}
.exam {
  width: calc(100% - 480px) !important;
  margin: 110px auto 20px 360px !important;
}
.exam .mainExam {
  padding: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #f2f2f2;
  border-radius: 15px;
  position: relative;
}
.exam .mainExam .newEmail {
  position: absolute;
  top: -76px;
  left: 0px;
  z-index: 20;
  height: 35px;
  display: flex;
  border-bottom: 4px solid #fa9551;
  margin-right: 45px;
}
.exam .mainExam .newEmail h4 {
  font-weight: bold;
  font-size: 15px;
  color: #2cace3;
}
.exam .mainExam .newEmail:hover {
  cursor: pointer;
}
.exam .mainExam .exam-spec {
  margin-right: 10px;
  width: 100%;
}
.exam .mainExam .exam-doc {
  margin-right: 10px;
  width: 100%;
}
.exam .mainExam .exam-sub {
  width: 100%;
}
.exam .mainExam .exam-sub .form-control {
  height: 33px !important;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  width: 100%;
  font-weight: 600;
  background: white;
  color: #666666;
}
.exam .exam-mess {
  margin-top: 20px !important;
  border-radius: 15px;
  width: 100%;
  margin: 0 auto;
  background: #f2f2f2;
  padding: 30px;
}
.exam .exam-mess .messageTitle {
  height: 40px;
  background: #fa9551;
  color: white;
  font-weight: 600;
  text-align: start;
  display: flex;
  align-items: center;
  text-indent: 12px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.exam .exam-mess .messageAndAttach {
  background: white;
  padding: 10px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.exam .exam-mess .messageAndAttach .form-control {
  height: 200px;
  border: none;
  box-shadow: none;
  overflow-x: hidden;
  resize: none;
  padding: 0;
}
.exam .exam-mess .messageAndAttach .form-control::-webkit-scrollbar {
  width: 5px;
}
.exam .exam-mess .messageAndAttach .form-control::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.exam .exam-mess .messageAndAttach .form-control::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.exam .exam-mess .messageAndAttach .form-control::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.exam .exam-mess .messageAndAttach .form-control::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.exam .exam-mess .messageAndAttach .mainDivForFile {
  display: flex;
  padding-top: 4px;
  border-top: 1px solid #f2f2f2;
  position: relative;
}
.exam .exam-mess .messageAndAttach .mainDivForFile .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.exam .exam-mess .messageAndAttach .mainDivForFile .upload-btn-wrapper .btnn {
  background: white;
  border: 1.7px solid #00aff0;
  height: 30px;
  color: #00aff0;
  font-weight: bold;
  padding: 0 5px;
  width: 70px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.exam .exam-mess .messageAndAttach .mainDivForFile .upload-btn-wrapper .btnn .attachDiv {
  align-items: center;
}
.exam .exam-mess .messageAndAttach .mainDivForFile .upload-btn-wrapper .btnn .attachDiv p {
  margin: 0;
}
.exam .exam-mess .messageAndAttach .mainDivForFile .upload-btn-wrapper input[type=file] {
  font-size: 0px;
  position: absolute;
  background: #00aff0;
  left: 0;
  top: 0;
  width: 70px;
  height: 40px;
  opacity: 0;
  cursor: pointer;
}
.exam .exam-mess .messageAndAttach .mainDivForFile .fileForDownload {
  left: 120px;
  border-radius: 5px;
  color: #00aff0;
  padding: 0 5px;
  height: 30px;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 0 0 0 10px;
  white-space: nowrap;
}
.exam .exam-mess .messageAndAttach .mainDivForFile .fileForDownload p {
  text-align: start;
  white-space: nowrap;
  max-width: 100px;
  margin: 0 2px 0 0;
  color: #00aff0;
  text-indent: 0;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.exam .exam-mess .messageAndAttach .mainDivForFile .fileForDownload p:hover {
  text-overflow: scroll;
  max-width: 350px;
}
.exam .exam-mess .messageAndAttach .mainDivForFile .fileForDownload .deleteB {
  color: gray;
  width: 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  border: none;
}
.exam .exam-mess .messageAndAttach .mainDivForFile .fileForDownload .deleteB:hover {
  cursor: pointer;
}
.exam .exam-mess .buttons {
  display: flex;
  height: 60px;
}
.exam .exam-mess .buttons .btn {
  margin: 30px 10px 0 0;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  font-weight: 500;
  color: white;
  background: #00aff0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  outline: 0;
}
.exam .exam-mess .buttons .btn:hover {
  box-shadow: 2px 2px 2px black;
}
.exam .exam-mess .buttons .btn:focus {
  outline: 0;
  border: none;
}
.exam .exam-mess .buttons .btn:active {
  outline: 0;
  border: none;
}
.exam .exam-mess .buttons .btnattach {
  margin: 30px 0 0 0;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  font-weight: 500;
  border-radius: 5px;
  color: white;
  background: #00aff0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  outline: 0;
  border: none;
}
.exam .exam-mess .buttons .btnattach:hover {
  box-shadow: 2px 2px 2px black;
}
.exam .exam-mess .buttons .btnattach:focus {
  outline: 0;
  border: none;
}
.exam .exam-mess .buttons .btnattach:active {
  outline: 0;
  border: none;
}
.exam #subject {
  height: 38px;
}
.App {
  position: relative;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.mainTitle {
  color: #00aff0;
  font-weight: 700;
  font-size: 30pt;
  width: calc(100% - 480px);
  margin: 110px 3px 20px 360px;
}

.underTitle {
  color: #666666;
  font-weight: 700;
  width: calc(100% - 480px);
  margin: 0px 3px 20px 360px;
}

.mainCardDiv {
  display: flex;
  flex-direction: column;
  align-self: center;
  background: #f2f2f2;
  border-radius: 15px;
  width: calc(100% - 480px);
  margin: 0px 0 20px 360px;
}
.mainCardDiv .loading {
  -webkit-animation: rotation 2s infinite linear;
          animation: rotation 2s infinite linear;
  width: 70px !important;
  position: absolute;
  top: 50%;
  left: calc(50% + 70px);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 20;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
.mainCardDiv .payWay {
  color: #00aff0;
  width: 65%;
  font-size: 50pt;
  display: flex;
  align-self: center;
  justify-content: space-around;
  margin: 30px auto;
}
.mainCardDiv .payWay .cardIcon {
  width: 150px;
  height: 100px;
  align-self: center;
  position: relative;
  border-radius: 10px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin: 0 20px 0 0;
}
.mainCardDiv .payWay .cardIcon .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.mainCardDiv .payWay .cardIcon .icon:hover {
  cursor: pointer;
}
.mainCardDiv .payWay .paypalIcon {
  width: 150px;
  height: 100px;
  align-self: center;
  position: relative;
  border-radius: 10px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin: 0 0 0 20px;
}
.mainCardDiv .payWay .paypalIcon .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.mainCardDiv .payWay .paypalIcon .icon:hover {
  cursor: pointer;
}
.mainCardDiv .mainBillDiv {
  display: flex;
  align-self: center;
}
.mainCardDiv .mainBillDiv .billingInfo {
  margin: 0 40px 0 0;
}
.mainCardDiv .mainBillDiv .billingInfo h1 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #00aff0;
  text-align: start;
}
.mainCardDiv .mainBillDiv .billingInfo label {
  font-size: 11px;
  text-align: start;
  color: #666666;
  line-height: 20px;
  line-height: 25px;
}
.mainCardDiv .mainBillDiv .billingInfo input {
  width: 250px;
  height: 40px;
  border-radius: 10px;
  border: none;
  text-indent: 15px;
  border: 1.7px solid #fa9551;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
}
.mainCardDiv .mainBillDiv .billingInfo input::-webkit-input-placeholder {
  color: #666666;
  font-size: 14px;
  font-weight: 450;
}
.mainCardDiv .mainBillDiv .billingInfo input:-ms-input-placeholder {
  color: #666666;
  font-size: 14px;
  font-weight: 450;
}
.mainCardDiv .mainBillDiv .billingInfo input::-ms-input-placeholder {
  color: #666666;
  font-size: 14px;
  font-weight: 450;
}
.mainCardDiv .mainBillDiv .billingInfo input::placeholder {
  color: #666666;
  font-size: 14px;
  font-weight: 450;
}
.mainCardDiv .mainBillDiv .billingInfo .totalPrice {
  display: flex;
  width: 250px;
  height: 40px;
  color: #666666;
  border-radius: 10px;
  text-indent: 15px;
  border: 1.7px solid #00aff0;
  flex-direction: column;
  justify-content: center;
  margin: 93px 0 0 0;
  background: white;
}
.mainCardDiv .creditCardInfo {
  margin: 0 0 0 40px;
}
.mainCardDiv .creditCardInfo h1 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: start;
  color: #00aff0;
}
.mainCardDiv .creditCardInfo label {
  font-size: 11px;
  text-align: start;
  line-height: 25px;
  color: #666666;
}
.mainCardDiv .creditCardInfo form {
  display: flex;
  flex-direction: column;
}
.mainCardDiv .creditCardInfo .CardElement {
  height: 40px;
  padding: 10px 12px;
  width: 250px;
  color: #666666;
  background-color: white;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
}
.mainCardDiv .creditCardInfo .CardElement::-webkit-input-placeholder {
  color: #666666;
  font-size: 14px;
  font-weight: 450;
}
.mainCardDiv .creditCardInfo .CardElement:-ms-input-placeholder {
  color: #666666;
  font-size: 14px;
  font-weight: 450;
}
.mainCardDiv .creditCardInfo .CardElement::-ms-input-placeholder {
  color: #666666;
  font-size: 14px;
  font-weight: 450;
}
.mainCardDiv .creditCardInfo .CardElement::placeholder {
  color: #666666;
  font-size: 14px;
  font-weight: 450;
}

.CardElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.CardElement--invalid {
  border-color: #fa755a;
}

.CardElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.btn-checkout {
  margin: 10px auto 20px auto;
  border-radius: 10px;
  border: none;
  left: -5px;
  height: 40px;
  top: 25px;
  width: 250px;
  color: white;
  font-weight: 600;
  background-color: #00aff0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  outline: 0;
}
.btn-checkout:hover {
  background-color: #34779e;
  cursor: pointer;
  box-shadow: 2px 2px 10px #4e4e4e;
}
.mainDoctor {
  display: flex;
  width: 40%;
  margin: 110px auto 20px 360px;
  background: #f2f2f2;
  border-radius: 15px;
  position: relative;
}
.mainDoctor .newVideo {
  position: absolute;
  top: -76px;
  z-index: 20;
  height: 35px;
  display: flex;
}
.mainDoctor .newVideo .profileDoc {
  margin-right: 45px;
}
.mainDoctor .newVideo .profileDoc h4 {
  font-size: 15px;
  color: #2cace3;
}
.mainDoctor .newVideo .profileDoc:hover {
  cursor: pointer;
}
.mainDoctor .doctor {
  width: 50%;
  padding: 30px 0px 30px 30px;
}
.mainDoctor .doctor .doctor-p {
  display: flex;
  flex-direction: column;
}
.mainDoctor .doctor .doctor-p input[type=number]::-webkit-inner-spin-button,
.mainDoctor .doctor .doctor-p input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.mainDoctor .doctor .doctor-p input {
  height: 33px;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  background: white;
  margin: 2px 0 20px 0;
  text-indent: 15px;
  font-weight: 700;
  width: 90%;
}
.mainDoctor .doctor .doctor-p input::-webkit-input-placeholder {
  font-weight: 700;
  color: #969696;
}
.mainDoctor .doctor .doctor-p input:-ms-input-placeholder {
  font-weight: 700;
  color: #969696;
}
.mainDoctor .doctor .doctor-p input::-ms-input-placeholder {
  font-weight: 700;
  color: #969696;
}
.mainDoctor .doctor .doctor-p input::placeholder {
  font-weight: 700;
  color: #969696;
}
.mainDoctor .doctor .doctor-p label {
  color: #666666;
  text-align: left;
}
.mainDoctor .doctor .doctor-p .saveChanges {
  background: #00aff0;
  width: 80px;
  height: 30px;
  color: white;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  margin: 25px 0 0 0;
}
.mainDoctor .form {
  padding: 30px 30px 30px 0px;
  width: 50%;
  color: #666666;
}
.mainDoctor .form .imageAndGender {
  display: flex;
}
.mainDoctor .form .imageAndGender .profilePic {
  display: flex;
  width: 110px;
  height: 110px;
  border-radius: 10px;
}
.mainDoctor .form .imageAndGender .profilePic p {
  font-weight: 600;
  color: #666666;
  margin: 0;
}
.mainDoctor .form .imageAndGender .profilePic .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
}
.mainDoctor .form .imageAndGender .profilePic .upload-btn-wrapper .btn {
  margin: 0;
  width: 110px;
  height: 110px;
  padding: 0;
  border-radius: 10px;
  background: transparent !important;
}
.mainDoctor .form .imageAndGender .profilePic .upload-btn-wrapper .btn .docImage {
  width: 107px;
  height: 107px;
  border-radius: 10px;
  object-fit: cover;
}
.mainDoctor .form .imageAndGender .profilePic .upload-btn-wrapper .btn p {
  font-size: 80px;
  padding: 0;
}
.mainDoctor .form .imageAndGender .profilePic .upload-btn-wrapper .deleteImage {
  position: absolute;
  width: 20px;
  height: 20px;
  background: red;
  color: white;
  border: none;
  border-radius: 5px;
  z-index: 1000;
  top: 5px;
  left: 85px;
}
.mainDoctor .form .imageAndGender .profilePic .upload-btn-wrapper input[type=file] {
  font-size: 0px;
  position: absolute;
  left: 0;
  top: -20px;
  width: 110px;
  height: 140px;
  opacity: 0;
  cursor: pointer;
}
.mainDoctor .form .pricing {
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 30px 0;
}
.mainDoctor .form .allergies {
  text-align: start;
  font-weight: 700;
  color: #666666;
  margin: 25px 0 0 0;
}
.mainDoctor .form .allergies .Nameing {
  display: flex;
  justify-content: space-between;
}
.mainDoctor .form .allergies .Nameing p {
  margin-bottom: 7px;
}
.mainDoctor .form .allergies .textAndProfile textarea {
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  background: white;
  padding: 10px;
  font-weight: 700;
  width: 100%;
  height: 115px;
  resize: none;
}
.mainDoctor .form .allergies .textAndProfile textarea::-webkit-scrollbar {
  width: 5px;
}
.mainDoctor .form .allergies .textAndProfile textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.mainDoctor .form .allergies .textAndProfile textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
  margin: 10px;
}
.mainDoctor .form .allergies .textAndProfile textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.mainDoctor .form .allergies .textAndProfile textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.mainDoctor .form .allergies .textAndProfile textarea::-webkit-input-placeholder {
  font-weight: 700;
  color: #969696;
}
.mainDoctor .form .allergies .textAndProfile textarea:-ms-input-placeholder {
  font-weight: 700;
  color: #969696;
}
.mainDoctor .form .allergies .textAndProfile textarea::-ms-input-placeholder {
  font-weight: 700;
  color: #969696;
}
.mainDoctor .form .allergies .textAndProfile textarea::placeholder {
  font-weight: 700;
  color: #969696;
}
.mainDoctor .form .allergies .textAndProfile .changeDiv {
  height: 100%;
  color: #969696;
}
.mainDoctor .form label {
  color: #666666;
  text-align: left;
  width: 100%;
}
.mainDoctor .Services {
  width: 100%;
  padding: 30px;
  color: #666666;
}
.mainDoctor .Services .mainEmailVisit {
  display: flex;
  opacity: 0.4;
}
.mainDoctor .Services .mainEmailVisit .emailVisit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 0px 10px 0px;
}
.mainDoctor .Services .mainEmailVisit .emailVisit input[type=number]::-webkit-inner-spin-button,
.mainDoctor .Services .mainEmailVisit .emailVisit input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.mainDoctor .Services .mainEmailVisit .emailVisit input {
  height: 33px;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  background: white;
  margin: 0 0 0 auto;
  text-indent: 15px;
  font-weight: 700;
  width: 85px;
}
.mainDoctor .Services .mainEmailVisit .emailVisit input::-webkit-input-placeholder {
  color: #969696;
}
.mainDoctor .Services .mainEmailVisit .emailVisit input:-ms-input-placeholder {
  color: #969696;
}
.mainDoctor .Services .mainEmailVisit .emailVisit input::-ms-input-placeholder {
  color: #969696;
}
.mainDoctor .Services .mainEmailVisit .emailVisit input::placeholder {
  color: #969696;
}
.mainDoctor .Services .mainEmailVisit .emailVisit label {
  width: 180px;
}
.mainDoctor .Services .mainEmailVisit input[type=checkbox] {
  visibility: hidden;
  position: absolute;
}
.mainDoctor .Services .mainEmailVisit input[type=checkbox] + label:before {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  content: " ";
  display: inline-block;
  vertical-align: baseline;
  border: 1px solid #00aff0;
  border-radius: 50%;
}
.mainDoctor .Services .mainEmailVisit input[type=checkbox]:checked + label:before {
  background: #00aff0;
}
.mainDoctor .Services .mainEmailVisit label {
  display: flex;
  align-items: center;
  margin: 0;
}
.mainDoctor .Services .mainVideoVisit {
  display: flex;
  opacity: 0.4;
}
.mainDoctor .Services .mainVideoVisit .videoVisit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.mainDoctor .Services .mainVideoVisit .videoVisit input[type=number]::-webkit-inner-spin-button,
.mainDoctor .Services .mainVideoVisit .videoVisit input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.mainDoctor .Services .mainVideoVisit .videoVisit input {
  height: 33px;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  background: white;
  margin: 0 0 0 auto;
  text-indent: 15px;
  font-weight: 700;
  width: 85px;
}
.mainDoctor .Services .mainVideoVisit .videoVisit input::-webkit-input-placeholder {
  color: #969696;
}
.mainDoctor .Services .mainVideoVisit .videoVisit input:-ms-input-placeholder {
  color: #969696;
}
.mainDoctor .Services .mainVideoVisit .videoVisit input::-ms-input-placeholder {
  color: #969696;
}
.mainDoctor .Services .mainVideoVisit .videoVisit input::placeholder {
  color: #969696;
}
.mainDoctor .Services .mainVideoVisit .videoVisit label {
  width: 180px;
}
.mainDoctor .Services .mainVideoVisit input[type=checkbox] {
  visibility: hidden;
  position: absolute;
}
.mainDoctor .Services .mainVideoVisit input[type=checkbox] + label:before {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  content: " ";
  display: inline-block;
  vertical-align: baseline;
  border: 1px solid #00aff0;
  border-radius: 50%;
}
.mainDoctor .Services .mainVideoVisit input[type=checkbox]:checked + label:before {
  background: #00aff0;
}
.mainDoctor .Services .mainVideoVisit label {
  display: flex;
  align-items: center;
  margin: 0;
}
.mainDoctor .Services .mainVideoFollowUp {
  display: flex;
  opacity: 0.4;
}
.mainDoctor .Services .mainVideoFollowUp .videoFollowUp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px 10px 0px;
}
.mainDoctor .Services .mainVideoFollowUp .videoFollowUp input[type=number]::-webkit-inner-spin-button,
.mainDoctor .Services .mainVideoFollowUp .videoFollowUp input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.mainDoctor .Services .mainVideoFollowUp .videoFollowUp input {
  height: 33px;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  background: white;
  margin: 0 0 0 auto;
  text-indent: 15px;
  font-weight: 700;
  width: 85px;
}
.mainDoctor .Services .mainVideoFollowUp .videoFollowUp input::-webkit-input-placeholder {
  color: #969696;
}
.mainDoctor .Services .mainVideoFollowUp .videoFollowUp input:-ms-input-placeholder {
  color: #969696;
}
.mainDoctor .Services .mainVideoFollowUp .videoFollowUp input::-ms-input-placeholder {
  color: #969696;
}
.mainDoctor .Services .mainVideoFollowUp .videoFollowUp input::placeholder {
  color: #969696;
}
.mainDoctor .Services .mainVideoFollowUp .videoFollowUp label {
  width: 180px;
}
.mainDoctor .Services .mainVideoFollowUp input[type=checkbox] {
  visibility: hidden;
  position: absolute;
}
.mainDoctor .Services .mainVideoFollowUp input[type=checkbox] + label:before {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  content: " ";
  display: inline-block;
  vertical-align: baseline;
  border: 1px solid #00aff0;
  border-radius: 50%;
}
.mainDoctor .Services .mainVideoFollowUp input[type=checkbox]:checked + label:before {
  background: #00aff0;
}
.mainDoctor .Services .mainVideoFollowUp label {
  display: flex;
  align-items: center;
  margin: 0;
}
.mainDoctor .Services .mainWaitingRoomVisit {
  display: flex;
  opacity: 0.4;
}
.mainDoctor .Services .mainWaitingRoomVisit .WaitingRoomVisit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px;
}
.mainDoctor .Services .mainWaitingRoomVisit .WaitingRoomVisit input[type=number]::-webkit-inner-spin-button,
.mainDoctor .Services .mainWaitingRoomVisit .WaitingRoomVisit input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.mainDoctor .Services .mainWaitingRoomVisit .WaitingRoomVisit input {
  height: 33px;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  background: white;
  margin: 0 0 0 auto;
  text-indent: 15px;
  font-weight: 700;
  width: 85px;
}
.mainDoctor .Services .mainWaitingRoomVisit .WaitingRoomVisit input::-webkit-input-placeholder {
  color: #969696;
}
.mainDoctor .Services .mainWaitingRoomVisit .WaitingRoomVisit input:-ms-input-placeholder {
  color: #969696;
}
.mainDoctor .Services .mainWaitingRoomVisit .WaitingRoomVisit input::-ms-input-placeholder {
  color: #969696;
}
.mainDoctor .Services .mainWaitingRoomVisit .WaitingRoomVisit input::placeholder {
  color: #969696;
}
.mainDoctor .Services .mainWaitingRoomVisit .WaitingRoomVisit label {
  width: 180px;
}
.mainDoctor .Services .mainWaitingRoomVisit input[type=checkbox] {
  visibility: hidden;
  position: absolute;
}
.mainDoctor .Services .mainWaitingRoomVisit input[type=checkbox] + label:before {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  content: " ";
  display: inline-block;
  vertical-align: baseline;
  border: 1px solid #00aff0;
  border-radius: 50%;
}
.mainDoctor .Services .mainWaitingRoomVisit input[type=checkbox]:checked + label:before {
  background: #00aff0;
}
.mainDoctor .Services .mainWaitingRoomVisit label {
  display: flex;
  align-items: center;
  margin: 0;
}
.mainDoctor .Services .saveChanges {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00aff0;
  width: 80px;
  height: 30px;
  color: white;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  margin: 45px auto 0 0px !important;
}
.mainDoctor .mainWorkHours {
  width: 100%;
  padding: 30px;
}
.mainDoctor .mainWorkHours .workHoursDiv {
  display: flex;
  justify-content: flex-start;
  margin: 0 0 20px 0;
  height: 33px;
  color: #666666;
}
.mainDoctor .mainWorkHours .workHoursDiv p {
  display: flex;
  margin: 0 10px 0 10px;
  font-size: 29px;
  align-items: center;
}
.mainDoctor .mainWorkHours .workHoursDiv input {
  margin: 0;
  font-size: 14px;
  text-align: center;
  width: 90px !important;
  text-indent: 0;
  height: 33px;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  background: white;
  font-weight: 500;
}
.mainDoctor .mainWorkHours .workHoursDiv input::-webkit-input-placeholder {
  color: #969696;
  text-align: center;
}
.mainDoctor .mainWorkHours .workHoursDiv input:-ms-input-placeholder {
  color: #969696;
  text-align: center;
}
.mainDoctor .mainWorkHours .workHoursDiv input::-ms-input-placeholder {
  color: #969696;
  text-align: center;
}
.mainDoctor .mainWorkHours .workHoursDiv input::placeholder {
  color: #969696;
  text-align: center;
}
.mainDoctor .mainWorkHours .workHoursDiv input .react-time-picker__wrapper {
  border: none;
}
.mainDoctor .mainWorkHours .workHoursDiv img {
  width: 20px;
  height: 20px;
  margin: 6px 0 0 10px;
}
.mainDoctor .mainWorkHours img {
  width: 28px;
  height: 28px;
  display: flex;
  margin: 20px auto 0 0;
}
.mainDoctor .mainWorkHours img:hover {
  cursor: pointer;
}
.mainDoctor .mainWorkHours .saveChanges {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00aff0;
  width: 80px;
  height: 30px;
  color: white;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  margin: 45px auto 0 0px !important;
}
.mainClientic {
  display: flex;
  width: 40% !important;
  margin: 110px auto 20px 360px;
  background: #f2f2f2;
  border-radius: 15px;
  position: relative;
}
.mainClientic .newVideo {
  position: absolute;
  top: -76px;
  z-index: 20;
  height: 35px;
  display: flex;
  border-bottom: 4px solid #fa9551;
  margin-right: 45px;
}
.mainClientic .newVideo h4 {
  font-weight: bold;
  font-size: 15px;
  color: #2cace3;
}
.mainClientic .newVideo:hover {
  cursor: pointer;
}
.mainClientic .client {
  width: 50%;
  padding: 30px 0px 30px 30px;
}
.mainClientic .client .client-p {
  display: flex;
  flex-direction: column;
}
.mainClientic .client .client-p input[type=number]::-webkit-inner-spin-button,
.mainClientic .client .client-p input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.mainClientic .client .client-p input {
  height: 33px;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  background: white;
  margin: 2px 0 20px 0;
  text-indent: 15px;
  font-weight: 700;
  width: 90%;
}
.mainClientic .client .client-p input::-webkit-input-placeholder {
  font-weight: 700;
  color: #969696;
}
.mainClientic .client .client-p input:-ms-input-placeholder {
  font-weight: 700;
  color: #969696;
}
.mainClientic .client .client-p input::-ms-input-placeholder {
  font-weight: 700;
  color: #969696;
}
.mainClientic .client .client-p input::placeholder {
  font-weight: 700;
  color: #969696;
}
.mainClientic .client .client-p label {
  color: #666666;
  text-align: left;
}
.mainClientic .client .client-p .saveChanges {
  background: #00aff0;
  width: 80px;
  height: 30px;
  color: white;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  margin: 25px 0 0 0;
}
.mainClientic .form {
  padding: 30px 30px 30px 0px !important;
  width: 50%;
}
.mainClientic .form .picturelabel {
  font-size: 14px;
  font-weight: bold;
  text-align: start;
  color: #666666;
  margin: 0 0 5px 0;
}
.mainClientic .form .conditions {
  display: flex;
  flex-direction: column;
  text-align: start;
  font-weight: 700;
  color: #666666;
}
.mainClientic .form .conditions textarea {
  height: 115px;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  padding: 10px 15px;
  background: white;
  margin: 5px 0 10px 0;
  font-weight: 700;
  resize: none;
}
.mainClientic .form .conditions textarea::-webkit-scrollbar {
  width: 5px;
}
.mainClientic .form .conditions textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.mainClientic .form .conditions textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.mainClientic .form .conditions textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.mainClientic .form .conditions textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.mainClientic .form .conditions textarea::-webkit-input-placeholder {
  font-weight: 700;
  color: #969696;
}
.mainClientic .form .conditions textarea:-ms-input-placeholder {
  font-weight: 700;
  color: #969696;
}
.mainClientic .form .conditions textarea::-ms-input-placeholder {
  font-weight: 700;
  color: #969696;
}
.mainClientic .form .conditions textarea::placeholder {
  font-weight: 700;
  color: #969696;
}
.mainClientic .form .allergies {
  display: flex;
  flex-direction: column;
  text-align: start;
  font-weight: 700;
  color: #666666;
  margin: 10px 0 0 0;
}
.mainClientic .form .allergies textarea {
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  background: white;
  margin: 5px 0 20px 0;
  padding: 10px 15px;
  font-weight: 700;
  height: 115px !important;
  resize: none;
}
.mainClientic .form .allergies textarea::-webkit-scrollbar {
  width: 5px;
}
.mainClientic .form .allergies textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.mainClientic .form .allergies textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.mainClientic .form .allergies textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.mainClientic .form .allergies textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.mainClientic .form .allergies textarea::-webkit-input-placeholder {
  font-weight: 700;
  color: #969696;
}
.mainClientic .form .allergies textarea:-ms-input-placeholder {
  font-weight: 700;
  color: #969696;
}
.mainClientic .form .allergies textarea::-ms-input-placeholder {
  font-weight: 700;
  color: #969696;
}
.mainClientic .form .allergies textarea::placeholder {
  font-weight: 700;
  color: #969696;
}
.mainClientic .form .checkboxDiv {
  display: flex;
  justify-content: space-between;
}
.mainClientic .form .checkboxDiv .check {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.mainClientic .form .checkboxDiv .check .Male {
  margin: 0 17px 10px 0;
}
.mainClientic .form .checkboxDiv .check label {
  margin: 0;
  height: 20px;
  display: flex;
  align-items: center;
}
.mainClientic .form .checkboxDiv .check input[type=checkbox] {
  visibility: hidden;
  position: absolute;
}
.mainClientic .form .checkboxDiv .check input[type=checkbox] + label:before {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  content: " ";
  display: inline-block;
  vertical-align: baseline;
  border: 1px solid #00aff0;
  border-radius: 50%;
}
.mainClientic .form .checkboxDiv .check input[type=checkbox]:checked + label:before {
  background: #00aff0;
}
.mainClientic .form .checkboxDiv .profilePic {
  display: flex;
  align-items: flex-start;
  width: 110px;
  border: 10px;
  height: 137px;
  border-radius: 10px;
}
.mainClientic .form .checkboxDiv .profilePic .cliImage {
  width: 110px;
  height: 110px;
  padding: 1px;
  border-radius: 10px;
  object-fit: cover;
}
.mainClientic .form .checkboxDiv .profilePic .cliImage:hover {
  cursor: pointer;
}
.mainClientic .form .checkboxDiv .profilePic .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
}
.mainClientic .form .checkboxDiv .profilePic .upload-btn-wrapper .btn {
  margin: 0;
  width: 110px;
  height: 110px;
  padding: 0;
  border-radius: 10px;
  background: transparent !important;
}
.mainClientic .form .checkboxDiv .profilePic .upload-btn-wrapper .btn .docImage {
  width: 107px;
  height: 107px;
  border-radius: 10px;
  object-fit: cover;
}
.mainClientic .form .checkboxDiv .profilePic .upload-btn-wrapper .btn p {
  font-size: 80px;
  padding: 0;
}
.mainClientic .form .checkboxDiv .profilePic .upload-btn-wrapper .deleteImage {
  position: absolute;
  width: 20px;
  height: 20px;
  background: red;
  color: white;
  border: none;
  border-radius: 5px;
  z-index: 1000;
  top: 5px;
  left: 85px;
}
.mainClientic .form .checkboxDiv .profilePic .upload-btn-wrapper input[type=file] {
  font-size: 0px;
  position: absolute;
  left: 0;
  top: -20px;
  width: 110px;
  height: 140px;
  opacity: 0;
  cursor: pointer;
}
.mainClienn {
  display: flex;
  flex-direction: column;
  width: 40% !important;
  margin: 110px 120px 40px 360px;
  background: #f2f2f2;
  padding: 30px;
  border-radius: 15px;
  position: relative;
}
.mainClienn .newVideo {
  position: absolute;
  top: -76px;
  z-index: 20;
  height: 35px;
  display: flex;
  margin: 0 0 0 -30px;
}
.mainClienn .newVideo .profileDoc {
  margin-right: 45px;
}
.mainClienn .newVideo .profileDoc h4 {
  font-size: 15px;
  color: #2cace3;
}
.mainClienn .newVideo .profileDoc:hover {
  cursor: pointer;
}
.mainClienn .client {
  width: 100%;
  padding: 20px 20px 20px 20px;
  border: 1.7px solid #fa9551;
  border-radius: 15px;
  height: 250px;
  display: flex;
  margin: 0 0 20px 0 !important;
  background: white;
  justify-content: flex-start;
}
.mainClienn .client img {
  width: 110px;
  height: 110px;
  border-radius: 10px;
  object-fit: cover;
}
.mainClienn .client .client-p {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0 0 0 20px;
}
.mainClienn .client .client-p p {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
}
.mainClienn .client .client-p h5 {
  font-weight: 900;
  color: #666666;
  margin: 0 0 20px 0;
}
.mainClienn .client .client-p h5 span {
  font-weight: 400;
}
.mainClienn .form {
  padding: 0px !important;
  margin: 0;
  height: 300px;
  width: 100%;
  color: #666666;
}
.mainClienn .form .conditionss {
  display: flex;
  flex-direction: column;
  text-align: start;
  height: 140px;
  border: 1.7px solid #fa9551;
  border-radius: 15px;
  font-weight: 900;
  color: #666666;
  margin: 0 0 20px 0;
  padding: 10px;
  background: white;
}
.mainClienn .form .conditionss span {
  font-size: 16px;
}
.mainClienn .form .conditionss textarea {
  background: transparent;
  border: none;
  font-weight: 400;
  resize: none;
  min-height: 100px;
}
.mainClienn .form .conditionss textarea::-webkit-scrollbar {
  width: 5px;
}
.mainClienn .form .conditionss textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.mainClienn .form .conditionss textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.mainClienn .form .conditionss textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.mainClienn .form .conditionss textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.mainClienn .form .allergiess {
  display: flex;
  flex-direction: column;
  text-align: start;
  font-weight: 900;
  border: 1.7px solid #fa9551;
  border-radius: 15px;
  height: 140px;
  color: #666666;
  margin: 20px 0;
  padding: 10px;
  background: white;
}
.mainClienn .form .allergiess span {
  font-size: 16px;
}
.mainClienn .form .allergiess textarea {
  background: transparent;
  border: none;
  font-weight: 400;
  resize: none;
  min-height: 100px;
}
.mainClienn .form .allergiess textarea::-webkit-scrollbar {
  width: 5px;
}
.mainClienn .form .allergiess textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.mainClienn .form .allergiess textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.mainClienn .form .allergiess textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.mainClienn .form .allergiess textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.mainClienn .consAndPagi {
  width: 780px;
  background: white;
}
.mainClienn .consAndPagi .mainTabelRecord {
  border-radius: 15px;
  overflow: hidden;
  width: 780px !important;
  margin: 0px 120px 0px 0px;
  background: #f2f2f2;
}
.mainClienn .consAndPagi .mainTabelRecord .NoResultDiv {
  color: #666666;
  margin: 20px auto;
  font-size: 20px;
}
.mainClienn .consAndPagi .mainTabelRecord .mainConsultation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fa9551;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 40px;
}
.mainClienn .consAndPagi .mainTabelRecord .mainConsultation .icon_left span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 3px 10px;
  align-self: center;
  text-align: center;
}
.mainClienn .consAndPagi .mainTabelRecord .mainConsultation .icon_left span .icon1 {
  align-self: center;
  color: white;
  font-size: 22px;
  margin-top: 3px;
}
.mainClienn .consAndPagi .mainTabelRecord .mainConsultation .icon_left p {
  align-self: center;
  margin: 0 0 0 30px;
  color: white;
}
.mainClienn .consAndPagi .mainTabelRecord .mainConsultation .sort {
  margin: 0 10px;
  display: flex;
}
.mainClienn .consAndPagi .mainTabelRecord .mainConsultation .sort p {
  align-self: center;
  margin: 0 50px 0 0;
  text-overflow: bold;
  color: white;
}
.mainClienn .consAndPagi .mainTabelRecord .mainConsultation .sort p:hover {
  cursor: pointer;
}
.mainClienn .consAndPagi .mainTabelRecord .mainConsultation .sort .all {
  margin: 0 20px 0 0;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  padding: 10px;
  color: #666666;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 tbody {
  background: #f2f2f2;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 tbody tr {
  height: 40px;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 tbody tr:hover {
  background: #e6e6e6;
  cursor: pointer;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 tbody tr td {
  text-align: left;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 tbody tr td .finished {
  color: #00aff0;
  font-size: 17px;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 tbody tr .client-status {
  text-align: center;
  width: 100px;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 tbody tr .client-status:hover .check,
.mainClienn .consAndPagi .mainTabelRecord .table2 tbody tr .client-status:hover .pendi,
.mainClienn .consAndPagi .mainTabelRecord .table2 tbody tr .client-status:hover .finished,
.mainClienn .consAndPagi .mainTabelRecord .table2 tbody tr .client-status:hover .declined {
  display: none;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 tbody tr .client-status:hover .status {
  display: block;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 tbody tr .status {
  display: none;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 tbody tr .client-type {
  width: 120px !important;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 tbody tr .created {
  width: 120px;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 tbody tr .client-subject {
  width: 300px;
  padding: 0 0 0 30px;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 .client-head {
  text-align: center;
  color: #666666;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 .client-head .client-row {
  background: #b3b3b3;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 .client-head .client-row .client-subject {
  display: flex;
  margin: 0 auto 0 30px;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 .client-head .client-row .client-type {
  height: 40px;
  padding: 0;
  width: 120px;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 .client-head .client-row .client-type .mainExamDiv {
  margin: 0 0 0 -5px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 120px;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 .client-head .client-row .client-type .mainExamDiv .searchIcon {
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 30px;
  height: 100%;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 .client-head .client-row .client-type .mainExamDiv .searchIcon:hover {
  cursor: pointer;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 .client-head .client-row .client-type .mainExamDiv select {
  width: 80px;
  height: 25px;
  border: none;
  border-radius: 5px;
  background: #b3b3b3;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 .client-head .client-row .client-type .mainExamDiv select::-webkit-input-placeholder {
  color: #666666;
  font-size: 10px;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 .client-head .client-row .client-type .mainExamDiv select:-ms-input-placeholder {
  color: #666666;
  font-size: 10px;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 .client-head .client-row .client-type .mainExamDiv select::-ms-input-placeholder {
  color: #666666;
  font-size: 10px;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 .client-head .client-row .client-type .mainExamDiv select::placeholder {
  color: #666666;
  font-size: 10px;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 .client-head .client-row .client-type .mainExamDiv select option {
  background: white;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 .client-head .client-row .client-date {
  display: flex;
  margin: 0;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  width: 120px;
}
.mainClienn .consAndPagi .mainTabelRecord .table2 .client-head .client-row .client-status {
  text-align: center;
  margin: 0 auto;
  height: 40px;
}
.mainClienn .pagi {
  display: flex;
  width: 780px;
  margin: 20px auto 0px 0px;
  justify-content: center;
}
.mainClienn .pagi ul {
  margin: 20px auto;
}
.mainClienn .pagi ul li a {
  color: #00aff0;
  border: none;
}
.mainClienn .pagi ul .lastPage {
  display: none;
}
.mainClienn .pagi ul .active a {
  background: #00aff0;
  color: white;
}
.mainClienn .pagi ul .disabled a {
  color: #00aff0;
}
.exam1 {
  width: calc(100% - 480px) !important;
  margin: 110px auto 20px 360px !important;
}
.exam1 .mainExam {
  padding: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #f2f2f2;
  border-radius: 15px;
  position: relative;
}
.exam1 .mainExam .newVideo {
  position: absolute;
  top: -76px;
  left: 0px;
  z-index: 20;
  height: 35px;
  display: flex;
  border-bottom: 4px solid #fa9551;
  margin-right: 45px;
}
.exam1 .mainExam .newVideo h4 {
  font-weight: bold;
  font-size: 15px;
  color: #2cace3;
}
.exam1 .mainExam .newVideo:hover {
  cursor: pointer;
}
.exam1 .mainExam .exam-spec {
  margin-right: 10px;
  width: 100%;
}
.exam1 .mainExam .exam-doc {
  margin-right: 10px;
  width: 100%;
}
.exam1 .mainExam .exam-sub {
  width: 100%;
}
.exam1 .mainExam .exam-sub .form-control {
  height: 33px !important;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  width: 100%;
  font-weight: 600;
  background: white;
  color: #666666;
}
.exam1 .DatePicker {
  width: 100%;
  height: 450px;
  margin: 20px auto 20px auto;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  background: #f2f2f2;
  border-radius: 15px;
  position: relative;
}
.exam1 .DatePicker .exam-mess {
  border-radius: 15px;
  height: 347px;
  padding: 10px;
  width: 100%;
  margin: 0;
  color: #666666;
  background: white;
  border: 1.7px solid #00aff0 !important;
}
.exam1 .DatePicker .exam-mess textarea {
  border: none;
  height: 280px;
  margin: 0;
  width: 100%;
  resize: none;
}
.exam1 .DatePicker .exam-mess textarea ::-webkit-input-placeholder {
  font-weight: 500;
  color: #969696;
}
.exam1 .DatePicker .exam-mess textarea :-ms-input-placeholder {
  font-weight: 500;
  color: #969696;
}
.exam1 .DatePicker .exam-mess textarea ::-ms-input-placeholder {
  font-weight: 500;
  color: #969696;
}
.exam1 .DatePicker .exam-mess textarea ::placeholder {
  font-weight: 500;
  color: #969696;
}
.exam1 .DatePicker .exam-mess textarea::-webkit-scrollbar {
  width: 5px;
}
.exam1 .DatePicker .exam-mess textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.exam1 .DatePicker .exam-mess textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.exam1 .DatePicker .exam-mess textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.exam1 .DatePicker .exam-mess textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.exam1 .DatePicker .exam-mess .mainDivForFile {
  display: flex;
  padding-top: 4px;
  border-top: 1px solid #f2f2f2;
  position: relative;
}
.exam1 .DatePicker .exam-mess .mainDivForFile .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.exam1 .DatePicker .exam-mess .mainDivForFile .upload-btn-wrapper .btnn {
  background: white;
  border: 1.7px solid #00aff0;
  height: 30px;
  color: #00aff0;
  font-weight: bold;
  padding: 0 5px;
  width: 70px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.exam1 .DatePicker .exam-mess .mainDivForFile .upload-btn-wrapper .btnn .attachDiv {
  align-items: center;
}
.exam1 .DatePicker .exam-mess .mainDivForFile .upload-btn-wrapper .btnn .attachDiv p {
  margin: 0;
}
.exam1 .DatePicker .exam-mess .mainDivForFile .upload-btn-wrapper input[type=file] {
  font-size: 0px;
  position: absolute;
  background: #00aff0;
  left: 0;
  top: 0;
  width: 70px;
  height: 40px;
  opacity: 0;
  cursor: pointer;
}
.exam1 .DatePicker .exam-mess .mainDivForFile .fileForDownload {
  left: 120px;
  border-radius: 5px;
  color: #00aff0;
  padding: 0 5px;
  height: 30px;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 0 0 0 10px;
  white-space: nowrap;
}
.exam1 .DatePicker .exam-mess .mainDivForFile .fileForDownload p {
  white-space: nowrap;
  max-width: 100px;
  margin: 0 2px 0 0;
  color: #00aff0;
  text-indent: 0;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.exam1 .DatePicker .exam-mess .mainDivForFile .fileForDownload p:hover {
  text-overflow: scroll;
  max-width: 250px;
}
.exam1 .DatePicker .exam-mess .mainDivForFile .fileForDownload .deleteB {
  color: gray;
  width: 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  border: none;
}
.exam1 .DatePicker .exam-mess .mainDivForFile .fileForDownload .deleteB:hover {
  cursor: pointer;
}
.exam1 .DatePicker .MainDate {
  position: relative;
  border-radius: 15px;
  height: 347px;
  width: 550px;
  display: flex;
  justify-content: flex-end;
  margin-left: 25px;
}
.exam1 .DatePicker .MainDate p {
  position: absolute;
  color: #fa6767;
  font-weight: bold;
  font-size: 15px;
  bottom: -70px;
}
.exam1 .DatePicker .MainDate .disabledDiv {
  width: 100%;
  height: 98%;
  position: absolute;
  z-index: 1;
}
.exam1 .DatePicker .MainDate div {
  width: 100%;
}
.exam1 .DatePicker .MainDate div .react-datepicker {
  border-radius: 15px;
  border: 1.7px solid #00aff0;
  margin-right: 125px;
  width: 425px;
  height: 347px;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__navigation {
  width: 10px;
  height: 10px;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__navigation--previous {
  position: absolute;
  left: 60px;
  top: 22px;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__navigation--next {
  position: absolute;
  right: 60px;
  top: 22px;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__month-container .react-datepicker__header {
  background: transparent;
  padding: 0;
  width: 360px;
  margin: 0 auto;
  border-bottom: 1.5px solid #00aff0;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__current-month {
  font-size: 20px;
  margin: 10px 0;
  color: #666666;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__month-container .react-datepicker__header .react-datepicker__day-names {
  margin: 0 auto 3px auto;
  color: #666666;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__month-container .react-datepicker__month {
  margin: 0 auto 0 auto;
  width: 360px;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 45px;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day {
  font-size: 18px;
  text-align: center;
  color: #00aff0;
  font-weight: 700;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover {
  height: 30px;
  padding: 7px 0 0 0;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--selected {
  margin: 0;
  padding: 7px 0 0 0;
  height: 30px;
  background: #fa9551;
  color: white;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--disabled,
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day--outside-month {
  color: #b3b2b2;
  font-weight: 500;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__time-container {
  position: absolute;
  right: -127px;
  top: -2px;
  height: 347px;
  width: 100px;
  margin: 0;
  padding: 0;
  border: 1.7px solid #00aff0;
  border-radius: 15px;
  background: white;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__time-container .react-datepicker__header--time {
  height: 40px;
  background: white;
  border-radius: 20px;
  border-bottom: none;
  width: 90%;
  padding: 10px 0;
  margin: 0 auto;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__time-container .react-datepicker__header--time .react-datepicker-time__header {
  font-size: 20px;
  color: #666666;
  text-transform: capitalize;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__time-container .react-datepicker__time {
  font-size: 12px;
  margin: 10px 0 0 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  height: 85%;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list {
  overflow-y: hidden;
  height: 270px !important;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  width: 75px;
  padding: 6px 6px 0 6px;
  color: #00aff0;
  font-weight: 600;
  overflow-y: scroll;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list::-webkit-scrollbar {
  width: 5px;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
  margin-left: 10px !important;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.exam1 .DatePicker .MainDate div .react-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item--selected {
  background: #00aff0;
  color: white;
  border-radius: 10px;
}
.exam1 .DatePicker .divAndAttach {
  background: #f2f2f2;
  width: 180px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  position: absolute;
  bottom: 30px;
  left: 10px;
}
.exam1 .DatePicker .divAndAttach .send {
  margin: 0px 20px 0 20px;
  width: 80px;
  height: 30px;
  border: none;
  border-radius: 5px;
  color: white;
  background: #00aff0;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  outline: none;
}
.exam1 #file {
  width: 60%;
  margin: 10px auto 0 auto;
}

#subject {
  height: 38px;
  font-weight: 500;
}
.detail-exam {
  background: #f2f2f2;
  position: relative;
  padding: 30px;
  border-radius: 15px;
  text-align: left;
  margin: 110px auto 20px 360px;
  width: calc(100% - 480px);
  min-height: 350px;
  height: 100% !important;
  display: flex;
}
.detail-exam .iconVideo {
  position: absolute;
  top: -76px;
  left: 0;
  z-index: 20;
  height: 35px;
  display: flex;
  border-bottom: 4px solid #fa9551;
  margin-right: 45px;
}
.detail-exam .iconVideo h4 {
  font-weight: bold;
  font-size: 15px;
  color: #2cace3;
}
.detail-exam .iconVideo:hover {
  cursor: pointer;
}
.detail-exam .detail {
  padding: 3px 10px 5px 0px;
  position: relative;
  width: 300px;
}
.detail-exam .detail .divSelectButton {
  display: flex;
}
.detail-exam .detail .divSelectButton .select-option {
  width: 200px;
  margin: 0;
}
.detail-exam .detail .divSelectButton .btnSend {
  margin: 0 10px;
  border: 1px solid #377da5;
  background: #4092c2;
  color: white;
  padding: 0 5px;
  border-radius: 5px;
}
.detail-exam .detail p {
  color: #666666;
  border: none;
}
.detail-exam .detail p span {
  font-weight: 900;
}
.detail-exam .detail .col-sm-10 {
  position: relative;
  height: 50px;
}
.detail-exam .detail .col-sm-10 .btn {
  position: relative;
  border: 1px solid #4092c2;
  height: 37px;
  left: 120px;
  top: -37px;
}
.detail-exam .mainMessageDiv {
  width: 100%;
}
.detail-exam .mainMessageDiv .subjectDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fa9551;
  color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 30px;
}
.detail-exam .mainMessageDiv .subjectDiv p {
  margin: 0px 15px 0 15px;
}
.detail-exam .mainMessageDiv .subjectDiv p span {
  margin-right: 5px;
}
.detail-exam .mainMessageDiv .subjectDiv .infoAndSquare {
  align-items: center;
}
.detail-exam .mainMessageDiv .subjectDiv .infoAndSquare #imageDiv1 {
  background: transparent;
  border: 1.7px solid white;
  width: 20px;
  border-radius: 5px;
  height: 20px;
  margin: 0px 20px 0 0;
  display: none;
}
.detail-exam .mainMessageDiv .subjectDiv .infoAndSquare #imageDiv1:hover {
  cursor: pointer;
}
.detail-exam .mainMessageDiv .messageDiv {
  min-height: 160px;
  width: 100%;
  padding: 10px 15px !important;
  background: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.detail-exam .mainMessageDiv .messageDiv textarea {
  resize: none;
  width: 100%;
  min-height: 100px;
  border: none;
  background: white;
}
.detail-exam .mainMessageDiv .messageDiv textarea::-webkit-scrollbar {
  width: 5px;
}
.detail-exam .mainMessageDiv .messageDiv textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.detail-exam .mainMessageDiv .messageDiv textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.detail-exam .mainMessageDiv .messageDiv textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.detail-exam .mainMessageDiv .messageDiv textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.detail-exam .mainMessageDiv .messageDiv .mainFileDiv {
  border-top: 1px solid #f2f2f2;
  height: 30px;
  display: flex;
}
.detail-exam .mainMessageDiv .messageDiv .mainFileDiv .FileDiv {
  height: 40px;
}
.detail-exam .mainMessageDiv .messageDiv .mainFileDiv .FileDiv p {
  color: #00aff0;
  font-weight: bold;
  width: 50px;
  height: 25px;
  border-radius: 5px;
  text-align: start;
  align-items: center;
  margin: 5px 0px 0 0;
}
.detail-exam .mainMessageDiv .messageDiv .mainFileDiv .fileForDownload {
  display: flex;
  color: #00aff0;
  border-radius: 5px;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  height: 25px;
  margin: 2px 0 0 0;
  padding: 0 5px;
  overflow: hidden;
  white-space: nowrap;
}
.detail-exam .mainMessageDiv .messageDiv .mainFileDiv .fileForDownload p {
  white-space: nowrap;
  width: 100px;
  margin: 0;
  color: #00aff0;
  text-indent: 0;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail-exam .mainMessageDiv .messageDiv .mainFileDiv .fileForDownload p:hover {
  text-overflow: scroll;
  width: 350px;
}
.detail-exam .mainMessageDiv .messageDiv .mainFileDiv .fileForDownload:hover {
  cursor: pointer;
}
.detail-exam .mainMessageDiv .reportIfDeclined,
.detail-exam .mainMessageDiv .reportIfFinished {
  margin: 20px 0 0 0;
}
.detail-exam .mainMessageDiv .reportIfDeclined .subjectDiv,
.detail-exam .mainMessageDiv .reportIfFinished .subjectDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e6e6e6;
  color: #666666;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 30px;
}
.detail-exam .mainMessageDiv .reportIfDeclined .subjectDiv p,
.detail-exam .mainMessageDiv .reportIfFinished .subjectDiv p {
  margin: 0px 15px 0 15px;
}
.detail-exam .mainMessageDiv .reportIfDeclined .subjectDiv p span,
.detail-exam .mainMessageDiv .reportIfFinished .subjectDiv p span {
  margin-right: 5px;
}
.detail-exam .mainMessageDiv .reportIfDeclined .subjectDiv #imageDiv2,
.detail-exam .mainMessageDiv .reportIfDeclined .subjectDiv #imageDiv3,
.detail-exam .mainMessageDiv .reportIfFinished .subjectDiv #imageDiv2,
.detail-exam .mainMessageDiv .reportIfFinished .subjectDiv #imageDiv3 {
  background: transparent;
  border: 1.7px solid white;
  width: 20px;
  border-radius: 5px;
  height: 20px;
  margin: 0px 20px 0 0;
}
.detail-exam .mainMessageDiv .reportIfDeclined .subjectDiv #imageDiv2:hover,
.detail-exam .mainMessageDiv .reportIfDeclined .subjectDiv #imageDiv3:hover,
.detail-exam .mainMessageDiv .reportIfFinished .subjectDiv #imageDiv2:hover,
.detail-exam .mainMessageDiv .reportIfFinished .subjectDiv #imageDiv3:hover {
  cursor: pointer;
}
.detail-exam .mainMessageDiv .reportIfDeclined .messageDivReport,
.detail-exam .mainMessageDiv .reportIfFinished .messageDivReport {
  width: 100%;
  height: 72%;
  background: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.detail-exam .mainMessageDiv .reportIfDeclined .messageDivReport textarea,
.detail-exam .mainMessageDiv .reportIfFinished .messageDivReport textarea {
  width: 100%;
  min-height: 150px;
  padding: 10px;
  text-indent: 5px;
  border: none;
  background: white;
  resize: none;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.detail-exam .mainMessageDiv .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar,
.detail-exam .mainMessageDiv .reportIfFinished .messageDivReport textarea::-webkit-scrollbar {
  width: 5px;
}
.detail-exam .mainMessageDiv .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar-button:single-button,
.detail-exam .mainMessageDiv .reportIfFinished .messageDivReport textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.detail-exam .mainMessageDiv .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar-track,
.detail-exam .mainMessageDiv .reportIfFinished .messageDivReport textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.detail-exam .mainMessageDiv .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar-thumb,
.detail-exam .mainMessageDiv .reportIfFinished .messageDivReport textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.detail-exam .mainMessageDiv .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar-thumb:hover,
.detail-exam .mainMessageDiv .reportIfFinished .messageDivReport textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.detail-exam .mainMessageDiv .reportIfDeclined .messageDivReport .mainFileDiv,
.detail-exam .mainMessageDiv .reportIfFinished .messageDivReport .mainFileDiv {
  border-top: 1px solid #f2f2f2;
  height: 30px;
  display: flex;
  padding: 0 15px;
}
.detail-exam .mainMessageDiv .reportIfDeclined .messageDivReport .mainFileDiv .FileDiv,
.detail-exam .mainMessageDiv .reportIfFinished .messageDivReport .mainFileDiv .FileDiv {
  height: 40px;
}
.detail-exam .mainMessageDiv .reportIfDeclined .messageDivReport .mainFileDiv .FileDiv p,
.detail-exam .mainMessageDiv .reportIfFinished .messageDivReport .mainFileDiv .FileDiv p {
  color: #00aff0;
  font-weight: bold;
  width: 50px;
  height: 25px;
  border-radius: 5px;
  text-align: start;
  align-items: center;
  margin: 5px 0px 0 0;
}
.detail-exam .mainMessageDiv .reportIfDeclined .messageDivReport .mainFileDiv .fileForDownload,
.detail-exam .mainMessageDiv .reportIfFinished .messageDivReport .mainFileDiv .fileForDownload {
  display: flex;
  color: #00aff0;
  border-radius: 5px;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  height: 25px;
  margin: 2px 0 0 0;
  padding: 0 5px;
  overflow: hidden;
  white-space: nowrap;
}
.detail-exam .mainMessageDiv .reportIfDeclined .messageDivReport .mainFileDiv .fileForDownload p,
.detail-exam .mainMessageDiv .reportIfFinished .messageDivReport .mainFileDiv .fileForDownload p {
  white-space: nowrap;
  width: 100px;
  margin: 0;
  color: #00aff0;
  text-indent: 0;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail-exam .mainMessageDiv .reportIfDeclined .messageDivReport .mainFileDiv .fileForDownload p:hover,
.detail-exam .mainMessageDiv .reportIfFinished .messageDivReport .mainFileDiv .fileForDownload p:hover {
  text-overflow: scroll;
  width: 350px;
}
.detail-exam .mainMessageDiv .reportIfDeclined .messageDivReport .mainFileDiv .fileForDownload:hover,
.detail-exam .mainMessageDiv .reportIfFinished .messageDivReport .mainFileDiv .fileForDownload:hover {
  cursor: pointer;
}
.detail-exam .mainMessageDiv .reportIfDeclined .messageDivReport button,
.detail-exam .mainMessageDiv .reportIfFinished .messageDivReport button {
  color: white;
  background: #00aff0;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 600;
  margin: 20px auto 20px 0;
}
.detail-exam .sideMessageDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.detail-exam .sideMessageDetails .SubjectMessage .subjectDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fa9551;
  color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 30px;
}
.detail-exam .sideMessageDetails .SubjectMessage .subjectDiv p {
  margin: 0px 15px 0 15px;
}
.detail-exam .sideMessageDetails .SubjectMessage .subjectDiv p span {
  margin-right: 5px;
}
.detail-exam .sideMessageDetails .SubjectMessage .subjectDiv .infoAndSquare {
  align-items: center;
}
.detail-exam .sideMessageDetails .SubjectMessage .subjectDiv .infoAndSquare #imageDiv1 {
  background: transparent;
  border: 1.7px solid white;
  width: 20px;
  border-radius: 5px;
  height: 20px;
  margin: 0px 20px 0 0;
  display: none;
}
.detail-exam .sideMessageDetails .SubjectMessage .subjectDiv .infoAndSquare #imageDiv1:hover {
  cursor: pointer;
}
.detail-exam .sideMessageDetails .SubjectMessage .messageDiv {
  min-height: 160px;
  background: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 10px 15px !important;
}
.detail-exam .sideMessageDetails .SubjectMessage .messageDiv textarea {
  width: 100%;
  min-height: 100px !important;
  background: white !important;
  border: none;
  resize: none;
}
.detail-exam .sideMessageDetails .SubjectMessage .messageDiv textarea::-webkit-scrollbar {
  width: 5px;
}
.detail-exam .sideMessageDetails .SubjectMessage .messageDiv textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.detail-exam .sideMessageDetails .SubjectMessage .messageDiv textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.detail-exam .sideMessageDetails .SubjectMessage .messageDiv textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.detail-exam .sideMessageDetails .SubjectMessage .messageDiv textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.detail-exam .sideMessageDetails .SubjectMessage .messageDiv .mainFileDiv {
  border-top: 1px solid #f2f2f2;
  height: 30px;
  display: flex;
}
.detail-exam .sideMessageDetails .SubjectMessage .messageDiv .mainFileDiv .FileDiv {
  height: 40px;
}
.detail-exam .sideMessageDetails .SubjectMessage .messageDiv .mainFileDiv .FileDiv p {
  color: #00aff0;
  font-weight: bold;
  width: 50px;
  height: 25px;
  border-radius: 5px;
  text-align: start;
  align-items: center;
  margin: 5px 0px 0 0;
}
.detail-exam .sideMessageDetails .SubjectMessage .messageDiv .mainFileDiv .fileForDownload {
  display: flex;
  color: #00aff0;
  border-radius: 5px;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  height: 25px;
  margin: 2px 0 0 0;
  padding: 0 5px;
  overflow: hidden;
  white-space: nowrap;
}
.detail-exam .sideMessageDetails .SubjectMessage .messageDiv .mainFileDiv .fileForDownload p {
  white-space: nowrap;
  width: 100px;
  margin: 0;
  color: #00aff0;
  text-indent: 0;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail-exam .sideMessageDetails .SubjectMessage .messageDiv .mainFileDiv .fileForDownload p:hover {
  text-overflow: scroll;
  width: 350px;
}
.detail-exam .sideMessageDetails .SubjectMessage .messageDiv .mainFileDiv .fileForDownload:hover {
  cursor: pointer;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished {
  margin: 20px 0 0 0;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .subjectDiv,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .subjectDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e6e6e6;
  color: #666666;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 30px;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .subjectDiv p,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .subjectDiv p {
  margin: 0px 15px 0 15px;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .subjectDiv p span,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .subjectDiv p span {
  margin-right: 5px;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .subjectDiv #imageDiv2,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .subjectDiv #imageDiv3,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .subjectDiv #imageDiv2,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .subjectDiv #imageDiv3 {
  background: transparent;
  border: 1.7px solid white;
  width: 20px;
  border-radius: 5px;
  height: 20px;
  margin: 0px 20px 0 0;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .subjectDiv #imageDiv2:hover,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .subjectDiv #imageDiv3:hover,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .subjectDiv #imageDiv2:hover,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .subjectDiv #imageDiv3:hover {
  cursor: pointer;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport {
  width: 100%;
  height: 72%;
  background: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport textarea,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport textarea {
  width: 100%;
  min-height: 150px;
  padding: 10px;
  background: white;
  border: none;
  resize: none;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport textarea::-webkit-scrollbar {
  width: 5px;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar-button:single-button,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar-track,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar-thumb,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport textarea::-webkit-scrollbar-thumb:hover,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport .mainFileDiv,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport .mainFileDiv {
  border-top: 1px solid #f2f2f2;
  height: 30px;
  display: flex;
  padding: 0 15px;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport .mainFileDiv .FileDiv,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport .mainFileDiv .FileDiv {
  height: 40px;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport .mainFileDiv .FileDiv p,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport .mainFileDiv .FileDiv p {
  color: #00aff0;
  font-weight: bold;
  width: 50px;
  height: 25px;
  border-radius: 5px;
  text-align: start;
  align-items: center;
  margin: 5px 0px 0 0;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport .mainFileDiv .fileForDownload,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport .mainFileDiv .fileForDownload {
  display: flex;
  color: #00aff0;
  border-radius: 5px;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  height: 25px;
  margin: 2px 0 0 0;
  padding: 0 5px;
  overflow: hidden;
  white-space: nowrap;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport .mainFileDiv .fileForDownload p,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport .mainFileDiv .fileForDownload p {
  white-space: nowrap;
  width: 100px;
  margin: 0;
  color: #00aff0;
  text-indent: 0;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport .mainFileDiv .fileForDownload p:hover,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport .mainFileDiv .fileForDownload p:hover {
  text-overflow: scroll;
  width: 350px;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined .messageDivReport .mainFileDiv .fileForDownload:hover,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished .messageDivReport .mainFileDiv .fileForDownload:hover {
  cursor: pointer;
}
.detail-exam .sideMessageDetails .SubjectMessage .reportIfDeclined button,
.detail-exam .sideMessageDetails .SubjectMessage .reportIfFinished button {
  color: white;
  background: #00aff0;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 600;
  margin: 50px auto 0px 0;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence {
  width: 100%;
  margin-top: 30px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody {
  margin: 0 0 20px auto;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody p {
  text-indent: 20px;
  color: white;
  border: none;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody p span {
  font-weight: 900;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .senderMaiin {
  height: 30px;
  display: flex;
  background: #fa9551;
  justify-content: space-between;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .senderMaiin .senderP {
  margin-top: 5px;
  width: 70%;
  text-indent: 10px;
  flex: 3 1;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .senderMaiin .senderP span {
  font-weight: 900;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .senderMaiin .createdP {
  margin: 5px 0 0 0;
  width: 150px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .senderMaiin #imageDiv {
  background: transparent;
  border: 1.7px solid white;
  width: 20px;
  border-radius: 5px;
  height: 20px;
  margin: 5px 20px 0 0;
  display: none;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .senderMaiin #imageDiv:hover {
  cursor: pointer;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 {
  background: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 10px 15px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 textarea {
  background: white;
  color: #666666;
  width: 100%;
  height: 100px;
  resize: none;
  border: none;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 textarea::-webkit-scrollbar {
  width: 5px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 .mainFileDiv {
  border-top: 1px solid #f2f2f2;
  height: 30px;
  display: flex;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 .mainFileDiv .FileDiv {
  height: 40px;
  display: flex;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 .mainFileDiv .FileDiv p {
  color: #00aff0;
  font-weight: bold;
  width: 50px;
  height: 25px;
  border-radius: 5px;
  text-align: start;
  justify-content: center;
  align-items: center;
  margin: 5px 0px 0 0;
  text-indent: 0;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 .mainFileDiv .fileForDownload {
  display: flex;
  color: #00aff0;
  border-radius: 5px;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  height: 25px;
  margin: 2px 0 0 0;
  padding: 0 5px;
  overflow: hidden;
  white-space: nowrap;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 .mainFileDiv .fileForDownload p {
  white-space: nowrap;
  width: 100px;
  margin: 0;
  color: #00aff0;
  text-indent: 0;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 .mainFileDiv .fileForDownload p:hover {
  text-overflow: scroll;
  width: 350px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .row1 .mainFileDiv .fileForDownload:hover {
  cursor: pointer;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .ReplyMainDiv {
  margin-left: 20px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .ReplyMainDiv button {
  color: white;
  background: #00aff0;
  height: 40px;
  width: 80px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .ReplyMainDiv button .replyIcon {
  font-size: 20px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv {
  margin-left: 20px;
  border: none;
  border-radius: 15px;
  margin: 10px auto 0 auto;
  box-shadow: none;
  background: white;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv textarea {
  min-height: 200px;
  resize: none;
  border: none;
  text-indent: 0;
  padding: 10px 15px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv textarea::-webkit-scrollbar {
  width: 5px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv .sendbuttonAndAtt {
  display: flex;
  background: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin: 0;
  padding: 0;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv .sendbuttonAndAtt button {
  color: white;
  background: #00aff0;
  border: none;
  height: 30px;
  width: 80px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px 20px 20px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv .sendbuttonAndAtt button .replyIcon {
  font-size: 20px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv .sendbuttonAndAtt .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv .sendbuttonAndAtt .btn {
  color: white;
  background-color: #00aff0;
  width: 30px;
  border-radius: 8px;
  font-size: 30px;
  font-weight: bold;
  outline: none;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv .sendbuttonAndAtt .upload-btn-wrapper input[type=file] {
  font-size: 0px;
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  margin-left: 20px;
  cursor: pointer;
  opacity: 0;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv .sendbuttonAndAtt .fileForDownload {
  display: flex;
  color: #00aff0;
  border-radius: 5px;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  margin: 0 0 0 20px;
  padding: 0 5px;
  overflow: hidden;
  white-space: nowrap;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv .sendbuttonAndAtt .fileForDownload p {
  white-space: nowrap;
  width: 100px;
  margin: 0;
  color: #00aff0;
  text-indent: 0;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .MessageCorrespondence .Maintbody .SendMainDiv .sendbuttonAndAtt .fileForDownload p:hover {
  text-overflow: scroll;
  width: 350px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .ReplyMainDiv {
  margin-left: 20px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .ReplyMainDiv button {
  color: white;
  background: #00aff0;
  height: 40px;
  width: 80px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .ReplyMainDiv button .replyIcon {
  font-size: 20px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .ReplyMainDiv:disabled,
.detail-exam .sideMessageDetails .mainMessageCorrespondence .ReplyMainDiv button[disabled] {
  background: lightgray;
  color: white;
  cursor: not-allowed;
  box-shadow: none;
  pointer-events: none;
  border: none;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv {
  margin-left: 20px;
  border: none;
  border-radius: 15px;
  margin: 10px auto 0 auto;
  box-shadow: none;
  background: white;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .senderMaiin {
  height: 30px;
  display: flex;
  background: #fa9551;
  justify-content: space-between;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  color: white;
  text-indent: 15px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .senderMaiin .senderP {
  margin-top: 5px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .senderMaiin .senderP span {
  font-weight: 900;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .senderMaiin .createdP {
  margin: 5px 20px 0 0;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv textarea {
  height: 200px;
  width: 100%;
  resize: none;
  border: none;
  text-indent: 0;
  padding: 10px 15px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv textarea::-webkit-scrollbar {
  width: 5px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt {
  display: flex;
  flex-direction: column;
  background: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  margin: 0;
  padding: 0 15px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt button {
  color: white;
  background: #00aff0;
  border: none;
  height: 30px;
  width: 80px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px 10px 0px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt button .replyIcon {
  font-size: 20px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .btn {
  outline: none;
  background: white;
  border: 1.7px solid #00aff0;
  height: 30px;
  color: #00aff0;
  font-weight: bold;
  padding: 0 5px;
  width: 70px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 0;
  align-items: center;
  box-shadow: none;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .btn p {
  margin: 0;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .upload-btn-wrapper input[type=file] {
  font-size: 0px;
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  margin-left: 20px;
  cursor: pointer;
  opacity: 0;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .fileForDownload {
  display: flex;
  color: #00aff0;
  border-radius: 5px;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  margin: 0 0 0 20px;
  padding: 0 5px;
  overflow: hidden;
  white-space: nowrap;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .fileForDownload p {
  white-space: nowrap;
  max-width: 100px;
  margin: 0 2px 0 0;
  color: #00aff0;
  text-indent: 0;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .fileForDownload p:hover {
  text-overflow: scroll;
  max-width: 350px;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .fileForDownload .deleteB {
  color: gray;
  width: 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  border: none;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .fileForDownload .deleteB:hover {
  cursor: pointer;
}
.detail-exam .sideMessageDetails .mainMessageCorrespondence .SendMainDiv .sendbuttonAndAtt .attachess {
  display: flex;
  border-top: 1.7px solid #f2f2f2;
  padding: 5px 0 0 0;
  height: 40px;
}
.detail-exam .sideMessageDetails .newMessage {
  border: 2px solid #00aff0;
  color: #00aff0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: relative;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  float: right;
  margin: auto 0 0 auto;
}
.detail-exam .sideMessageDetails .newMessage h1 {
  margin: 0;
  position: absolute;
  font-size: 40px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.detail-exam .sideMessageDetails .newMessage:hover {
  background: #00aff0;
  color: white;
}
.detail-exam .sideMessageDetails .sendButtonForReplay {
  color: white;
  background: #00aff0;
  border: none;
  height: 30px;
  width: 80px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px 10px 0px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.detail-exam .sideMessageDetails .sendButtonForReplay .replyIcon {
  font-size: 20px;
}
.detail-exam .message-btn {
  position: absolute;
  bottom: 30px;
}
.detail-exam .message-btn button {
  background: #00aff0;
  margin: 0;
  font-size: 14px;
  height: 30px;
  width: 120px;
  border-radius: 10px;
}
.detail-exam .message-btn button:disabled,
.detail-exam .message-btn button button[disabled] {
  background: lightgray;
  color: white;
  cursor: not-allowed;
  box-shadow: none;
  pointer-events: none;
  border: none;
}

.messages-link,
.message-link {
  border-radius: 8px;
  background-color: #00aff0;
  color: white;
  margin: 10px;
  border: none;
  font-size: 18px;
  font-weight: 700;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  outline: 0;
}
.messages-link:disabled,
.messages-link button[disabled],
.message-link:disabled,
.message-link button[disabled] {
  background: lightgray;
  color: white;
  cursor: not-allowed;
  box-shadow: none;
  pointer-events: none;
  border: none;
}
.mainClientAlerts {
  margin: 110px auto 20px 360px !important;
  width: calc(100% - 480px) !important;
  padding: 30px !important;
  background: #f2f2f2;
  height: 500px;
  position: relative;
  border-radius: 15px;
}
.mainClientAlerts .iconAlert {
  position: absolute;
  top: -76px;
  left: 0;
  z-index: 20;
  height: 35px;
  display: flex;
  border-bottom: 4px solid #fa9551;
  margin-right: 45px;
}
.mainClientAlerts .iconAlert h4 {
  font-weight: bold;
  font-size: 15px;
  color: #2cace3;
}
.mainClientAlerts .loading {
  -webkit-animation: rotation 2s infinite linear;
          animation: rotation 2s infinite linear;
  position: absolute;
  width: 70px !important;
  top: 40%;
  left: 45%;
  -webkit-transform: translate(-40%, -45%);
          transform: translate(-40%, -45%);
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
.mainClientAlerts .tableDiv {
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
}
.mainClientAlerts .tableDiv::-webkit-scrollbar {
  width: 1px;
}
.mainClientAlerts .tableDiv .NoResultDiv {
  color: #666666;
  margin: 20px auto;
  font-size: 20px;
}
.mainClientAlerts .tableDiv .table2 {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  padding: 10px;
  color: #666666;
}
.mainClientAlerts .tableDiv .table2 tbody {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.mainClientAlerts .tableDiv .table2 tbody:hover {
  background: white;
  cursor: pointer;
}
.mainClientAlerts .tableDiv .table2 tbody tr {
  height: 40px;
}
.mainClientAlerts .tableDiv .table2 tbody tr td {
  text-align: left;
  text-indent: 20px;
}
.mainClientAlerts .tableDiv .table2 tbody tr td.client-type {
  text-indent: 0;
}
.mainClientAlerts .tableDiv .table2 tbody tr .created p {
  margin: 2px 0 0 0;
}
.mainClientAlerts .tableDiv .table2 tbody tr .client-trash {
  color: red;
  text-align: center;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  text-indent: 0;
}
.mainClientAlerts .tableDiv .table2 tbody tr .client-trash:hover {
  background: red;
  color: white;
}
.mainClientAlerts .tableDiv .table2 .client-head {
  text-align: center;
  color: #666666;
}
.mainClientAlerts .tableDiv .table2 .client-head .client-row {
  height: 40px;
  width: 100%;
  color: white;
  background: #fa9551;
}
.mainClientAlerts .tableDiv .table2 .client-head .client-row .client-doctor {
  align-self: left;
  text-align: left;
  border-top-left-radius: 15px;
  text-indent: 20px;
}
.mainClientAlerts .tableDiv .table2 .client-head .client-row .client-date {
  text-align: left;
  align-self: left;
  text-align: left;
  text-indent: 20px;
}
.mainClientAlerts .tableDiv .table2 .client-head .client-row .client-type {
  width: 150px;
  align-self: left;
  text-align: left;
}
.mainClientAlerts .tableDiv .table2 .client-head .client-row .client-subject {
  align-self: left;
  text-align: left;
}
.mainClientAlerts .tableDiv .table2 .client-head .client-row .client-trash {
  width: 40px;
  border-top-right-radius: 15px;
}
.mainForgotPass {
  width: 60%;
  margin: 200px auto 200px auto;
}
.mainForgotPass .forgot {
  width: 500px;
  margin: 0 auto;
  text-align: start;
  color: #4092c2;
}
.mainForgotPass .forgot p {
  margin: 0 0 20px 0;
}
.mainForgotPass .forgot label {
  font-weight: 600;
}
.mainForgotPass .forgot input {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #4092c2;
  text-indent: 10px;
  outline: none;
}
.mainForgotPass .forgot input::-webkit-input-placeholder {
  color: #4092c2;
  text-indent: 10px;
}
.mainForgotPass .forgot input:-ms-input-placeholder {
  color: #4092c2;
  text-indent: 10px;
}
.mainForgotPass .forgot input::-ms-input-placeholder {
  color: #4092c2;
  text-indent: 10px;
}
.mainForgotPass .forgot input::placeholder {
  color: #4092c2;
  text-indent: 10px;
}
.mainForgotPass .forgot button {
  background: #4092c2;
  color: white;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #4092c2;
  margin: 20px 0;
  outline: none;
}
.mainForgotPass .forgot .mainGoBackDiv {
  text-align: center;
}
.mainForgotPass .forgot .mainGoBackDiv .goBack {
  text-decoration: underline;
}
.mainForgotPass .message {
  color: #4092c2;
  height: 200px;
}
.mainChangePass {
  width: 60%;
  margin: 200px auto 200px auto;
}
.mainChangePass .newPasswords {
  width: 500px;
  margin: 0 auto;
  text-align: start;
  color: #4092c2;
}
.mainChangePass .newPasswords p {
  margin: 0 0 20px 0;
}
.mainChangePass .newPasswords label {
  font-weight: 600;
}
.mainChangePass .newPasswords input {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #4092c2;
  text-indent: 10px;
  outline: none;
  margin: 0 0 20px 0;
}
.mainChangePass .newPasswords input::-webkit-input-placeholder {
  color: #4092c2;
  text-indent: 10px;
}
.mainChangePass .newPasswords input:-ms-input-placeholder {
  color: #4092c2;
  text-indent: 10px;
}
.mainChangePass .newPasswords input::-ms-input-placeholder {
  color: #4092c2;
  text-indent: 10px;
}
.mainChangePass .newPasswords input::placeholder {
  color: #4092c2;
  text-indent: 10px;
}
.mainChangePass .newPasswords button {
  background: #4092c2;
  color: white;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #4092c2;
  outline: none;
}
.mainChangePass .message {
  color: #4092c2;
  height: 200px;
}
.mainClientsDiv {
  width: 40%;
  min-height: 500px;
  margin: 110px auto 40px 360px;
  background: #f3f3f3;
  position: relative;
  border-radius: 15px;
}
.mainClientsDiv .clientsAbove {
  position: absolute;
  top: -76px;
  z-index: 20;
  border-bottom: 4px solid #fa9551;
  height: 35px;
  display: flex;
}
.mainClientsDiv .clientsAbove h4 {
  font-size: 15px;
  font-weight: bold !important;
  color: #2cace3;
}
.mainClientsDiv .clientsDiv {
  height: 93px;
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 30px 30px;
}
.mainClientsDiv .clientsDiv .exam-doc {
  width: 100%;
}
.mainClientsDiv .row2 {
  padding: 0 0 20px 0;
  margin: 0 30px 0 30px;
}
.mainClientsDiv .row2 .list-group {
  box-shadow: none;
  height: 130px;
  display: flex;
}
.mainClientsDiv .row2 .list-group .list-group-item {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  padding: 0;
  color: #666666;
  outline: 0;
  display: flex;
  width: 100%;
  font-weight: 500;
}
.mainClientsDiv .row2 .list-group .list-group-item img {
  margin: 9px 20px 9px 9px;
  border-radius: 10px;
  width: 110px;
  height: 110px;
  background: white;
  object-fit: cover;
}
.mainClientsDiv .row2 .list-group .list-group-item p {
  font-weight: bold;
  font-size: 18px;
  padding: 20px 0 0 0;
}
.mainClientsDiv .row2 .list-group .list-group-item .docInfo {
  text-align: start;
}
.mainClientsDiv .row2 .list-group .list-group-item .docInfo p {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
  padding: 20px 0 0 0;
}
.mainClientsDiv .row2 .list-group .list-group-item .docInfo h5 {
  font-weight: 900;
}
.mainClientsDiv .row2 .list-group .list-group-item .docInfo h5 span {
  font-weight: 400;
}
.mainClientsDiv .row2 .list-group .list-group-item a {
  color: #2cace3 !important;
  font-weight: 600;
  margin: auto 20px 20px auto;
}
.mainClientsDiv .row2 .list-group .list-group-item a:hover {
  cursor: pointer;
}
.e-header-icon-wrapper .e-edit,
.e-header-icon-wrapper .e-delete {
  display: none !important;
}

.e-input-group .e-input {
  display: none !important;
}

.e-event-create,
.e-event-details {
  display: none !important;
}
.mainEmailList {
  margin: 110px auto 20px 360px !important;
  width: calc(100% - 480px) !important;
  padding: 30px !important;
  background: #f2f2f2;
  height: 500px;
  position: relative;
  border-radius: 15px;
}
.mainEmailList .iconEmail {
  position: absolute;
  top: -76px;
  left: 0;
  z-index: 20;
  height: 35px;
  display: flex;
  border-bottom: 4px solid #fa9551;
  margin-right: 45px;
}
.mainEmailList .iconEmail h4 {
  font-weight: bold;
  font-size: 15px;
  color: #2cace3;
}
.mainEmailList .loading {
  -webkit-animation: rotation 2s infinite linear;
          animation: rotation 2s infinite linear;
  position: absolute;
  width: 70px !important;
  top: 40%;
  left: 45%;
  -webkit-transform: translate(-40%, -45%);
          transform: translate(-40%, -45%);
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
.mainEmailList .tableDiv {
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  /* width */
  /* Track */
}
.mainEmailList .tableDiv::-webkit-scrollbar {
  width: 1px;
}
.mainEmailList .tableDiv .NoResultDiv {
  color: #666666;
  margin: 20px auto;
  font-size: 20px;
}
.mainEmailList .tableDiv .table2 {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  padding: 10px;
  color: #666666 !important;
}
.mainEmailList .tableDiv .table2 tbody {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.mainEmailList .tableDiv .table2 tbody:hover {
  background: white;
  cursor: pointer;
}
.mainEmailList .tableDiv .table2 tbody tr {
  height: 40px;
}
.mainEmailList .tableDiv .table2 tbody tr td {
  text-align: left;
}
.mainEmailList .tableDiv .table2 tbody tr td .finished {
  color: #00aff0;
  font-size: 17px;
}
.mainEmailList .tableDiv .table2 tbody tr .client-subject {
  color: #666666;
}
.mainEmailList .tableDiv .table2 tbody tr .client-status {
  text-align: center;
  width: 100px;
}
.mainEmailList .tableDiv .table2 tbody tr .client-status:hover .check,
.mainEmailList .tableDiv .table2 tbody tr .client-status:hover .pendi,
.mainEmailList .tableDiv .table2 tbody tr .client-status:hover .finished,
.mainEmailList .tableDiv .table2 tbody tr .client-status:hover .declined {
  display: none;
}
.mainEmailList .tableDiv .table2 tbody tr .client-status:hover .status {
  display: block;
}
.mainEmailList .tableDiv .table2 tbody tr .status {
  display: none;
}
.mainEmailList .tableDiv .table2 tbody tr .client-doctor {
  padding: 0 0 0 35px;
}
.mainEmailList .tableDiv .table2 tbody tr .created p {
  margin: 2px 0 0 0;
}
.mainEmailList .tableDiv .table2 .client-head {
  text-align: center;
  color: #666666;
}
.mainEmailList .tableDiv .table2 .client-head .client-row {
  height: 40px;
  color: white;
  background: #fa9551;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-doctor {
  width: 40%;
  border-top-left-radius: 15px;
  background: #fa9551;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-doctor .mainExamDiv {
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 150px;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-doctor .mainExamDiv span {
  margin-left: 24%;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-doctor .mainExamDiv .searchIcon {
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 0;
  height: 100%;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-doctor .mainExamDiv .searchIcon:hover {
  cursor: pointer;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-subject {
  width: 40%;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-type {
  width: 200px;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-type .mainExamDiv {
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 150px;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-type .mainExamDiv .searchIcon {
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 0;
  height: 100%;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-type .mainExamDiv .searchIcon:hover {
  cursor: pointer;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-date {
  text-align: left;
  width: 200px;
  border-top-right-radius: 15px;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-status {
  text-align: center;
}
.mainEmailList {
  margin: 110px auto 20px 360px !important;
  width: calc(100% - 480px) !important;
  padding: 30px !important;
  background: #f2f2f2;
  height: 500px;
  position: relative;
  border-radius: 15px;
}
.mainEmailList .iconEmail {
  position: absolute;
  top: -76px;
  left: 0;
  z-index: 20;
  height: 35px;
  display: flex;
  border-bottom: 4px solid #fa9551;
  margin-right: 45px;
}
.mainEmailList .iconEmail h4 {
  font-weight: bold;
  font-size: 15px;
  color: #2cace3;
}
.mainEmailList .loading {
  -webkit-animation: rotation 2s infinite linear;
          animation: rotation 2s infinite linear;
  position: absolute;
  width: 70px !important;
  top: 40%;
  left: 45%;
  -webkit-transform: translate(-40%, -45%);
          transform: translate(-40%, -45%);
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
.mainEmailList .tableDiv {
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  /* width */
  /* Track */
}
.mainEmailList .tableDiv::-webkit-scrollbar {
  width: 1px;
}
.mainEmailList .tableDiv .NoResultDiv {
  color: #666666;
  margin: 20px auto;
  font-size: 20px;
}
.mainEmailList .tableDiv .table2 {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  padding: 10px;
  color: #666666 !important;
}
.mainEmailList .tableDiv .table2 tbody {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.mainEmailList .tableDiv .table2 tbody:hover {
  background: white;
  cursor: pointer;
}
.mainEmailList .tableDiv .table2 tbody tr {
  height: 40px;
}
.mainEmailList .tableDiv .table2 tbody tr td {
  text-align: left;
}
.mainEmailList .tableDiv .table2 tbody tr td .finished {
  color: #00aff0;
  font-size: 17px;
}
.mainEmailList .tableDiv .table2 tbody tr .client-subject {
  color: #666666;
}
.mainEmailList .tableDiv .table2 tbody tr .client-status {
  text-align: center;
  width: 100px;
}
.mainEmailList .tableDiv .table2 tbody tr .client-status:hover .check,
.mainEmailList .tableDiv .table2 tbody tr .client-status:hover .pendi,
.mainEmailList .tableDiv .table2 tbody tr .client-status:hover .finished,
.mainEmailList .tableDiv .table2 tbody tr .client-status:hover .declined {
  display: none;
}
.mainEmailList .tableDiv .table2 tbody tr .client-status:hover .status {
  display: block;
}
.mainEmailList .tableDiv .table2 tbody tr .status {
  display: none;
}
.mainEmailList .tableDiv .table2 tbody tr .client-doctor {
  padding: 0 0 0 35px;
}
.mainEmailList .tableDiv .table2 tbody tr .created p {
  margin: 2px 0 0 0;
}
.mainEmailList .tableDiv .table2 .client-head {
  text-align: center;
  color: #666666;
}
.mainEmailList .tableDiv .table2 .client-head .client-row {
  height: 40px;
  color: white;
  background: #fa9551;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-doctor {
  width: 40%;
  border-top-left-radius: 15px;
  background: #fa9551;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-doctor .mainExamDiv {
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 150px;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-doctor .mainExamDiv span {
  margin-left: 24%;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-doctor .mainExamDiv .searchIcon {
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 0;
  height: 100%;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-doctor .mainExamDiv .searchIcon:hover {
  cursor: pointer;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-subject {
  width: 40%;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-type {
  width: 200px;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-type .mainExamDiv {
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 150px;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-type .mainExamDiv .searchIcon {
  font-size: 20px;
  display: flex;
  align-items: center;
  padding: 0 10px 0 0;
  height: 100%;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-type .mainExamDiv .searchIcon:hover {
  cursor: pointer;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-date {
  text-align: left;
  width: 200px;
  border-top-right-radius: 15px;
}
.mainEmailList .tableDiv .table2 .client-head .client-row .client-status {
  text-align: center;
}
.mainTransactionDiv {
  display: flex;
  width: 40%;
  margin: 110px auto 20px 360px;
  border-radius: 15px;
  background: green !important;
  position: relative;
}
.mainTransactionDiv .newVideo {
  position: absolute;
  top: -76px;
  z-index: 20;
  height: 35px;
  display: flex;
}
.mainTransactionDiv .newVideo .profileDoc {
  margin-right: 45px;
}
.mainTransactionDiv .newVideo .profileDoc h4 {
  font-size: 15px;
  color: #2cace3;
}
.mainTransactionDiv .newVideo .profileDoc:hover {
  cursor: pointer;
}

.mainBancAcount {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 40%;
  background: #f2f2f2;
  margin: 0px auto 20px 360px;
  padding: 30px 30px;
  position: relative;
  border-radius: 15px;
}
.mainBancAcount .doctor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
}
.mainBancAcount .doctor .imageAndInputDiv {
  display: flex;
  width: 100%;
  position: relative;
}
.mainBancAcount .doctor .imageAndInputDiv input {
  font-size: 20px;
  width: 100%;
  margin: 0;
  padding-right: 30px;
}
.mainBancAcount .doctor .imageAndInputDiv img {
  position: absolute;
  right: 5px;
  top: 10px;
}
.mainBancAcount .doctor label {
  color: #666666;
  margin: 0px 0 5px 0;
}
.mainBancAcount .doctor input {
  width: 100%;
  margin: 0 0 10px 0;
  height: 33px;
  border-radius: 10px;
  border: 1.7px solid #fa9551;
  text-indent: 20px;
}
.mainBancAcount .doctor input[type=number]::-webkit-inner-spin-button,
.mainBancAcount .doctor input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.mainBancAcount .doctor .confirmPass {
  font-size: 20px;
}
.mainBancAcount .doctor button {
  background: #00aff0;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  color: white;
  margin: 35px auto 0 0;
}
.mainDoctorAlerts {
  margin: 110px auto 20px 360px !important;
  width: calc(100% - 480px) !important;
  padding: 30px !important;
  background: #f2f2f2;
  height: 500px;
  position: relative;
  border-radius: 15px;
}
.mainDoctorAlerts .iconAlert {
  position: absolute;
  top: -76px;
  left: 0;
  z-index: 20;
  height: 35px;
  display: flex;
  border-bottom: 4px solid #fa9551;
  margin-right: 45px;
}
.mainDoctorAlerts .iconAlert h4 {
  font-weight: bold;
  font-size: 15px;
  color: #2cace3;
}
.mainDoctorAlerts .loading {
  -webkit-animation: rotation 2s infinite linear;
          animation: rotation 2s infinite linear;
  position: absolute;
  width: 70px !important;
  top: 40%;
  left: 45%;
  -webkit-transform: translate(-40%, -45%);
          transform: translate(-40%, -45%);
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
.mainDoctorAlerts .tableDiv {
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
}
.mainDoctorAlerts .tableDiv::-webkit-scrollbar {
  width: 1px;
}
.mainDoctorAlerts .tableDiv .NoResultDiv {
  color: #666666;
  margin: 20px auto;
  font-size: 20px;
}
.mainDoctorAlerts .tableDiv .table2 {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  padding: 10px;
  color: #666666;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.mainDoctorAlerts .tableDiv .table2 tbody {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.mainDoctorAlerts .tableDiv .table2 tbody:hover {
  background: white;
  cursor: pointer;
}
.mainDoctorAlerts .tableDiv .table2 tbody tr {
  height: 40px;
}
.mainDoctorAlerts .tableDiv .table2 tbody tr td {
  text-align: left;
  text-indent: 20px;
}
.mainDoctorAlerts .tableDiv .table2 tbody tr td.client-type {
  text-indent: 0;
}
.mainDoctorAlerts .tableDiv .table2 tbody tr .created p {
  margin: 2px 0 0 0;
}
.mainDoctorAlerts .tableDiv .table2 tbody tr .client-trash {
  color: red;
  text-align: center;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  text-indent: 0;
}
.mainDoctorAlerts .tableDiv .table2 tbody tr .client-trash:hover {
  background: red;
  color: white;
}
.mainDoctorAlerts .tableDiv .table2 .client-head {
  text-align: center;
  color: #666666;
}
.mainDoctorAlerts .tableDiv .table2 .client-head .client-row {
  height: 40px;
  width: 100%;
  color: white;
  background: #fa9551;
}
.mainDoctorAlerts .tableDiv .table2 .client-head .client-row .client-doctor {
  align-self: left;
  text-align: left;
  border-top-left-radius: 15px;
  text-indent: 20px;
}
.mainDoctorAlerts .tableDiv .table2 .client-head .client-row .client-date {
  text-align: left;
  align-self: left;
  text-align: left;
  text-indent: 20px;
}
.mainDoctorAlerts .tableDiv .table2 .client-head .client-row .client-type {
  width: 150px;
  align-self: left;
  text-align: left;
}
.mainDoctorAlerts .tableDiv .table2 .client-head .client-row .client-subject {
  align-self: left;
  text-align: left;
}
.mainDoctorAlerts .tableDiv .table2 .client-head .client-row .client-trash {
  width: 40px;
  border-top-right-radius: 15px;
}
.mainSettings {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 40%;
  background: #f2f2f2;
  margin: 110px auto 20px 360px;
  padding: 30px 30px;
  position: relative;
  border-radius: 15px;
}
.mainSettings .iconVideo {
  position: absolute;
  top: -76px;
  left: 0;
  z-index: 20;
  border-bottom: 4px solid #fa9551;
  height: 35px;
  display: flex;
}
.mainSettings .iconVideo h4 {
  font-size: 15px;
  font-weight: bold !important;
  color: #2cace3;
}
.mainSettings .doctor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
}
.mainSettings .doctor .imageAndInputDiv {
  display: flex;
  width: 100%;
  position: relative;
}
.mainSettings .doctor .imageAndInputDiv input {
  font-size: 20px;
  width: 100%;
  margin: 0;
  padding-right: 30px;
}
.mainSettings .doctor .imageAndInputDiv img {
  position: absolute;
  right: 5px;
  top: 10px;
}
.mainSettings .doctor label {
  color: #666666;
  margin: 0px 0 5px 0;
}
.mainSettings .doctor input {
  width: 100%;
  margin: 0 0 10px 0;
  height: 33px;
  border-radius: 10px;
  border: 1.7px solid #fa9551;
  text-indent: 20px;
}
.mainSettings .doctor .confirmPass {
  font-size: 20px;
}
.mainSettings .doctor button {
  background: #00aff0;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  color: white;
  margin: 35px auto 0 0;
}
.mainUpdate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 40%;
  background: #f2f2f2;
  margin: 110px auto 20px 360px;
  padding: 30px 30px;
  position: relative;
  border-radius: 15px;
}
.mainUpdate .iconVideo {
  position: absolute;
  top: -76px;
  left: 0;
  z-index: 20;
  border-bottom: 4px solid #fa9551;
  height: 35px;
  display: flex;
}
.mainUpdate .iconVideo h4 {
  font-size: 15px;
  font-weight: bold !important;
  color: #2cace3;
}
.mainUpdate .doctor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
}
.mainUpdate .doctor label {
  color: #666666;
  margin: 0px 0 5px 0;
}
.mainUpdate .doctor div {
  width: 100%;
  position: relative;
}
.mainUpdate .doctor div input {
  width: 100%;
  margin: 0 0 10px 0;
  height: 33px;
  border-radius: 10px;
  border: 1.7px solid #fa9551;
  text-indent: 20px;
  font-weight: 600;
  padding-right: 30px;
}
.mainUpdate .doctor div img {
  position: absolute;
  right: 5px;
  top: 10px;
}
.mainUpdate .doctor .confirmPass {
  font-size: 20px;
}
.mainUpdate button {
  background: #00aff0;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  color: white;
  margin: 25px auto 0 0;
}
.mainSettingsClient {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 40%;
  background: #f2f2f2;
  margin: 110px auto 20px 360px;
  padding: 30px 30px;
  position: relative;
  border-radius: 15px;
}
.mainSettingsClient .iconVideo {
  position: absolute;
  top: -76px;
  left: 0;
  z-index: 20;
  border-bottom: 4px solid #fa9551;
  height: 35px;
  display: flex;
}
.mainSettingsClient .iconVideo h4 {
  font-size: 15px;
  font-weight: bold !important;
  color: #2cace3;
}
.mainSettingsClient .doctor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
}
.mainSettingsClient .doctor .imageAndInputDiv {
  display: flex;
  width: 100%;
  position: relative;
}
.mainSettingsClient .doctor .imageAndInputDiv input {
  font-size: 20px;
  width: 100%;
  margin: 0;
  padding-right: 30px;
}
.mainSettingsClient .doctor .imageAndInputDiv img {
  position: absolute;
  right: 5px;
  top: 10px;
}
.mainSettingsClient .doctor label {
  color: #666666;
  margin: 0px 0 5px 0;
}
.mainSettingsClient .doctor input {
  width: 100%;
  margin: 0 0 10px 0;
  height: 33px;
  border-radius: 10px;
  border: 1.7px solid #fa9551;
  text-indent: 20px;
}
.mainSettingsClient .doctor .confirmPass {
  font-size: 20px;
}
.mainSettingsClient .doctor button {
  background: #00aff0;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  color: white;
  margin: 35px auto 0 0;
}
.mainUpdateClient {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 40%;
  background: #f2f2f2;
  margin: 110px auto 20px 360px;
  padding: 30px 30px;
  position: relative;
  border-radius: 15px;
}
.mainUpdateClient .iconVideo {
  position: absolute;
  top: -76px;
  left: 0;
  z-index: 20;
  border-bottom: 4px solid #fa9551;
  height: 35px;
  display: flex;
}
.mainUpdateClient .iconVideo h4 {
  font-size: 15px;
  font-weight: bold !important;
  color: #2cace3;
}
.mainUpdateClient .doctor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
}
.mainUpdateClient .doctor label {
  color: #666666;
  margin: 0px 0 5px 0;
}
.mainUpdateClient .doctor div {
  width: 100%;
  position: relative;
}
.mainUpdateClient .doctor div input {
  width: 100%;
  margin: 0 0 10px 0;
  height: 33px;
  border-radius: 10px;
  border: 1.7px solid #fa9551;
  text-indent: 20px;
  font-weight: 600;
  padding-right: 30px;
}
.mainUpdateClient .doctor div img {
  position: absolute;
  right: 5px;
  top: 10px;
}
.mainUpdateClient .doctor .confirmPass {
  font-size: 20px;
}
.mainUpdateClient button {
  background: #00aff0;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  color: white;
  margin: 25px auto 0 0;
}
.mainDoctorsDiv {
  width: 40%;
  min-height: 350px;
  margin: 110px auto 40px 360px;
  background: #f3f3f3;
  position: relative;
  border-radius: 15px;
}
.mainDoctorsDiv .doctorsAbove {
  position: absolute;
  top: -76px;
  z-index: 20;
  height: 35px;
  display: flex;
  border-bottom: 4px solid #fa9551;
  margin-right: 45px;
}
.mainDoctorsDiv .doctorsAbove h4 {
  font-size: 15px;
  font-weight: bold;
  color: #2cace3;
}
.mainDoctorsDiv .doctorsAbove:hover {
  cursor: pointer;
}
.mainDoctorsDiv .doctorsDiv {
  height: 100px;
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px;
  width: 100%;
}
.mainDoctorsDiv .doctorsDiv .exam-spec {
  flex: 1 1;
  width: 50%;
}
.mainDoctorsDiv .doctorsDiv .exam-doc {
  flex: 1 1;
  width: 50%;
}
.mainDoctorsDiv .row2 {
  padding: 0 0 20px 0;
  margin: 0 30px 0 30px;
}
.mainDoctorsDiv .row2 .list-group {
  box-shadow: none;
  height: 130px;
  display: flex;
}
.mainDoctorsDiv .row2 .list-group .list-group-item {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border: 1.7px solid #fa9551;
  border-radius: 10px;
  padding: 0;
  color: #666666;
  outline: 0;
  display: flex;
  width: 100%;
  font-weight: 500;
}
.mainDoctorsDiv .row2 .list-group .list-group-item img {
  margin: 9px 20px 9px 9px;
  border-radius: 10px;
  width: 110px;
  height: 110px;
  background: white;
  object-fit: cover;
}
.mainDoctorsDiv .row2 .list-group .list-group-item .docInfo {
  text-align: start;
}
.mainDoctorsDiv .row2 .list-group .list-group-item .docInfo p {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
  padding: 20px 0 0 0;
}
.mainDoctorsDiv .row2 .list-group .list-group-item .docInfo h5 {
  font-weight: 900;
}
.mainDoctorsDiv .row2 .list-group .list-group-item .docInfo h5 span {
  font-weight: 400;
}
.mainDoctorsDiv .row2 .list-group .list-group-item a {
  color: #2cace3 !important;
  font-weight: 600;
  margin: auto 20px 20px auto;
}
.mainDoctorsDiv .row2 .list-group .list-group-item a:hover {
  cursor: pointer;
}
.mainDoctorDiv {
  width: 40%;
  min-height: 520px;
  margin: 110px 0 20px 360px;
  background: #f3f3f3;
  position: relative;
  border-radius: 15px;
}
.mainDoctorDiv .doctorsAbove {
  position: absolute;
  top: -76px;
  z-index: 20;
  height: 35px;
  display: flex;
  border-bottom: 4px solid #fa9551;
  margin-right: 45px;
}
.mainDoctorDiv .doctorsAbove h4 {
  font-size: 15px;
  font-weight: bold;
  color: #2cace3;
}
.mainDoctorDiv .doctorsAbove:hover {
  cursor: pointer;
}
.mainDoctorDiv .row2 {
  margin: 0 auto;
  color: #666666;
  padding: 30px 30px;
}
.mainDoctorDiv .row2 .mainDoctorInfoDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mainDoctorDiv .row2 .mainDoctorInfoDiv .imageDiv {
  border: 1.7px solid #fa9551;
  border-radius: 15px;
  background: white;
  flex: 1 1;
  height: 220px;
  display: flex;
  margin: 0 0 20px 0;
}
.mainDoctorDiv .row2 .mainDoctorInfoDiv .imageDiv .info {
  padding: 20px;
  text-align: start;
}
.mainDoctorDiv .row2 .mainDoctorInfoDiv .imageDiv .info p {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
}
.mainDoctorDiv .row2 .mainDoctorInfoDiv .imageDiv .info h5 {
  font-weight: 900;
  margin: 20px 0 0 0 !important;
}
.mainDoctorDiv .row2 .mainDoctorInfoDiv .imageDiv .info h5 span {
  font-weight: 400;
}
.mainDoctorDiv .row2 .mainDoctorInfoDiv .imageDiv img {
  width: 110px;
  height: 110px;
  margin: 20px 5px 20px 20px;
  border-radius: 10px;
  object-fit: cover;
}
.mainDoctorDiv .row2 .mainDoctorInfoDiv .pricesAndDoing {
  flex: 1 1;
  border: 1.7px solid #fa9551;
  background: white;
  border-radius: 15px;
  margin: 0 0 20px 0;
  min-height: 130px;
}
.mainDoctorDiv .row2 .mainDoctorInfoDiv .pricesAndDoing .priceInfo {
  padding: 20px;
}
.mainDoctorDiv .row2 .mainDoctorInfoDiv .pricesAndDoing .priceInfo div {
  display: flex;
  justify-content: space-between;
}
.mainDoctorDiv .row2 .mainDoctorInfoDiv .pricesAndDoing .priceInfo div p {
  text-align: left;
}
.mainDoctorDiv .row2 .mainDoctorInfoDiv .pricesAndDoing .priceInfo div p span {
  font-weight: 900;
}
.mainDoctorDiv .row2 .mainDoctorInfoDiv .pricesAndDoing .priceInfo div button {
  width: 45px;
  height: 20px;
  font-size: 12px;
  font-weight: 550;
  padding-top: 2px;
  display: flex;
  justify-content: center;
  background: #00aff0;
  border: none;
  border-radius: 3px;
  color: white;
}
.mainDoctorDiv .row2 .BiographyDiv {
  display: flex;
  flex-direction: column;
}
.mainDoctorDiv .row2 .BiographyDiv .bio {
  flex: 1 1;
  border: 1.7px solid #fa9551;
  background: white;
  border-radius: 15px;
  margin: 20px 0 0 0;
  min-height: 130px;
}
.mainDoctorDiv .row2 .BiographyDiv .bio .pForBio {
  font-weight: 900;
  text-align: left;
  padding: 20px 20px 0 20px;
}
.mainDoctorDiv .row2 .BiographyDiv .bio textarea {
  resize: none;
  width: 100%;
  min-height: 70px;
  padding: 0px 20px 20px 20px;
  border-radius: 5px;
  background: white;
  border: none;
}
.mainDoctorDiv .row2 .BiographyDiv .bio textarea::-webkit-scrollbar {
  width: 5px;
}
.mainDoctorDiv .row2 .BiographyDiv .bio textarea::-webkit-scrollbar-button:single-button {
  display: block;
  height: 13px;
  width: 16px;
}
.mainDoctorDiv .row2 .BiographyDiv .bio textarea::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.mainDoctorDiv .row2 .BiographyDiv .bio textarea::-webkit-scrollbar-thumb {
  background: #fa9551;
  border-radius: 5px;
}
.mainDoctorDiv .row2 .BiographyDiv .bio textarea::-webkit-scrollbar-thumb:hover {
  background: #db8246;
}
.mainDoctorDiv .row2 .BiographyDiv .workHours {
  flex: 1 1;
  border: 1.7px solid #fa9551;
  background: white;
  border-radius: 15px;
  min-height: 130px;
}
.mainDoctorDiv .row2 .BiographyDiv .workHours div {
  padding: 20px;
}
.mainDoctorDiv .row2 .BiographyDiv .workHours div p {
  text-align: left;
  margin-bottom: 7px;
}
.mainDoctorDiv .row2 .BiographyDiv .workHours div p span {
  font-weight: 900;
}
#jitsi-container {
  height: 100vh !important;
}
#jitsi-container #jitsiConferenceFrame0 {
  width: 100% !important;
}

.detailExam {
  background: #f2f2f2;
  position: relative;
  padding: 30px 0px !important;
  border-radius: 15px;
  text-align: left;
  margin: 110px auto 20px 360px !important;
  width: calc(100% - 480px) !important;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  height: 100% !important;
}
.detailExam .iconVideooCall {
  position: absolute;
  top: -79px;
  z-index: 20;
  height: 35px;
  display: flex;
  border-bottom: 4px solid #fa9551;
  margin-right: 45px;
}
.detailExam .iconVideooCall h4 {
  font-size: 15px;
  color: #2cace3;
}
.detailExam .iconVideooCall:hover {
  cursor: pointer;
}
.detailExam .MainIconsDiv {
  display: flex;
  width: 400px;
  justify-content: space-around;
  margin: 20px auto 0 auto;
}
.detailExam .MainIconsDiv img:hover {
  cursor: pointer;
}
.detailExam .detail2 {
  color: #666666;
  display: flex;
}
.detailExam .detail2 .detailInfo2 {
  width: 35%;
  margin: 0px 30px 0px 10px !important;
  overflow: hidden;
  height: 550px;
  border-bottom: 1px solid #fa9551;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
}
.detailExam .detail2 .detailInfo2 .ClientP {
  text-indent: 10px;
}
.detailExam .detail2 .detailInfo2 .ClientP span {
  font-weight: 900;
}
.detailExam .detail2 .detailInfo2 .MainDivForChat {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.detailExam .detail2 .detailInfo2 .MainDivForChat p {
  background: #fa9551;
  color: white;
  height: 30px;
  text-indent: 10px;
  font-weight: 900;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  margin: 0;
}
.detailExam .detail2 .detailInfo2 .MainDivForChat form {
  height: 518px;
  overflow: hidden;
  position: relative;
}
.detailExam .detail2 .detailInfo2 .MainDivForChat form #messages {
  height: 488px;
  margin: 0;
  padding: 20px;
  border-bottom: none;
  border-left: 1px solid #fa9551;
  border-right: 1px solid #fa9551;
  border-radius: 0;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.detailExam .detail2 .detailInfo2 .MainDivForChat form #messages p {
  position: relative;
  margin-top: 25px;
  margin-bottom: 20px;
  padding-left: 10px;
  text-indent: 0;
}
.detailExam .detail2 .detailInfo2 .MainDivForChat form #messages p span {
  display: flex;
  position: absolute;
  top: -18px;
  left: -10px;
  width: 48px;
  text-indent: 0;
}
.detailExam .detail2 .detailInfo2 .MainDivForChat form #messages::-webkit-scrollbar {
  width: 5px;
}
.detailExam .detail2 .detailInfo2 .MainDivForChat form #messages::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.detailExam .detail2 .detailInfo2 .MainDivForChat form #messages::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border: 1px solid white;
  border-radius: 5px;
}
.detailExam .detail2 .detailInfo2 .MainDivForChat form #messages::-webkit-scrollbar-thumb:hover {
  background: #1184c7;
}
.detailExam .detail2 .detailInfo2 .MainDivForChat form #messages p {
  font-weight: 400 !important;
  color: #666666;
}
.detailExam .detail2 .detailInfo2 .MainDivForChat form .inputMessage {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: none !important;
}
.detailExam .detail2 .detailInfo2 .MainDivForChat form .inputMessage #yourMessage {
  border: 0;
  border-left: 1px solid #fa9551 !important;
  border-right: 1px solid #fa9551 !important;
  width: 100%;
  height: 30px;
  resize: none;
  padding: 5px 35px 5px 5px;
}
.detailExam .detail2 .detailInfo2 #send {
  position: absolute;
  right: 10px;
  bottom: 3px !important;
  z-index: 20;
  background: white;
  border: none;
}
.detailExam .detail2 .detailInfo2 #send img {
  width: 26px;
  height: 26px !important;
}
.detailExam .detail2 #videoChat {
  display: flex;
  justify-content: center;
  height: 550px;
  width: 100%;
  padding: 0px 0px 0px 30px !important;
}
.detailExam .detail2 #videoChat .vid {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.detailExam .detail2 #videoChat .extendScreen {
  position: absolute;
  z-index: 5;
  top: 480px;
  right: 20px;
}
.detailExam .detail2 #videoChat .extendScreen:hover {
  cursor: pointer;
}
.detailExam .detail2 #videoChat #myVid {
  object-fit: cover;
  width: 170px !important;
  border-radius: 15px;
  height: 100px;
}
.detailExam .detail2 #videoChat #myVid video {
  width: 170px !important;
  height: 140px !important;
  background: #e6e6e6;
  z-index: 20;
}
.detailExam .record-box {
  padding: 5px;
}
.detailExam .record-box h4 {
  margin: 0 auto 12px auto;
  color: #4092c2;
}
.detailExam .record-box p {
  text-align: left;
  text-indent: 10px;
  color: #4092c2;
}
.detailExam .record-box p span {
  font-weight: 900;
}
.detailExam .message-btn {
  position: absolute;
  bottom: 20px;
  left: 30px;
}
.detailExam .message-btn .btn {
  width: 120px;
  font-size: 14;
  height: 30px;
  padding: 0px;
  border-radius: 8px;
  background-color: #00aff0;
  color: white;
  font-size: 18px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  outline: 0;
}
.detailExam .message-btn .btn:disabled,
.detailExam .message-btn .btn button[disabled] {
  background: lightgray;
  color: white;
  cursor: not-allowed;
  box-shadow: none;
  pointer-events: none;
  border: none;
}
.detailExam .btn1 {
  background: #00aff0;
  position: absolute;
  bottom: 40px;
  margin: 0;
  font-size: 14px;
  padding: 12px;
  font-weight: bold;
  color: white;
  width: 150px;
  border-radius: 6px;
  box-shadow: none !important;
  border: none !important;
}
.detailExam .btn1:hover {
  color: white !important;
}
.detailExam .btn1:disabled,
.detailExam .btn1 button[disabled] {
  background: lightgray;
  cursor: not-allowed;
  box-shadow: none;
  border: none;
  pointer-events: none;
}
.hamburger {
  margin: 60px 0 10px 0;
  background: #00aff0;
  height: 60px;
  color: white !important;
  position: relative;
}
.hamburger .hamNprofil {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  height: 100%;
  margin: 0 auto;
  font-size: 16px;
}
.hamburger .hamNprofil .ham:hover {
  cursor: pointer;
}
.hamburger .hamNprofil .profile:hover {
  cursor: pointer;
}
.hamburger .hamNprofil .rightNavIcons {
  display: flex;
}
.hamburger .hamNprofil .rightNavIcons div {
  margin: 0 0 0 70px;
  font-size: 13px;
  height: 20px;
  display: flex;
}
.hamburger .hamNprofil .rightNavIcons div:hover {
  cursor: pointer;
}
.hamburger .hamNprofil .rightNavIcons div .iconNav {
  margin: 1px 10px 0 0;
  font-size: 18px;
}
.hamburger .hamNprofil .rightNavIcons div p {
  align-items: center;
  margin: 1px 0 0 0;
}
.hamburger .hamNprofil .rightNavIcons div .linksPatients,
.hamburger .hamNprofil .rightNavIcons div .links {
  display: flex;
  color: white;
  list-style: none;
  text-decoration: none;
}
.hamburger .hamNprofil .rightNavIcons div .doctorsIcon {
  height: 24px;
  width: 24px;
  margin: -2px 10px 0 0;
}
.hamburger .hamNprofil .rightNavIcons div .profileIcon {
  height: 22px;
  width: 22px;
  margin: -2px 10px 0 0;
}
.hamburger .hamNprofil .rightNavIcons div .dashboardIcon {
  width: 16px;
  height: 22px;
  margin: -2px 10px 0 0;
}
.hamburger .hamNprofil .rightNavIcons div .calendarIcon {
  height: 22px;
  width: 22px;
  margin: -2px 10px 0 0;
}
.hamburger .sideNav {
  position: absolute;
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  width: 265.5px;
  background: #999999;
  color: white;
}
.hamburger .sideNav .sideProfile {
  border-bottom: 2px solid #fa9551;
  width: 70%;
  margin: 60px auto 40px auto;
}
.hamburger .sideNav .sideProfile .mainProfile {
  border: 1px solid #4092c2;
  border-radius: 50%;
  width: 67px;
  height: 67px;
  margin: 0 auto;
  background: white;
  position: relative;
}
.hamburger .sideNav .sideProfile .mainProfile .onlineDot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  right: 2px;
}
.hamburger .sideNav .sideProfile .mainProfile .profile {
  color: white;
  font-size: 60px;
  background: #bdebfa;
  width: 60px;
  height: 60px;
  align-items: center;
  overflow: hidden;
  border-radius: 50%;
  margin: 2px auto 2px auto;
}
.hamburger .sideNav .sideProfile .mainProfile .profile .icon {
  margin: 10px 0 0 0;
}
.hamburger .sideNav .sideProfile p {
  margin: 15px 0;
}
.hamburger .sideNav .sideVideo,
.hamburger .sideNav .sideEmail,
.hamburger .sideNav .sideWaitingRoom,
.hamburger .sideNav .sideMyCounsultation,
.hamburger .sideNav .sideHelp,
.hamburger .sideNav .sideFaq,
.hamburger .sideNav .sideMyAccount,
.hamburger .sideNav .sideSignOut {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  height: 50px;
  margin: 0 auto;
  padding: 10px 10px 10px 40px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.hamburger .sideNav .sideVideo.active,
.hamburger .sideNav .sideEmail.active,
.hamburger .sideNav .sideWaitingRoom.active,
.hamburger .sideNav .sideMyCounsultation.active,
.hamburger .sideNav .sideHelp.active,
.hamburger .sideNav .sideFaq.active,
.hamburger .sideNav .sideMyAccount.active,
.hamburger .sideNav .sideSignOut.active {
  background: #fa9551 !important;
}
.hamburger .sideNav .sideVideo:hover,
.hamburger .sideNav .sideEmail:hover,
.hamburger .sideNav .sideWaitingRoom:hover,
.hamburger .sideNav .sideMyCounsultation:hover,
.hamburger .sideNav .sideHelp:hover,
.hamburger .sideNav .sideFaq:hover,
.hamburger .sideNav .sideMyAccount:hover,
.hamburger .sideNav .sideSignOut:hover {
  cursor: pointer;
}
.hamburger .sideNav .sideVideo span,
.hamburger .sideNav .sideEmail span,
.hamburger .sideNav .sideWaitingRoom span,
.hamburger .sideNav .sideMyCounsultation span,
.hamburger .sideNav .sideHelp span,
.hamburger .sideNav .sideFaq span,
.hamburger .sideNav .sideMyAccount span,
.hamburger .sideNav .sideSignOut span {
  width: 30px;
  font-size: 18px;
  margin: 0px 10px auto 0;
}
.hamburger .sideNav .sideVideo h2,
.hamburger .sideNav .sideEmail h2,
.hamburger .sideNav .sideWaitingRoom h2,
.hamburger .sideNav .sideMyCounsultation h2,
.hamburger .sideNav .sideHelp h2,
.hamburger .sideNav .sideFaq h2,
.hamburger .sideNav .sideMyAccount h2,
.hamburger .sideNav .sideSignOut h2 {
  color: white;
  font-size: 13px;
  margin: 0;
}

.sideNav {
  position: absolute;
  -webkit-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  top: 70px;
  width: 240px;
  height: calc(100% - 70px);
  background: whitesmoke;
  color: #0164a1;
}
.sideNav .sideProfile {
  border-bottom: 2px solid #f79451;
  width: 190px;
  margin: 30px auto 10px auto;
}
.sideNav .sideProfile .mainProfile {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin: 0 auto;
  position: relative;
}
.sideNav .sideProfile .mainProfile .profile {
  color: white;
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 50%;
}
.sideNav .sideProfile .mainProfile .profile img {
  margin: 5px auto 2px auto;
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.sideNav .sideProfile .mainProfile .profile img:hover {
  cursor: pointer;
}
.sideNav .sideProfile p {
  margin: 15px 0;
  color: #666666;
  font-weight: bold;
  font-size: 17px;
}
.sideNav .sideVideo,
.sideNav .sideEmail,
.sideNav .sideWaitingRoom,
.sideNav .sideMyCounsultation,
.sideNav .sideHelp,
.sideNav .sideFaq,
.sideNav .sideMyAccount,
.sideNav .sideSignOut {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 12px 10px 12px 20px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  color: #666666;
  text-decoration: none;
}
.sideNav .sideVideo.active,
.sideNav .sideEmail.active,
.sideNav .sideWaitingRoom.active,
.sideNav .sideMyCounsultation.active,
.sideNav .sideHelp.active,
.sideNav .sideFaq.active,
.sideNav .sideMyAccount.active,
.sideNav .sideSignOut.active {
  background: #fa9551 !important;
}
.sideNav .sideVideo:hover,
.sideNav .sideEmail:hover,
.sideNav .sideWaitingRoom:hover,
.sideNav .sideMyCounsultation:hover,
.sideNav .sideHelp:hover,
.sideNav .sideFaq:hover,
.sideNav .sideMyAccount:hover,
.sideNav .sideSignOut:hover {
  cursor: pointer;
}
.sideNav .sideVideo span,
.sideNav .sideEmail span,
.sideNav .sideWaitingRoom span,
.sideNav .sideMyCounsultation span,
.sideNav .sideHelp span,
.sideNav .sideFaq span,
.sideNav .sideMyAccount span,
.sideNav .sideSignOut span {
  font-size: 18px;
  margin: 0px 7px auto 0;
}
.sideNav .sideVideo h2,
.sideNav .sideEmail h2,
.sideNav .sideWaitingRoom h2,
.sideNav .sideMyCounsultation h2,
.sideNav .sideHelp h2,
.sideNav .sideFaq h2,
.sideNav .sideMyAccount h2,
.sideNav .sideSignOut h2 {
  font-size: 15px;
  font-weight: bold;
  margin: 0;
}
.mainClientDashboard {
  width: calc(100% - 480px);
  margin: 110px 0px 20px 360px;
}
.mainClientDashboard .loading {
  -webkit-animation: rotation 2s infinite linear;
          animation: rotation 2s infinite linear;
  width: 70px !important;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
.mainClientDashboard .main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin: 0px auto 20px auto;
  grid-gap: 10px;
  background: #f2f2f2;
  padding: 30px 15px;
  position: relative;
  border-radius: 15px;
}
.mainClientDashboard .main .dashboardIcon {
  position: absolute;
  top: -76px;
  z-index: 20;
  border-bottom: 4px solid #fa9551;
  height: 35px;
  display: flex;
}
.mainClientDashboard .main .dashboardIcon h4 {
  font-size: 15px;
  font-weight: bold !important;
  color: #2cace3;
}
.mainClientDashboard .main .videoApp,
.mainClientDashboard .main .waitRoom,
.mainClientDashboard .main .emailReq {
  display: flex;
  font-size: 40px;
  align-items: center;
  height: 90px;
  border-radius: 10px;
  border: 1.6px solid #b3b3b3;
  justify-content: flex-start;
  background: white;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.mainClientDashboard .main .videoApp .email,
.mainClientDashboard .main .videoApp .video,
.mainClientDashboard .main .videoApp .clock,
.mainClientDashboard .main .waitRoom .email,
.mainClientDashboard .main .waitRoom .video,
.mainClientDashboard .main .waitRoom .clock,
.mainClientDashboard .main .emailReq .email,
.mainClientDashboard .main .emailReq .video,
.mainClientDashboard .main .emailReq .clock {
  border-radius: 50%;
  align-self: center;
  margin: 0 10px;
}
.mainClientDashboard .main .videoApp .email .icon,
.mainClientDashboard .main .videoApp .video .icon,
.mainClientDashboard .main .videoApp .clock .icon,
.mainClientDashboard .main .waitRoom .email .icon,
.mainClientDashboard .main .waitRoom .video .icon,
.mainClientDashboard .main .waitRoom .clock .icon,
.mainClientDashboard .main .emailReq .email .icon,
.mainClientDashboard .main .emailReq .video .icon,
.mainClientDashboard .main .emailReq .clock .icon {
  align-self: center;
}
.mainClientDashboard .main .videoApp div h2,
.mainClientDashboard .main .waitRoom div h2,
.mainClientDashboard .main .emailReq div h2 {
  font-size: 15px;
  text-align: start;
  color: #666666;
  margin-top: 10px;
}
.mainClientDashboard .main .videoApp div .secondH2,
.mainClientDashboard .main .waitRoom div .secondH2,
.mainClientDashboard .main .emailReq div .secondH2 {
  font-size: 15px;
  font-weight: 800;
  margin-top: 10px;
}
.mainClientDashboard .main .videoApp:hover,
.mainClientDashboard .main .waitRoom:hover,
.mainClientDashboard .main .emailReq:hover {
  cursor: pointer;
  box-shadow: 2px 2px 10px #999999;
}
.mainClientDashboard .main .waitRoom span {
  position: relative;
}
.mainClientDashboard .main .waitRoom span p {
  position: absolute;
  bottom: -10px;
  right: 0;
  font-size: 20px;
  background: red;
  color: white;
  padding: 0 3px;
  border-radius: 5px;
}
.mainClientDashboard .pagi {
  display: flex;
  margin: 20px auto;
  justify-content: center;
  width: 100%;
}
.mainClientDashboard .pagi ul {
  margin: 20px auto;
}
.mainClientDashboard .pagi ul li {
  border: none;
}
.mainClientDashboard .pagi ul li a {
  color: #00aff0;
  border: none;
}
.mainClientDashboard .pagi ul .lastPage {
  display: none;
}
.mainClientDashboard .pagi ul .active a {
  background: #00aff0;
  color: white;
}
.mainClientDashboard .mainTabel {
  margin: 0 auto;
  height: 480px;
  border-radius: 15px;
  overflow: hidden;
}
.mainClientDashboard .mainTabel .NoResultDiv {
  color: #666666;
  margin: 20px auto;
  font-size: 20px;
}
.mainClientDashboard .mainTabel .mainConsultation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fa9551;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  height: 40px;
}
.mainClientDashboard .mainTabel .mainConsultation .icon_left span {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 3px 10px;
  align-self: center;
  text-align: center;
}
.mainClientDashboard .mainTabel .mainConsultation .icon_left span .icon1 {
  align-self: center;
  color: white;
  font-size: 22px;
  margin-top: 3px;
}
.mainClientDashboard .mainTabel .mainConsultation .icon_left p {
  align-self: center;
  margin: 0 0 0 30px;
  color: white;
}
.mainClientDashboard .mainTabel .mainConsultation .sort {
  margin: 0 10px;
  display: flex;
}
.mainClientDashboard .mainTabel .mainConsultation .sort p {
  align-self: center;
  margin: 0 50px 0 0;
  text-overflow: bold;
  color: white;
}
.mainClientDashboard .mainTabel .mainConsultation .sort p:hover {
  cursor: pointer;
}
.mainClientDashboard .mainTabel .mainConsultation .sort .all {
  margin: 0 20px 0 0;
}
.mainClientDashboard .mainTabel .table3 {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  padding: 10px;
  color: #666666;
}
.mainClientDashboard .mainTabel .table3 tbody {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background: #f2f2f2;
}
.mainClientDashboard .mainTabel .table3 tbody:last-of-type {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden !important;
}
.mainClientDashboard .mainTabel .table3 tbody:last-of-type tr {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden !important;
}
.mainClientDashboard .mainTabel .table3 tbody:last-of-type tr td:first-of-type {
  border-bottom-left-radius: 15px;
}
.mainClientDashboard .mainTabel .table3 tbody:last-of-type tr td:last-of-type {
  border-bottom-right-radius: 15px !important;
}
.mainClientDashboard .mainTabel .table3 tbody tr {
  height: 40px;
}
.mainClientDashboard .mainTabel .table3 tbody tr:hover {
  background: #e6e6e6;
  cursor: pointer;
}
.mainClientDashboard .mainTabel .table3 tbody tr td {
  text-align: left;
}
.mainClientDashboard .mainTabel .table3 tbody tr td .finished {
  color: #00aff0;
  font-size: 17px;
}
.mainClientDashboard .mainTabel .table3 tbody tr .client-status {
  text-align: center;
  width: 100px;
}
.mainClientDashboard .mainTabel .table3 tbody tr .client-status:hover .check,
.mainClientDashboard .mainTabel .table3 tbody tr .client-status:hover .pendi,
.mainClientDashboard .mainTabel .table3 tbody tr .client-status:hover .finished,
.mainClientDashboard .mainTabel .table3 tbody tr .client-status:hover .declined {
  display: none;
}
.mainClientDashboard .mainTabel .table3 tbody tr .client-status:hover .status {
  display: block;
}
.mainClientDashboard .mainTabel .table3 tbody tr .status {
  display: none;
}
.mainClientDashboard .mainTabel .table3 tbody tr .client-doctor {
  padding: 0 0 0 30px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.mainClientDashboard .mainTabel .table3 tbody tr .client-subject1 {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  width: 350px;
}
.mainClientDashboard .mainTabel .table3 tbody tr .client-subject {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.mainClientDashboard .mainTabel .table3 tbody .created {
  margin: 0 auto;
  padding: 0 0 0 40px;
}
.mainClientDashboard .mainTabel .table3 tbody .created p {
  margin: 0 auto;
}
.mainClientDashboard .mainTabel .table3 .client-head {
  text-align: center;
  color: #666666;
  background-color: black !important;
}
.mainClientDashboard .mainTabel .table3 .client-head .client-row {
  height: 40px;
  background: #b3b3b3;
}
.mainClientDashboard .mainTabel .table3 .client-head .client-row .client-doctor {
  text-indent: 30px;
}
.mainClientDashboard .penTable {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  z-index: 5;
}
.mainClientDashboard .penTable button {
  width: 150px;
  height: 30px;
  background: #dddddd;
  border-radius: 5px;
  border: none;
  margin: 30px auto;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  outline: 0;
}
.mainClientDashboard .penTable button:hover {
  background: pink;
  box-shadow: 5px 5px 5px black;
}

.table4 {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  padding: 10px;
}
.table4 tbody {
  background: white;
  -webkit-transition: all 0.5 ease-in-out;
  transition: all 0.5 ease-in-out;
}
.table4 tbody:hover {
  background: lightblue;
}
.table4 tbody tr {
  height: 40px;
}
.table4 .client-head {
  text-align: center;
  background-color: whitesmoke;
  color: #666666;
}
.table4 .client-head .client-row {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #b3b3b3;
}
