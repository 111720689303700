.nav {
  ul {
    display: flex;
    float: right;
    .topProfile {
      width: 100%;
      display: flex;
      .mainProfile {
        width: 50px;
        height: 50px;
        margin: 0 auto;
        background: white;
        position: relative;

        .profile {
          margin: 0;
          img {
            margin: 0;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: contain;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      p {
        margin: 13px 10px 13px 40px;
        color: #666666;
        font-weight: 500;
        // align-self: center;
      }
    }
    .inic {
      background: lightblue;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid rgb(48, 100, 179);
      .initials {
        color: white;
        text-align: center;
        margin-top: 9px;
        // margin-top: 17px;
      }
    }
    li {
      padding: 0;
      margin-top: 5px;
      font-weight: 600;
      font-size: 12pt;
      a {
        color: #666666;
        background: none;
        transition: all 0.5s ease-in-out;
        padding: 15px 0 15px 10px;
        // &:hover {
        //   background: none;
        //   color: rgb(3, 137, 182);
        // }
        &:link {
          background: none;
        }
      }
    }
    .userName {
      margin-left: 10px;
    }
    .selectStatus {
      max-width: 100px;
      margin: 35px 0 0 0;
      padding: 0;
      #status {
        border-radius: 3px;
        border: none;
        background: #3cb54a;
        color: white;
        width: 100px;
        height: 30px;
        font-size: 10px;
        font-size: 13px;
      }
    }
  }
  .li-reg {
    height: 40px;
    margin: 20px 0 0 0;
    .register {
      color: #00aff0;
      font-weight: 600;
      font-size: 12pt;
      background-color: transparent;
    }
  }

  .li-log {
    height: 40px;
    margin: 20px 0 0 0;
    .login {
      color: white;
      font-size: 12pt;
      font-weight: 600;
      background-color: #00aff0;
      height: 26px;
      padding: 2px 10px 2px 10px;
      margin: 12px 0 0 10px;
      border-radius: 5px;
    }
  }
}
