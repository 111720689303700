* {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }
}

.mainRegisterDiv {
  width: 47%;
  margin: 120px auto 0 auto;
  text-align: start;
  background: #f2f2f2;
  padding: 0 70px;
  border-radius: 15px;
  color: #666666;
  position: relative;

  .logo {
    position: absolute;
    width: 110px;
    right: -30px;
    top: -25px;
  }

  .loading {
    animation: rotation 2s infinite linear;
    width: 70px !important;
    position: absolute;
    top: 45%;
    left: 48%;
    transform: translate(-45%, -48%);
    z-index: 20;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  .radioDiv {
    width: 100% !important;
    h1 {
      font-weight: 700;
      text-align: start;
      color: #00aff0;
      padding: 20px 0 0 0;
    }
    .checkingInputs {
      margin: 20px 0 0 0;

      display: flex;
      justify-content: space-between;
      p {
        font-weight: 700;
        color: LightCoral;
        position: absolute;
        left: 0;
        bottom: -40px;
      }
      .doctorORClient {
        display: flex;
        width: 100%;
        margin: 10px 10px 0 0;
        justify-content: space-evenly;

        input[type="checkbox"] {
          visibility: hidden;
          position: absolute;
        }

        input[type="checkbox"] + label:before {
          height: 15px;
          width: 15px;
          margin-right: 5px;
          content: " ";
          display: inline-block;
          vertical-align: baseline;
          border: 1px solid #00aff0;
          border-radius: 50%;
        }

        input[type="checkbox"]:checked + label:before {
          background: #00aff0;
        }

        // [type="checkbox"]:not(:checked),
        // [type="checkbox"]:checked {
        //   position: absolute;
        //   left: -9999px;
        // }

        // [type="checkbox"]:not(:checked) + label,
        // [type="checkbox"]:checked + label {
        //   position: relative;
        //   padding-left: 25px;
        //   cursor: pointer;
        // }

        // [type="checkbox"]:not(:checked) + label:before,
        // [type="checkbox"]:checked + label:before {
        //   content: "";
        //   position: absolute;
        //   left: 0;
        //   width: 17px;
        //   height: 17px;
        //   border-radius: 3px;
        //   box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        //   border-radius: 50%;
        //   outline: none;
        //   border: 1px solid #00aff0;
        // }

        // [type="checkbox"]:not(:checked) + label:after,
        // [type="checkbox"]:checked + label:after {
        //   content: "✔";
        //   position: absolute;
        //   top: 3px;
        //   left: 2px;
        //   font-size: 15px;
        //   line-height: 0.8;
        //   color: #00aff0;
        //   transition: all 0.2s;
        // }

        // [type="checkbox"]:not(:checked) + label:after {
        //   opacity: 0;
        //   transform: scale(0);
        // }

        // [type="checkbox"]:checked + label:after {
        //   opacity: 1;
        //   transform: scale(1);
        // }

        .doctorLabel {
          // margin: 0 10px 0px 0;
          color: #666666;
          font-size: 15px;
          font-weight: 700;
        }

        .clientLabel {
          color: #666666;
          font-size: 15px;
          font-weight: 700;
        }
      }

      .gender {
        width: 100%;
        margin: 0 0 0 10px;
        height: 20px;
        position: relative;
        display: flex;
        align-items: center;
        align-self: center;
        label {
          float: right !important;
        }
        .maleGender {
          margin: 10px 10px 0 0;
          input[type="radio"] {
            visibility: hidden;
            position: absolute;
          }

          input[type="radio"] + label:before {
            height: 15px;
            width: 15px;
            margin-right: 5px;
            content: " ";
            display: inline-block;
            vertical-align: baseline;
            border: 1px solid #00aff0;
            border-radius: 50%;
          }

          input[type="radio"]:checked + label:before {
            background: #00aff0;
          }
        }
        .femaleGender {
          margin: 10px 0 0 10px;

          input[type="radio"] {
            visibility: hidden;
            position: absolute;
          }

          input[type="radio"] + label:before {
            height: 15px;
            width: 15px;
            margin-right: 5px;
            content: " ";
            display: inline-block;
            vertical-align: baseline;
            border: 1px solid #00aff0;
            border-radius: 50%;
          }

          input[type="radio"]:checked + label:before {
            background: #00aff0;
          }
        }
      }
    }
  }

  @media (max-width: 1400px) {
    .radioDiv {
      width: 80%;
    }
  }
  .register-form {
    margin: 40px auto 0 auto;
    label {
      font-size: 12px;
      float: left;
      text-indent: 2px;
    }
    .Pricing {
      // width: 420px;
      margin: 5px 0 20px 0;
      display: flex;
      justify-content: space-between;
      ::placeholder {
        color: #666666;
        font-size: 12px;
        font-weight: 600;
      }
      input {
        height: 40px;
        width: 250px;
        border: 1.7px solid #fa9551;
        border-radius: 10px;
        background: white;
        font-weight: 700;
      }
      // .EmailPrice {
      // }
      .WebPrice {
        width: 250px;
        input {
          margin: 0 -2px 0 auto;
          font-weight: 700;
        }
      }
    }
    .firstLastGender {
      display: flex;
      justify-content: space-between;
      margin: 0 0 20px 0;
      .reg-name {
        width: 100%;
        margin: 0 20px 0 0;
        ::placeholder {
          color: #666666;
          font-size: 12px;
          font-weight: 600;
        }
        input {
          flex: 1;
          height: 40px;
          border: 1.7px solid #fa9551;
          border-radius: 10px;
          background: white;
          font-weight: 700;
          margin: 0;
        }
      }
      .reg-surname {
        width: 100%;
        ::placeholder {
          color: #666666;
          font-size: 12px;
          font-weight: 600;
        }
        input {
          flex: 1;
          height: 40px;
          // max-width: 250px;
          border: 1.7px solid #fa9551;
          border-radius: 10px;
          margin: 0;

          background: white;
          font-weight: 700;
        }
      }
    }

    .regPass {
      margin: 0 0 20px 0;
      display: flex;
      justify-content: space-between;
      .reg-email {
        margin: 0 20px 0 0;
        width: 100% !important;
        ::placeholder {
          color: #666666;
          font-size: 12px;
          font-weight: 600;
        }
        input {
          height: 40px;
          // max-width: 250px;
          flex: 1;
          border: 1.7px solid #fa9551;
          border-radius: 10px;
          background: white;
          margin: 0;

          font-weight: 700;
        }
      }
      .address {
        // margin: 0 0 0 20px;
        width: 100%;
        ::placeholder {
          color: #666666;
          font-size: 12px;
          font-weight: 600;
        }
        input {
          height: 40px;
          border: 1.7px solid #fa9551;
          border-radius: 10px;
          background: white;
          font-weight: 700;
          flex: 1;
          margin: 0;
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }

    .clientsBinfo {
      margin: 0 0 20px 0;
      display: flex;
      justify-content: space-between;
      .reg-pass,
      .conf-pass {
        width: 100%;
        margin: 0 20px 0 0;
        ::placeholder {
          color: #666666;
          font-size: 12px;
          font-weight: 600;
        }
        div {
          width: 100%;
          position: relative;
          input {
            flex: 1;
            margin: 0 0 10px 0;
            height: 40px;
            border-radius: 10px;
            border: 1.7px solid #fa9551;
            font-weight: 700;
            margin: 0;

            padding-right: 30px;
          }

          img {
            position: absolute;
            right: 5px;
            bottom: 13px;
          }
        }
        // input {
        //   height: 40px;
        //   border: 1.7px solid #fa9551;
        //   border-radius: 10px;
        //   background: white;
        // }
      }
      .conf-pass {
        margin: 0;
      }

      .date {
        width: 100%;
        ::placeholder {
          color: #666666;
          font-size: 12px;
          font-weight: 600;
        }
        input {
          height: 40px;
          border: 1.7px solid #fa9551;
          border-radius: 10px;
          background: white;
          font-weight: 700;
          flex: 1;
          margin: 0;
        }
        .dateOnRegister {
          color: rgb(156, 155, 155);
        }
      }
      .spec {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 40px;
        margin: 0px 0 40px 0;
        border-radius: 10px;
      }
    }

    .clientsBinfo {
      display: flex;
    }

    .doctorsIdInfo {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      ::placeholder {
        color: #4092c2;
        font-size: 10px;
      }

      .prefix {
        width: 100%;
        margin: 0 0 30px 0;
        div {
          width: 100%;
          position: relative;
          input {
            width: 100%;
            margin: 0 0 10px 0;
            height: 40px;
            border-radius: 10px;
            border: 1.7px solid #fa9551;
            font-weight: 700;
            flex: 1;
            margin: 0;
            padding-right: 30px;
          }
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          img {
            position: absolute;
            right: 5px;
            bottom: 13px;
          }
        }
      }

      .spec {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 40px;
        margin: 0px 0 40px 0;
        border-radius: 10px;
      }
    }

    .doctorsBinfo {
      display: flex;
      justify-content: space-between;
      ::placeholder {
        color: #4092c2;
        font-size: 10px;
      }
      .prefix {
        width: 100%;
        margin: 0 20px 20px 0;
        div {
          width: 100%;
          position: relative;
          input {
            width: 100%;
            margin: 0 0 10px 0;
            height: 40px;
            border-radius: 10px;
            border: 1.7px solid #fa9551;
            font-weight: 700;
            flex: 1;
            margin: 0;
            padding-right: 30px;
          }
          img {
            position: absolute;
            right: 5px;
            bottom: 13px;
          }
        }
      }

      .spec {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 40px;
        margin: 0px 0 0 0;
        border-radius: 10px;
      }
    }

    .regPass {
      display: flex;
    }
    .form-control {
      margin: 3px;
    }

    .register-button {
      width: 100px;
      margin-top: 5px;

      .btn {
        position: relative;
        width: 80px;
        height: 35px;
        color: white;
        border-radius: 10px;
        background: #00aff0;
        transition: all 0.5s ease-in-out;
        margin: 0 0 30px 2px;
        outline: 0;

        &:hover {
          box-shadow: 2px 2px 2px rgb(109, 109, 109);
        }
      }
    }
    .confirmAndPhone {
      display: flex;
      justify-content: space-between;
      .conf-pass {
        width: 100%;
        margin: 0 20px 30px 0;
        div {
          width: 100%;
          position: relative;
          input {
            flex: 1;
            margin: 0 0 10px 0;
            height: 40px;
            border-radius: 10px;
            border: 1.7px solid #fa9551;
            font-weight: 700;
            margin: 0;

            padding-right: 30px;
          }
          img {
            position: absolute;
            right: 5px;
            bottom: 13px;
          }
        }
        // input {
        //   height: 40px;
        //   border: 1.7px solid #fa9551;
        //   border-radius: 10px;
        //   background: white;
        //   &::placeholder {
        //     color: #666666;
        //     font-size: 12px;
        //     font-weight: 600;
        //   }
        // }
      }
      .Phone {
        width: 100%;
        margin: 0 0 30px 0;
        input {
          height: 40px;
          border: 1.7px solid #fa9551;
          border-radius: 10px;
          background: white;
          font-weight: 700;
          flex: 1;
          margin: 0;

          &::placeholder {
            color: #666666;
            font-size: 12px;
            font-weight: 600;
          }
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }
}
