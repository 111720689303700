.mainClientic {
  display: flex;
  width: 40% !important;
  margin: 110px auto 20px 360px;
  background: #f2f2f2;
  border-radius: 15px;
  position: relative;
  .newVideo {
    position: absolute;
    top: -76px;
    z-index: 20;
    height: 35px;
    display: flex;
    border-bottom: 4px solid #fa9551;
    margin-right: 45px;
    h4 {
      font-weight: bold;
      font-size: 15px;
      color: #2cace3;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .client {
    width: 50%;
    padding: 30px 0px 30px 30px;

    .client-p {
      display: flex;
      flex-direction: column;
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }

      input {
        height: 33px;
        border: 1.7px solid #fa9551;
        border-radius: 10px;
        background: white;
        margin: 2px 0 20px 0;
        text-indent: 15px;
        font-weight: 700;
        width: 90%;

        &::placeholder {
          font-weight: 700;
          color: #969696;
        }
      }
      label {
        color: #666666;
        text-align: left;
      }

      .saveChanges {
        background: #00aff0;
        width: 80px;
        height: 30px;
        color: white;
        border: none;
        border-radius: 10px;
        font-weight: 600;
        margin: 25px 0 0 0;
      }
    }
  }

  .form {
    padding: 30px 30px 30px 0px !important;
    width: 50%;

    .picturelabel {
      font-size: 14px;
      font-weight: bold;
      text-align: start;
      color: #666666;
      margin: 0 0 5px 0;
    }

    .conditions {
      display: flex;
      flex-direction: column;
      text-align: start;
      font-weight: 700;
      color: #666666;
      // margin: 25px 0 0 0;
      textarea {
        height: 115px;
        border: 1.7px solid #fa9551;
        border-radius: 10px;
        padding: 10px 15px;
        background: white;
        margin: 5px 0 10px 0;
        font-weight: 700;
        resize: none;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-button:single-button {
          // background-color: #bbbbbb;
          display: block;

          height: 13px;
          width: 16px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: #fa9551;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #db8246;
        }

        &::placeholder {
          font-weight: 700;
          color: #969696;
        }
      }
    }
    .allergies {
      display: flex;
      flex-direction: column;
      text-align: start;
      font-weight: 700;
      color: #666666;
      margin: 10px 0 0 0;
      textarea {
        border: 1.7px solid #fa9551;
        border-radius: 10px;
        background: white;
        margin: 5px 0 20px 0;
        padding: 10px 15px;
        font-weight: 700;
        height: 115px !important;
        resize: none;
        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-button:single-button {
          // background-color: #bbbbbb;
          display: block;

          height: 13px;
          width: 16px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: #fa9551;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #db8246;
        }

        &::placeholder {
          font-weight: 700;
          color: #969696;
        }
      }
    }
    .checkboxDiv {
      display: flex;
      justify-content: space-between;
      .check {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        .Male {
          margin: 0 17px 10px 0;
        }
        label {
          margin: 0;
          height: 20px;
          display: flex;
          align-items: center;
        }
        input[type="checkbox"] {
          visibility: hidden;
          position: absolute;
        }

        input[type="checkbox"] + label:before {
          height: 20px;
          width: 20px;
          margin-right: 5px;
          content: " ";
          display: inline-block;
          vertical-align: baseline;
          border: 1px solid #00aff0;
          border-radius: 50%;
        }

        input[type="checkbox"]:checked + label:before {
          background: #00aff0;
        }

        // [type="checkbox"]:not(:checked),
        // [type="checkbox"]:checked {
        //   position: absolute;
        //   left: -9999px;
        // }

        // [type="checkbox"]:not(:checked) + label,
        // [type="checkbox"]:checked + label {
        //   position: relative;
        //   padding-left: 25px;
        //   cursor: pointer;
        // }

        // [type="checkbox"]:not(:checked) + label:before,
        // [type="checkbox"]:checked + label:before {
        //   content: "";
        //   position: absolute;
        //   left: 0;
        //   width: 17px;
        //   height: 17px;
        //   border-radius: 3px;
        //   box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
        //   border-radius: 50%;
        //   outline: none;
        //   border: 1px solid #00aff0;
        // }

        // [type="checkbox"]:not(:checked) + label:after,
        // [type="checkbox"]:checked + label:after {
        //   content: "✔";
        //   position: absolute;
        //   top: 3px;
        //   left: 2px;
        //   font-size: 15px;
        //   line-height: 0.8;
        //   color: #00aff0;
        //   transition: all 0.2s;
        // }

        // [type="checkbox"]:not(:checked) + label:after {
        //   opacity: 0;
        //   transform: scale(0);
        // }

        // [type="checkbox"]:checked + label:after {
        //   opacity: 1;
        //   transform: scale(1);
        // }
      }
      .profilePic {
        display: flex;

        align-items: flex-start;
        // width: 100px;
        // height: 150px;
        width: 110px;
        border: 10px;
        height: 137px;
        border-radius: 10px;
        .cliImage {
          width: 110px;
          height: 110px;
          padding: 1px;
          border-radius: 10px;
          object-fit: cover;
          &:hover {
            cursor: pointer;
          }
        }
        .upload-btn-wrapper {
          position: relative;
          overflow: hidden;
          .btn {
            margin: 0;
            width: 110px;
            height: 110px;
            padding: 0;
            border-radius: 10px;

            background: transparent !important;
            .docImage {
              width: 107px;
              height: 107px;
              border-radius: 10px;

              object-fit: cover;
              // border-radius: 50%;
            }
            p {
              font-size: 80px;
              padding: 0;
            }
          }
          .deleteImage {
            position: absolute;
            width: 20px;
            height: 20px;
            background: red;
            color: white;
            border: none;
            border-radius: 5px;
            z-index: 1000;
            top: 5px;
            left: 85px;
          }
        }

        .upload-btn-wrapper input[type="file"] {
          font-size: 0px;
          position: absolute;

          left: 0;
          top: -20px;
          width: 110px;
          height: 140px;
          opacity: 0;
          cursor: pointer;
        }
        // .upload-btn-wrapper {
        //   position: relative;
        //   overflow: hidden;
        //   // li {
        //   //   color: #008dc0;
        //   //   text-decoration: underline;
        //   //   list-style: none;
        //   // }
        //   // .btn {
        //   //   margin: 0;
        //   //   border: 1.7px solid #666666;
        //   //   width: 100px;
        //   //   height: 120px;
        //   //   padding: 0;
        //   //   background: transparent !important;
        //   //   .docImage {
        //   //     width: 95px;
        //   //     height: 116px;
        //   //     object-fit: cover;
        //   //     // border-radius: 50%;
        //   //   }
        //   //   p {
        //   //     font-size: 80px;
        //   //     padding: 0;
        //   //   }
        //   // }
        //   .deleteImage {
        //     position: absolute;
        //     width: 20px;
        //     height: 20px;
        //     background: red;
        //     color: white;
        //     border: none;
        //     border-radius: 5px;
        //     z-index: 1000;
        //     top: -100px;
        //     left: 75px;
        //   }
        // }

        // .upload-btn-wrapper input[type="file"] {
        //   font-size: 0px;
        //   position: absolute;
        //   background: #00aff0;

        //   left: 0;
        //   top: -20px;
        //   width: 100px;
        //   height: 150px;
        //   opacity: 0;
        //   cursor: pointer;
        // }
        // li {
        //   color: #008dc0;
        //   text-decoration: underline;
        //   list-style: none;
        //   &:hover {
        //     cursor: pointer;
        //   }
        // }
      }
      // .profilePic {
      //   display: flex;
      //   flex-direction: column;
      //   align-items: flex-start;
      //   margin: 0 0 20px 20px;
      //   width: 100px;
      //   height: 120px;

      //   p {
      //     font-weight: 600;
      //     color: #666666;
      //     margin: 0 0 5px 0;
      //   }
      //   .upload-btn-wrapper {
      //     position: relative;
      //     overflow: hidden;
      //     .btn {
      //       margin: 0;
      //       border: 1.7px solid #666666;
      //       width: 100px ;
      //       height: 120px;
      //       padding: 0;
      //       background: transparent !important;
      //       .docImage {
      //         width: 95px;
      //         height: 116px;
      //         object-fit: cover;
      //         // border-radius: 50%;
      //       }
      //       p {
      //         font-size: 80px;
      //         padding: 0;
      //       }
      //     }
      //   }

      //   .upload-btn-wrapper input[type="file"] {
      //     font-size: 100px;
      //     position: absolute;
      //     // background: #00aff0;

      //     left: 0;
      //     top: -20px;
      //     width: 100px;
      //     opacity: 0;
      //   }
      // }
    }
  }
}
