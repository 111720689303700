.App {
  position: relative;
  transition: all 1s ease-in-out;
}

.mainTitle {
  color: #00aff0;
  font-weight: 700;
  font-size: 30pt;
  width: calc(100% - 480px);
  margin: 110px 3px 20px 360px;
}
.underTitle {
  color: #666666;
  font-weight: 700;
  width: calc(100% - 480px);
  margin: 0px 3px 20px 360px;
}

.mainCardDiv {
  display: flex;
  flex-direction: column;
  align-self: center;
  background: #f2f2f2;
  border-radius: 15px;
  width: calc(100% - 480px);
  margin: 0px 0 20px 360px;

  .loading {
    animation: rotation 2s infinite linear;
    width: 70px !important;
    position: absolute;
    top: 50%;
    left: calc(50% + 70px);
    transform: translate(-50%, -50%);
    z-index: 20;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .payWay {
    color: #00aff0;
    width: 65%;
    font-size: 50pt;
    display: flex;
    align-self: center;
    justify-content: space-around;
    margin: 30px auto;

    .cardIcon {
      width: 150px;
      height: 100px;
      align-self: center;
      position: relative;
      border-radius: 10px;
      transition: all 0.2s ease-in-out;
      margin: 0 20px 0 0;
      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &:hover {
          cursor: pointer;
        }
      }
    }
    .paypalIcon {
      width: 150px;
      height: 100px;
      align-self: center;
      position: relative;
      border-radius: 10px;
      transition: all 0.2s ease-in-out;
      margin: 0 0 0 20px;

      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  .mainBillDiv {
    display: flex;
    align-self: center;
    .billingInfo {
      margin: 0 40px 0 0;
      h1 {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
        color: #00aff0;

        text-align: start;
      }
      label {
        font-size: 11px;
        text-align: start;
        color: #666666;
        line-height: 20px;
        line-height: 25px;
      }
      input {
        width: 250px;
        height: 40px;
        border-radius: 10px;
        border: none;
        text-indent: 15px;
        border: 1.7px solid #fa9551;
        font-size: 14px;
        font-weight: bold;
        color: #666666;
      }
      input::placeholder {
        color: #666666;
        font-size: 14px;
        font-weight: 450;
      }
      .totalPrice {
        display: flex;
        width: 250px;
        height: 40px;
        color: #666666;
        border-radius: 10px;
        text-indent: 15px;
        border: 1.7px solid #00aff0;
        flex-direction: column;
        justify-content: center;
        margin: 93px 0 0 0;
        background: white;
      }
    }
  }
  .creditCardInfo {
    margin: 0 0 0 40px;
    h1 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
      text-align: start;
      color: #00aff0;
    }
    label {
      font-size: 11px;
      text-align: start;
      line-height: 25px;
      color: #666666;
    }
    form {
      display: flex;
      flex-direction: column;
    }

    .CardElement {
      height: 40px;
      padding: 10px 12px;
      width: 250px;
      color: #666666;
      background-color: white;
      border: 1.7px solid #fa9551;
      border-radius: 10px;
      font-size: 14px;
      font-weight: bold;

      &::placeholder {
        color: #666666;
        font-size: 14px;
        font-weight: 450;
      }
    }
  }
}

.CardElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.CardElement--invalid {
  border-color: #fa755a;
}

.CardElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.btn-checkout {
  margin: 10px auto 20px auto;
  border-radius: 10px;
  border: none;
  left: -5px;
  height: 40px;
  top: 25px;
  width: 250px;
  color: white;
  font-weight: 600;
  background-color: #00aff0;
  transition: all 0.5s ease-in-out;
  outline: 0;

  &:hover {
    background-color: #34779e;
    cursor: pointer;
    box-shadow: 2px 2px 10px rgb(78, 78, 78);
  }
}
